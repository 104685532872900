import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type Translations } from '../utils/i18n/SwappingIntlProvider';

export interface Locale {
  language: string;
  country: string;
}

export interface I18nState {
  locale: Locale | null;
  appLanguage: Locale | null;
  messages: Translations;
  availableLanguages: Locale[];
}

export const initialState: I18nState = {
  locale: null,
  appLanguage: null,
  availableLanguages: [],
  messages: {} as Translations,
};

const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  extraReducers: builder => builder.addCase('resetStore', () => initialState),
  reducers: {
    updateLocale: (state, action: PayloadAction<Locale>) => {
      state.locale = action.payload;
    },
    updateAppLanguage: (state, action: PayloadAction<Locale>) => {
      state.appLanguage = action.payload;
    },
    updateTranslations: (state, action: PayloadAction<Translations>) => {
      state.messages = action.payload;
    },
    updateAvailableLanguages: (state, action: PayloadAction<Locale[]>) => {
      state.availableLanguages = action.payload;
    },
  },
});

export const {
  reducer: i18nReducer,
  actions: { updateAppLanguage, updateAvailableLanguages, updateLocale, updateTranslations },
} = i18nSlice;
