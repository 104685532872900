// @ts-strict-ignore
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type Entity, type NatureCategory } from '../../apis/ilink.type';
import { type EntityQuantityUpdate } from '../../apis/interventions/intervention.type';
import { type Article } from '../../apis/masterdata/masterdata.type';
import { findArticleId, updateSparePartArticles } from '../../business/articleHelper';
import { isPogoAuthorized } from '../../store/selectors/intervention';
import { type ProductTabType } from '../InterventionPage/CatalogAddLinks/CatalogAddLinks';

const getEntitiesArticles = (entities: Entity[], articles: Article[]) =>
  entities.map(entity => ({
    ...entity,
    articles: entity.articles?.length === 0 ? articles.filter(article => article.modelCode === entity.modelCode) : entity.articles,
  }));

const addOrUpdateSparePart = (sparePart: Entity, spareParts: Entity[]): Entity[] => {
  if (spareParts.some(existingSparePart => existingSparePart.modelCode === sparePart.modelCode)) {
    return spareParts.map(existingSparePart =>
      existingSparePart.modelCode === sparePart.modelCode
        ? {
            ...existingSparePart,
            quantity: 1,
            articles: existingSparePart.articles
              .map(article => ({ ...article, selected: false }))
              .concat({ ...sparePart.articles[0], selected: true }),
          }
        : existingSparePart
    );
  }

  return spareParts.concat(sparePart);
};

export const initialState: NatureCategory[] = [];

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    emptyNatureCategoriesAction: () => initialState,
    initNatureCategoriesAction: (_, action: PayloadAction<NatureCategory[]>) => action.payload,
    replaceRelatedSparePartsAction: (state, action: PayloadAction<{ categoryId: number; natureId: number; spareParts: Entity[] }>) => {
      const { natureId, spareParts, categoryId } = action.payload;
      return state.map(natureCategory =>
        natureCategory.id === categoryId
          ? {
              ...natureCategory,
              natures: natureCategory.natures.map(nature => {
                if (nature.natureId === natureId) {
                  return {
                    ...nature,
                    spareParts: nature.spareParts.filter(sparePart => !sparePart.related).concat(spareParts),
                  };
                }
                return nature;
              }),
            }
          : natureCategory
      );
    },
    selectArticleAction: (state, action: PayloadAction<{ categoryId: number; modelCode: number; articleId: number }>) => {
      const { articleId, modelCode, categoryId } = action.payload;
      const setQuantity = (articles: Article[]) => (!!articleId && articles.find(findArticleId(articleId))?.available ? 1 : 0);
      const mapSpareParts = (sparePart: Entity) => ({
        ...sparePart,
        quantity: sparePart.modelCode === modelCode ? setQuantity(sparePart.articles) : sparePart.quantity,
        articles:
          sparePart.modelCode === modelCode
            ? sparePart.articles.map(article => ({
                ...article,
                selected: article.articleId === articleId,
              }))
            : sparePart.articles,
      });

      return state.map(natureCategory =>
        natureCategory.id === categoryId
          ? {
              ...natureCategory,
              natures: natureCategory.natures.map(nature => ({
                ...nature,
                spareParts: nature.spareParts.map(mapSpareParts),
              })),
            }
          : natureCategory
      );
    },
    fillSparePartsAction: (state, action: PayloadAction<Entity[]>) => {
      const spareParts = action.payload;
      const mapEntity = (sparePartToUpdate: Entity): Entity => {
        const sparePart = spareParts.find(newSparePart => newSparePart.modelCode === sparePartToUpdate.modelCode);
        return {
          ...sparePartToUpdate,
          ...(sparePart
            ? {
                name: sparePart.name,
                imageUrl: sparePart.imageUrl,
                quantity: 0,
                articles: sparePart.articles || [],
              }
            : {}),
        };
      };
      return state.map(natureCategory => ({
        ...natureCategory,
        natures: natureCategory.natures.map(nature => ({
          ...nature,
          spareParts: nature.selected ? nature.spareParts.map(mapEntity) : nature.spareParts,
        })),
      }));
    },
    setSparePartQuantityAction: (state, action: PayloadAction<EntityQuantityUpdate>) => {
      const { modelCode, quantity, categoryId, contextType } = action.payload;
      return state.map(natureCategory =>
        natureCategory.id === categoryId
          ? {
              ...natureCategory,
              natures: natureCategory.natures.map(nature => ({
                ...nature,
                spareParts: nature.spareParts.map(sparePart => ({
                  ...sparePart,
                  quantity: sparePart.modelCode === modelCode ? quantity : sparePart.quantity,
                  articles: updateSparePartArticles(sparePart.articles, contextType),
                })),
              })),
            }
          : natureCategory
      );
    },
    setServiceQuantityAction: (state, action: PayloadAction<EntityQuantityUpdate>) => {
      const { modelCode, quantity, natureId, categoryId, contextType } = action.payload;
      return state.map(natureCategory =>
        natureCategory.id === categoryId
          ? {
              ...natureCategory,
              natures: natureCategory.natures.map(nature => ({
                ...nature,
                services:
                  nature.natureId === natureId
                    ? nature.services.map(service => ({
                        ...service,
                        quantity: service.modelCode === modelCode ? quantity : service.quantity,
                        articles:
                          contextType?.internal &&
                          service.articles.some(article => isPogoAuthorized(contextType.authorizedPogos, article.gridValueId))
                            ? service.articles.map(article => ({
                                ...article,
                                selected: isPogoAuthorized(contextType.authorizedPogos, article.gridValueId),
                              }))
                            : service.articles,
                      }))
                    : nature.services,
              })),
            }
          : natureCategory
      );
    },
    setArticleStockAction: (state, action: PayloadAction<{ categoryId: number; modelCode: number; articleId: number; stock: number }>) => {
      const { articleId, modelCode, stock, categoryId } = action.payload;
      return state.map(natureCategory =>
        natureCategory.id === categoryId
          ? {
              ...natureCategory,
              natures: natureCategory.natures.map(nature => ({
                ...nature,
                spareParts: nature.spareParts.map(sparePart => ({
                  ...sparePart,
                  articles:
                    sparePart.modelCode === modelCode
                      ? sparePart.articles.map(article => ({
                          ...article,
                          stock: article.articleId === articleId ? stock : article.stock,
                        }))
                      : sparePart.articles,
                })),
              })),
            }
          : natureCategory
      );
    },
    setSparePartsArticlesAction: (state, action: PayloadAction<Article[]>) => {
      const articles = [...action.payload].sort((a, b) => a.labelGrid?.localeCompare(b.labelGrid, undefined, { numeric: true, sensitivity: 'base' }));
      return state.map(natureCategory => ({
        ...natureCategory,
        natures: natureCategory.natures.map(nature => ({
          ...nature,
          spareParts: getEntitiesArticles(nature.spareParts, articles),
        })),
      }));
    },
    setServiceArticlesAction: (state, action: PayloadAction<Article[]>) => {
      const articles = action.payload;
      return state.map(natureCategory => ({
        ...natureCategory,
        natures: natureCategory.natures.map(nature => ({
          ...nature,
          services: getEntitiesArticles(nature.services, articles),
        })),
      }));
    },
    selectNatureCategoryAction: (state, action: PayloadAction<number>) =>
      state.map(natureCategory => ({
        ...natureCategory,
        selected: natureCategory.id === action.payload,
        natures: natureCategory.natures.map(nature => ({
          ...nature,
          selected: natureCategory.id === action.payload && natureCategory.natures.length === 1,
        })),
      })),
    selectSparePartNatureAction: (state, action: PayloadAction<{ natureCategoryId: number; natureId: number }>) =>
      state.map(natureCategory => ({
        ...natureCategory,
        selected: natureCategory.id === action.payload.natureCategoryId,
        natures: natureCategory.natures.map(nature => ({
          ...nature,
          selected: natureCategory.id === action.payload.natureCategoryId && nature.natureId === action.payload.natureId,
        })),
      })),
    resetSearchAction: state =>
      state.map(natureCategory => ({
        ...natureCategory,
        selected: false,
        natures: natureCategory.natures.map(nature => ({ ...nature, selected: false })),
      })),
    addSparePartToNatureAction: (state, action: PayloadAction<{ categoryId: number; natureId: number; sparePart: Entity }>) => {
      const { natureId, sparePart, categoryId } = action.payload;
      return state.map(natureCategory =>
        natureCategory.id === categoryId
          ? {
              ...natureCategory,
              natures: natureCategory.natures.map(nature =>
                nature.natureId === natureId
                  ? {
                      ...nature,
                      spareParts: addOrUpdateSparePart(sparePart, nature.spareParts),
                    }
                  : nature
              ),
            }
          : natureCategory
      );
    },
    filterEntitiesAction: (
      state,
      action: PayloadAction<{ categoryId: number; modelCodes: number[]; tabType: keyof typeof ProductTabType; natureId?: number }>
    ) => {
      const { modelCodes, tabType, natureId, categoryId } = action.payload;
      const mapFilterFalse = (entity: Entity): Entity => ({ ...entity, filtered: false });

      return state.map(natureCategory =>
        !categoryId || natureCategory.id === categoryId
          ? {
              ...natureCategory,
              natures: natureCategory.natures.map(nature =>
                !natureId || nature.natureId === natureId
                  ? {
                      ...nature,
                      [tabType]: nature[tabType].map(entity => ({ ...entity, filtered: modelCodes.includes(entity.modelCode) })),
                    }
                  : {
                      ...nature,
                      [tabType]: nature[tabType].map(mapFilterFalse),
                    }
              ),
            }
          : {
              ...natureCategory,
              natures: natureCategory.natures.map(nature => ({
                ...nature,
                [tabType]: nature[tabType].map(mapFilterFalse),
              })),
            }
      );
    },
  },
});

export const {
  emptyNatureCategoriesAction,
  selectArticleAction,
  setArticleStockAction,
  setServiceQuantityAction,
  setSparePartQuantityAction,
  setSparePartsArticlesAction,
  setServiceArticlesAction,
  fillSparePartsAction,
  initNatureCategoriesAction,
  selectNatureCategoryAction,
  selectSparePartNatureAction,
  replaceRelatedSparePartsAction,
  addSparePartToNatureAction,
  filterEntitiesAction,
  resetSearchAction,
} = productSlice.actions;

export default productSlice.reducer;
export const { reducer: productReducer, actions } = productSlice;
