// @ts-strict-ignore
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import cache from 'lscache';

import { type ApisDomains, type Configuration, type Secrets, type ServiceApiKey, type ServiceApiName } from '../utils/config/configuration.type';
import { authenticationStorageKey } from '../components/auth/constants';

export interface ConfigState {
  isProd: boolean;
  hasMedallia: boolean;
  GTMTrackingID?: string;
  isFreeClosingAuthorized?: boolean;
  hideEventNotifications?: boolean;
  config: Configuration;
  token: string;
  secrets: Secrets;
  hasLog: boolean;
}

const filterInternalApis = (serviceName: ServiceApiName): boolean => !/ICARE_BACK|USERINFO/.test(serviceName);

export const initialState: ConfigState = {
  isProd: true,
  hasMedallia: false,
  GTMTrackingID: '',
  isFreeClosingAuthorized: false,
  hideEventNotifications: false,
  hasLog: true,
  token: cache.get(`${authenticationStorageKey}-token`),
  config: {
    idpUrl: '',
    services: {
      CUSTOMER: {
        url: '',
        api_key: '',
      },
      CUSTOMER_CN: {
        url: '',
        api_key: '',
      },
      MASTERDATA: {
        url: '',
        api_key: '',
      },
      MASTERPRICE: {
        url: '',
        api_key: '',
      },
      SHARE: {
        url: '',
        api_key: '',
      },
      STOCK_PICTURE: {
        url: '',
        api_key: '',
      },
      SUPPLY_ORDER: {
        url: '',
        api_key: '',
      },
      WAREHOUSE_ID: {
        url: '',
        api_key: '',
      },
      WAREHOUSE_STOCK: {
        url: '',
        api_key: '',
      },
      FNR: {
        url: '',
        api_key: '',
      },
      WORKSHOP: {
        url: '',
        api_key: '',
      },
      WORKSHOP_V3: {
        url: '',
        api_key: '',
      },
      WORKSHOP_ANALYTICS: {
        url: '',
        api_key: '',
      },
      DIRECTORY: {
        url: '',
        api_key: '',
      },
      DEFLOC: {
        url: '',
        api_key: '',
      },
      GEO_LOOK: {
        url: '',
        api_key: '',
      },
      ONE_INVOICE: {
        url: '',
        api_key: '',
      },
      ICARE_BACK: {
        url: '',
        api_key: '',
      },
      USERINFO: {
        url: '',
        api_key: '',
      },
    },
  },
  secrets: {
    clientId: '',
    clientSecret: '',
  },
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  extraReducers: builder => builder.addCase('resetStore', () => initialState),
  reducers: {
    setIsProd: (state, action: PayloadAction<boolean>) => {
      state.isProd = action.payload;
    },
    setHasMedallia: (state, action: PayloadAction<boolean>) => {
      state.hasMedallia = action.payload;
    },
    setGTMTrackingID: (state, action: PayloadAction<string>) => {
      state.GTMTrackingID = action.payload;
    },
    setIsFreeClosingAuthorized: (state, action: PayloadAction<boolean>) => {
      state.isFreeClosingAuthorized = action.payload;
    },
    setHideEventNotifications: (state, action: PayloadAction<boolean>) => {
      state.hideEventNotifications = action.payload;
    },
    setHasLog: (state, action: PayloadAction<boolean>) => {
      state.hasLog = action.payload;
    },
    updateToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    updateDomains: (state, action: PayloadAction<ApisDomains>) => {
      const { urls, idpUrl } = action.payload;
      state.config.idpUrl = idpUrl;
      const { services } = state.config;
      Object.entries(urls).forEach(([key, value]: [ServiceApiName, string]) => {
        services[key].url = value;
      });
    },
    updateSecrets: (state, action: PayloadAction<Secrets>) => {
      state.secrets = action.payload;
    },
    updateConfigApiKeys: (state, action: PayloadAction<ServiceApiKey>) => {
      const { services } = state.config;
      Object.keys(services)
        .filter(filterInternalApis)
        .forEach((apiItem: ServiceApiName) => {
          services[apiItem].api_key = action.payload[apiItem].api_key;
        });
    },
  },
});

export const {
  reducer: configReducer,
  actions: {
    setIsProd,
    setHasMedallia,
    updateToken,
    updateConfigApiKeys,
    updateSecrets,
    updateDomains,
    setHasLog,
    setGTMTrackingID,
    setIsFreeClosingAuthorized,
    setHideEventNotifications,
  },
} = configSlice;
