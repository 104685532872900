type StringMap = {
  [name: string]: string;
};

const allowedKeys: StringMap = {
  37: 'left',
  38: 'up',
  39: 'right',
  40: 'down',
  65: 'a',
  66: 'b',
};

const konamiCode = ['up', 'up', 'down', 'down', 'left', 'right', 'left', 'right', 'b', 'a'];
let konamiCodePosition = 0;
let currentOrientation = 0;

const setNextOrientation = () => {
  currentOrientation -= 180;
};

const activateKonami = () => {
  setNextOrientation();
  document.body.style.setProperty('-webkit-transform', `rotate(${currentOrientation}deg)`, undefined);
  // eslint-disable-next-line no-alert
  alert('Upside down, you turn me :p');
};

export default (e: React.KeyboardEvent<HTMLDivElement>) => {
  const key = allowedKeys[e.keyCode];
  const requiredKey = konamiCode[konamiCodePosition];
  if (key === requiredKey) {
    konamiCodePosition++;
    if (konamiCodePosition === konamiCode.length) {
      activateKonami();
      konamiCodePosition = 0;
    }
  } else {
    konamiCodePosition = 0;
  }
};
