const MEDALLIA_SCRIPT_SRC = 'https://resources.digital-cloud-west.medallia.com/wdcwest/650644/onsite/embed.js';

interface Medallia {
  env: string;
  jobName: string;
  languageCode: string;
  storeId: string;
  uid: string;
  userCountry: string;
}

declare global {
  interface Window {
    KAMPYLE_ONSITE_SDK: any;
    medalliaCustomParams: Medallia;
  }
}
const injectScript = () => {
  ((document, htmlTag, id) => {
    if (document.querySelector(`${id}`)) {
      return;
    }

    const js = document.createElement(htmlTag) as HTMLScriptElement;
    js.id = id;
    js.async = true;
    js.src = MEDALLIA_SCRIPT_SRC;

    const fjs = document.querySelectorAll(htmlTag)[0];
    if (fjs) {
      fjs.parentNode?.insertBefore(js, fjs);
    }
  })(window?.document, 'script', 'medallia');
};
let count = 0;
export const setGlobalValuesForMedallia = ({ env, jobName, languageCode, storeId, uid, userCountry }: Medallia) => {
  window.medalliaCustomParams = {
    env,
    jobName,
    languageCode,
    storeId,
    uid,
    userCountry,
  };

  if (window?.KAMPYLE_ONSITE_SDK) {
    window.KAMPYLE_ONSITE_SDK.updatePageView();
  } else {
    setTimeout(() => {
      count++;
      if (count <= 5) {
        setGlobalValuesForMedallia({ env, jobName, languageCode, storeId, uid, userCountry });
      }
    }, 1000);
  }
};

export const initializeMedallia = () => {
  injectScript();
};
