import { useCallback, useEffect } from 'react';

import { type Blocker, type Transition } from './History';
import history from '../../utils/history/customHistory';

function useBlocker(blocker: Blocker, when = true) {
  useEffect(() => {
    if (!when) {
      return () => {};
    }

    const unblock = history.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

export function usePrompt(message: string | ((location: Transition['location'], action: Transition['action']) => boolean), when = true) {
  const blocker = useCallback(
    (tx: Transition) => {
      let response;
      if (typeof message === 'function') {
        response = message(tx.location, tx.action);
        if (typeof response === 'string') {
          response = window.confirm(response);
        }
      } else {
        response = window.confirm(message);
      }
      if (response) {
        tx.retry();
      }
    },
    [message]
  );
  return useBlocker(blocker, when);
}
