import { createApi } from '@reduxjs/toolkit/query/react';

import { buildICareBackBaseQuery } from '../rtkQuery/baseQuery';
import { type GetBookingsRequestParams, type Booking } from './bookings.types';

export const bookingsApi = createApi({
  reducerPath: 'bookingsApi',
  baseQuery: buildICareBackBaseQuery('/bookings'),
  endpoints: builder => ({
    getBookings: builder.query<Booking[], GetBookingsRequestParams>({
      query: params => ({
        url: '/',
        params,
      }),
    }),
  }),
});
