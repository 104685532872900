import { type BaseQueryFn, type FetchArgs, fetchBaseQuery, type FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { type RootState } from '../../store/store';
import { selectLocaleToString } from '../../store/selectors/i18n';

const mainBaseQuery = fetchBaseQuery({
  prepareHeaders: (headers: Headers, { getState }) => {
    const rootState = getState() as RootState;
    const token = rootState.configuration.token;
    const locale = selectLocaleToString(rootState);
    headers.set('Authorization', `Bearer ${token}`);
    headers.set('Accept-Language', locale);
  },
});

export const buildICareBackBaseQuery =
  (resourceParh: string): BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) => {
    const iCareBackUrl = (api.getState() as RootState).configuration.config.services.ICARE_BACK.url;
    return mainBaseQuery(
      {
        ...args,
        url: `${iCareBackUrl}${resourceParh}`,
      },
      api,
      extraOptions
    );
  };
