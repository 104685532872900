// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loader-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-top: 50px;\n}\n\n.loader {\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  margin-bottom: 10px;\n  border: 5px solid lightgrey;\n  border-top: 5px solid #344450;\n}\n\n.loader {\n  animation: cqakyBKukL179oq-c0b-eQ\\=\\= 2s linear infinite;\n}\n\n@keyframes cqakyBKukL179oq-c0b-eQ\\=\\= {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/utils/loader/external-loader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,2BAA2B;EAC3B,6BAA6B;AAC/B;;AAEA;EACE,wDAAyC;AAC3C;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[":global .loader-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-top: 50px;\n}\n\n:global .loader {\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  margin-bottom: 10px;\n  border: 5px solid lightgrey;\n  border-top: 5px solid #344450;\n}\n\n:global .loader :local {\n  animation: loader_spin 2s linear infinite;\n}\n\n@keyframes loader_spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader_spin": "cqakyBKukL179oq-c0b-eQ=="
};
export default ___CSS_LOADER_EXPORT___;
