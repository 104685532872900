import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type RootState } from './store';
import { type StoreItem } from '../apis/startup/startup.type';

export interface StoreListState {
  storeList: StoreItem[];
}

const initialState: StoreListState = {
  storeList: [],
};

const storeListSlice = createSlice({
  name: 'i18n',
  initialState,
  extraReducers: builder => builder.addCase('resetStore', () => initialState),
  reducers: {
    updateStoreList: (state, action: PayloadAction<StoreItem[]>) => {
      state.storeList = action.payload;
    },
  },
});

export const {
  reducer: storeListReducer,
  actions: { updateStoreList },
} = storeListSlice;

const selectSitesInfos = (state: RootState) => ({ storeList: state.storeList.storeList, rwId: state.userInfo.regionalWorkshopId });
const toSorted = (compareA: StoreItem, compareB: StoreItem): number => compareA.id - compareB.id;

export const selectAllStores = (state: RootState) => state.storeList.storeList;
export const selectAllowedStores = createSelector(selectAllStores, storeList => storeList.filter(storeItem => storeItem.isAllowed));
export const selectRWRelatedStores = createSelector(selectAllStores, storeList => storeList.filter(storeItem => storeItem.isRWRelated));
export const selectHasRWRelatedStores = createSelector(selectRWRelatedStores, storeList => storeList.length > 0);
export const selectRWStores = createSelector(selectAllStores, storeList => storeList.filter(storeItem => storeItem.isRW));
export const selectPrioritySiteAndTheRest = createSelector(selectSitesInfos, ({ rwId, storeList }) => {
  const allStoreList = [...storeList].sort(toSorted);
  const firstSites = rwId ? allStoreList.filter(a => a.id === rwId) : allStoreList.filter(a => a.isRWRelated);
  const exclude = firstSites.reduce((acc, curr) => {
    acc[curr.id.toFixed()] = true;
    return acc;
  }, {} as Record<string, boolean>);
  const theRest = allStoreList.filter(a => !exclude[a.id]);

  allStoreList.sort((a, b) => {
    const sortForRW = a.id === rwId ? toSorted(b, a) : 0;
    const sortForRelated = a.isRWRelated ? toSorted(b, a) : 0;
    return rwId ? sortForRW : sortForRelated;
  });
  return [...firstSites, ...theRest];
});
