import { useAppFetch } from '../../utils/apis/useAppFetch';

interface IlinkProcess {
  id: number;
  label: string;
  pixl_id: string;
  ranking: null | string;
}

export const useFetchIlinkProcesses = (processId: string) =>
  useAppFetch<IlinkProcess | null>({ requestId: `ilink-processes-${processId}`, url: `ilink/v1/processes/${processId}`, fullLocale: true }, [
    processId,
  ]);
