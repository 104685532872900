// @ts-strict-ignore
import cache from 'lscache';

import { authenticationStorageKey, storeStorageKey } from '../components/auth/constants';
import { buildHeaders, buildRequest, buildUrl, fetchRequest } from '../utils/apis/RestApi';
import { handleError } from '../business/helpers';
import store from '../store/store';
import { createLog } from './logger';
import { getWorkshopInterventionStates, getWorkshopInterventionTypes } from './interventions/interventions';
import { initWorkshopState } from '../store/workshopInterventionSlice';
import { fetchStartup } from './startup/startup';
import { type StoreItem } from './startup/startup.type';
import { updateStoreList } from '../store/store-list';
import { type Locale } from '../store/i18nSlice';
import { formatLocales } from '../utils/utils';
import { type ServiceApiKey } from '../utils/config/configuration.type';
import { type IcareSiteUserInfo } from '../store/userInfoSlice';

const logger = createLog('api/userinfo');

interface UserInfoStartup {
  userInfo: IcareSiteUserInfo;
  locales: Locale[];
  apiKeys: ServiceApiKey;
}

export const getUserInfo = async (sitePartyNumber?: string): Promise<UserInfoStartup> => {
  const storedStore = cache.get(storeStorageKey)?.toString();

  const {
    apiKeys,
    countryId,
    storeInfo,
    storeList,
    availableLang,
    selectedSite,
    parameters: {
      customerCreationRequiredFields,
      isAllocationAuthorized,
      isHandlingNewProduct,
      isPurchaseActive,
      crmZone,
      customerCommunication,
      traceabilityActivation,
      newCustomerPurchasesPage,
      sparePartsOrder,
      bookings,
    },
  } = await fetchStartup(storedStore, sitePartyNumber);

  const locales = formatLocales(availableLang || ['fr-FR', 'en-GB']);

  try {
    const [workshopInterventionStates, workshopInterventionTypes] = await Promise.all([
      getWorkshopInterventionStates(selectedSite.id),
      getWorkshopInterventionTypes(selectedSite.id),
    ]);

    store.dispatch(
      updateStoreList(
        storeList.map(
          ({ id, name, countryId: countryCode, isAllowed, isRW, isRWRelated }): StoreItem => ({
            id,
            name,
            countryCode,
            isAllowed: Boolean(isAllowed),
            isRW: Boolean(isRW),
            isRWRelated: Boolean(isRWRelated),
          })
        )
      )
    );
    store.dispatch(
      initWorkshopState({
        workshopInterventionStates,
        storeInformation: {
          id: storeInfo.siteNumber,
          isRegionalWorkshop: selectedSite.isRegionalWorkshop,
          label: selectedSite.name,
          allowSad: selectedSite.allowSad,
          contexts: storeInfo.contexts,
          displayPriceWithoutTaxes: storeInfo.displayPriceWithoutTaxes,
          maxExpressAmount: selectedSite.maxExpressAmount,
        },
        workshopInterventionTypes,
      })
    );

    const mergedUserInfo: IcareSiteUserInfo = {
      regionalWorkshopId: storeInfo.defaultRegionalWorkshop,
      selectedSite: {
        ...selectedSite,
        isRegionalWorkshop: selectedSite.isRegionalWorkshop,
        maxExpressAmount: storeInfo.maxExpressAmount,
        allowSad: Boolean(storeInfo.allowSad),
        displayPriceWithoutTaxes: storeInfo.displayPriceWithoutTaxes,
      },
      crmZone,
      countryId: Number(countryId),
      hasSADNewProduct: isHandlingNewProduct,
      customerCreationRequiredFields: customerCreationRequiredFields.reduce((acc, field) => ({ ...acc, [field]: true }), {}),
      isAllocationAuthorized,
      isPurchaseActive,
      customerCommunication,
      traceabilityActivation: Boolean(traceabilityActivation),
      newCustomerPurchasesPage: Boolean(newCustomerPurchasesPage),
      sparePartsOrder,
      isBookingsEnabled: bookings,
    };

    return {
      apiKeys,
      locales,
      userInfo: mergedUserInfo,
    };
  } catch (error) {
    logger.log(new Error(error));
    return handleError('UserInfo Request');
  }
};

export const logout = (): Promise<any> => {
  const sessionIdentifier = cache.get(`${authenticationStorageKey}-sessionIdentifierKey`);
  const url = buildUrl('ICARE_BACK', `session/${sessionIdentifier}`);
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const request = buildRequest(url, 'DELETE', headers);
  return fetchRequest(request)
    .then(response => response.json())
    .catch(handleError);
};
