// @ts-strict-ignore
/* eslint-disable no-restricted-syntax */
import { v4 } from 'uuid';
import camelcaseKeys from 'camelcase-keys';

import store from '../store/store';
import { NATURE_ID_WITHOUT_SPARE_PART } from '../apis/ilink';
import { type Entity, type ILinkSparePartsAndServicesNature, type InterventionNature } from '../apis/ilink.type';
import { type Article, NUM_GRID } from '../apis/masterdata/masterdata.type';
import { Pogos, type POGO, PAYABLE_POGO_ID, translateFromPogo, translatePogoToPogoId } from '../apis/interventions/intervention.const';
import { removeEntity, updateEntity, addServicesAndSparePartsToIntervention } from '../apis/interventions/interventions';
import { NO_SIZE_GRID_ID, SERVICE_GRID_ID } from '../apis/prices';
import { addOrUpdateInterventionNatures, updatePecService } from '../store/interventionSlice';
import { getServicesArticles } from '../components/ProductPage/product';
import {
  type ServiceWorkshop,
  type SparePartWorkshop,
  type WorkshopIntervention,
  InterventionType,
  type PogoDetail,
} from '../apis/interventions/intervention.type';
import { getModelsInformation } from '../apis/spid';
import { availableArticleLifeStages, emptyArticle, findSelectedArticle, findArticleId } from './articleHelper';
import { type ModelInformation } from '../apis/spid.type';
import { getPrice } from './priceHelper';
import { checkAvailabilityForArticles, getArticles, getArticlesDetails, isServiceNature } from '../apis/masterdata/masterdata';
import translate from '../utils/i18n/SwappingIntlProvider';
import { colors, externalToastStore } from '../utils/toaster/ToastProvider';
import { normalizedString } from './helpers';
import { isInterventionDisabled } from './interventionHelper';
import { getStockForArticles } from '../apis/stockPicture';
import { type StockPictureResponse } from '../apis/stockPicture.type';
import { getArticleIdFromCode } from './referenceCodeHelper';

export interface EntitySearch {
  label: string;
  modelCode: number;
  nature: {
    id: number;
    label: string;
  };
  natureCategory: {
    id: number;
    label: string;
  };
}

export const isInterventionOgea = (type: InterventionType) => type === InterventionType.ogea;

const notExistingArticleId = 999999;
export const notExistingArticle = (modelCode?: number): Article => ({
  articleId: notExistingArticleId,
  modelCode,
  priceType: '',
  value: null,
  selected: true,
});

const buildService = (articles: Article[], isOgea: boolean) =>
  articles.reduce((acc, article) => {
    if ((isOgea && article.gridValueId !== Pogos.GARANTI) || (!isOgea && article.gridValueId !== Pogos['ASSURANCE OGEA'])) {
      if (article.gridId === SERVICE_GRID_ID) {
        const isPayable = article.gridValueId === Pogos.PAYANT;
        acc.push({
          ...notExistingArticle(article.modelCode),
          ...article,
          estimatedTime: undefined,
          selected: isPayable,
          free: !isPayable,
        });
      } else {
        const index = acc.findIndex(findArticleId(article.articleId));
        if (article.gridId === NUM_GRID.GRID_THEORETICAL_TIME && index > -1) {
          acc[index].estimatedTime = Number(article?.gridValueId?.replace(' MN', ''));
        }
      }
    }
    return acc;
  }, [] as Article[]);

export const buildServiceArticles = (articles: Article[], interventionType: InterventionType) => {
  const sortedArticles = [...articles]
    .filter(article => article.available)
    .sort((a, b) => a.articleId - b.articleId || (a.gridId ?? 0) - (b.gridId ?? 0));
  let serviceArticles = buildService(sortedArticles, isInterventionOgea(interventionType));

  if (!serviceArticles.length) {
    return [notExistingArticle(articles.at(0)?.modelCode)];
  }

  if ([InterventionType.ogea, InterventionType.homeService].includes(interventionType)) {
    const pogo = isInterventionOgea(interventionType) ? Pogos['ASSURANCE OGEA'] : Pogos.GARANTI;
    const hasType = serviceArticles.find(serviceArticle => serviceArticle.gridValueId === pogo);
    serviceArticles = serviceArticles.map(article => ({
      ...article,
      selected: hasType ? article.gridValueId === pogo : article.selected,
    }));
  }

  if (!serviceArticles.some(article => article.selected)) {
    serviceArticles[0].selected = true;
  }

  return serviceArticles;
};

export const buildSparePartArticles = (articles: Article[]) => {
  let builtArticles: Article[] = [];
  if (articles.length === 0) {
    builtArticles = [emptyArticle()];
  } else if (articles[0].gridId === NO_SIZE_GRID_ID) {
    builtArticles = articles
      .filter((article, index) => index === articles.findIndex(findArticleId(article.articleId)))
      .map(article => ({ ...article, labelGrid: undefined }));
    if (builtArticles.length > 1) {
      builtArticles = builtArticles.map(article => ({ ...article, labelGrid: article.articleId.toString() }));
    }
  } else {
    builtArticles = [...articles]
      .sort((a, b) => a.articleId - b.articleId || (b.gridId ?? 0) - (a.gridId ?? 0))
      .reduce((acc, article) => {
        if (!acc.some(findArticleId(article.articleId))) {
          acc.push(article);
        } else {
          const index = acc.findIndex(articleToCompare => articleToCompare.eanNum === article.eanNum);
          if (index > -1) {
            acc[index].labelGrid = `${acc[index].labelGrid} - ${article.labelGrid}`;
          }
        }
        return acc;
      }, [] as Article[]);
  }

  return builtArticles.length === 1 ? builtArticles.map(article => ({ ...article, selected: true })) : builtArticles;
};

interface HandleServiceSelectionProps {
  selectedLabel: string;
  service: Entity;
  natureId: number;
}

export const handleServiceSelection = ({ selectedLabel, service, natureId }: HandleServiceSelectionProps) => {
  const intervention = store.getState().intervention;
  const stateServices = intervention.natures.flatMap(stateNature => stateNature.services);
  const docNumber = intervention.footer.docNumber;
  const serviceToUpdate = {
    ...service,
    articles: service.articles.map(article => ({ ...article, selected: article?.labelGrid?.toUpperCase() === selectedLabel.toUpperCase() })),
  };
  if (docNumber) {
    const promises: Promise<any>[] = [];
    const samePogoService = stateServices.find(
      stateService =>
        stateService.modelCode === service.modelCode &&
        stateService.articles.find(findSelectedArticle)?.labelGrid?.toUpperCase() === selectedLabel.toUpperCase()
    );

    const oldPogo = service.articles.find(findSelectedArticle)?.gridValueId;
    if (oldPogo) {
      let pogoToUpdate = translatePogoToPogoId(oldPogo);
      const newPogo = serviceToUpdate.articles.find(findSelectedArticle)?.gridValueId;
      let quantity = serviceToUpdate.quantity;
      if (samePogoService && newPogo) {
        pogoToUpdate = translatePogoToPogoId(newPogo);
        quantity = serviceToUpdate.quantity + samePogoService.quantity;
        promises.push(removeEntity(docNumber, service.modelCode, translatePogoToPogoId(oldPogo), 'services'));
      }
      if (newPogo) {
        promises.push(
          updateEntity({
            docNumber,
            articleId: service.modelCode,
            oldPogo: pogoToUpdate,
            pogoId: translatePogoToPogoId(newPogo),
            quantity,
            type: 'services',
          })
        );
      }
    }
    return Promise.all(promises).then(() => {
      store.dispatch(updatePecService({ natureId, service: serviceToUpdate }));
    });
  }

  store.dispatch(updatePecService({ natureId, service: serviceToUpdate }));
  return Promise.resolve();
};

interface HandleSparePartSelectionProps {
  sparePart: Entity;
  natureId: number;
  updatePecSparePart: (natureId: number, sparePart: Entity) => void;
  newGridValueId: POGO;
  pogoLabel: string;
}

export const handleSparePartSelection = ({ sparePart, natureId, updatePecSparePart, newGridValueId, pogoLabel }: HandleSparePartSelectionProps) => {
  const intervention = store.getState().intervention;
  const interventionSpareParts = intervention.natures.flatMap(stateNature => stateNature.spareParts);
  const docNumber = intervention.footer.docNumber;
  const sparePartToUpdate = {
    ...sparePart,
    articles: sparePart.articles.map(article =>
      article.selected
        ? {
            ...article,
            free: newGridValueId !== Pogos.PAYANT,
            gridValueId: newGridValueId,
            priceType: pogoLabel,
          }
        : article
    ),
  };
  if (docNumber) {
    const selectedArticle = sparePart.articles.find(findSelectedArticle);
    const promises: Promise<any>[] = [];
    if (selectedArticle) {
      const samePogoSparePart = interventionSpareParts.find(
        interventionSparePart =>
          interventionSparePart.articles.find(findSelectedArticle)?.articleId === selectedArticle.articleId &&
          interventionSparePart.articles.find(findSelectedArticle)?.gridValueId?.toUpperCase() === newGridValueId.toUpperCase()
      );

      const { articleId, gridValueId } = selectedArticle;
      if (gridValueId) {
        let oldPogo = translatePogoToPogoId(gridValueId);
        let quantity = sparePart.quantity;
        if (samePogoSparePart) {
          oldPogo = translatePogoToPogoId(newGridValueId);
          quantity = sparePartToUpdate.quantity + samePogoSparePart.quantity;
          promises.push(removeEntity(docNumber, articleId, translatePogoToPogoId(gridValueId), 'spare_parts'));
        }
        promises.push(
          updateEntity({
            docNumber,
            articleId,
            oldPogo,
            pogoId: translatePogoToPogoId(newGridValueId),
            quantity,
            type: 'spare_parts',
          })
        );
      }
    }
    return Promise.all(promises).then(() => updatePecSparePart(natureId, sparePartToUpdate));
  }

  updatePecSparePart(natureId, sparePartToUpdate);
  return Promise.resolve();
};

export const formatServices = (services: ServiceWorkshop[], modelsInformation: ModelInformation[], interventionType?: InterventionType) =>
  getServicesArticles(
    services.map(service => service.modelCode),
    interventionType
  ).then(allArticles =>
    services.map(service => {
      const serviceModel = modelsInformation.find(image => image.modelCode === service.modelCode);
      const articles = [...allArticles.filter(model => model.modelCode === service.modelCode)];
      if (!articles.some(findArticleId(service.itemCode))) {
        articles.push({
          articleId: service.itemCode,
          free: service.pogoId !== PAYABLE_POGO_ID,
          value: getPrice(service),
          priceType: service.pogo.toUpperCase(),
          labelGrid: service.pogo.toUpperCase(),
          estimatedTime: service.theoreticalTime,
          gridValueId: translateFromPogo(service.pogoId),
          selected: true,
          disabled: true,
          modelCode: service.modelCode,
        });
      }
      return {
        id: v4(),
        name: serviceModel?.label,
        modelCode: service.modelCode,
        quantity: service.quantity,
        imageUrl: serviceModel?.imageUrl,
        articles: articles.map(article => ({
          ...article,
          estimatedTime: service.theoreticalTime,
          selected: article.articleId === service.itemCode,
          value: article.articleId === service.itemCode ? getPrice(service) : article.value,
        })),
      };
    })
  );

export const handleEnforcedServices = async (docNumber: string, newServices: ServiceWorkshop[], pecServices: Entity[]) => {
  const returnedServices = camelcaseKeys<ServiceWorkshop[]>(newServices, { deep: true });
  const enforcedServices = returnedServices.filter(
    returnedService => !pecServices.flatMap(pecService => pecService.modelCode).includes(returnedService.modelCode)
  );
  const servicesModelCodes = enforcedServices.map(service => service.modelCode);
  if (servicesModelCodes.length) {
    await getModelsInformation(servicesModelCodes).then(modelsInformation =>
      formatServices(enforcedServices, modelsInformation).then(formattedServices => {
        store.dispatch(
          addOrUpdateInterventionNatures({
            interventionNatures: [
              {
                // TODO : récupérer la bonne nature
                natureId: 0,
                natureTitle: '',
                services: formattedServices,
                spareParts: [],
              },
            ],
          })
        );
      })
    );
  }

  const updateEntitiesPromise: Promise<void>[] = [];
  const enforcedServiceIds = returnedServices.reduce((acc: number[], service: ServiceWorkshop) => {
    const existingService = pecServices.find((pecService: Entity) => pecService.modelCode === service.modelCode);

    if (existingService && existingService.quantity > service.quantity) {
      const gridValueId = existingService.articles.find(findSelectedArticle)?.gridValueId;
      if (gridValueId) {
        const pogo = translatePogoToPogoId(gridValueId);
        updateEntitiesPromise.push(
          updateEntity({
            docNumber,
            articleId: existingService.modelCode,
            oldPogo: pogo,
            pogoId: pogo,
            quantity: existingService.quantity,
            type: 'services',
          })
        );
      }
    }
    existingService && acc.push(existingService.modelCode);
    return acc;
  }, []);
  return Promise.all(updateEntitiesPromise).then(() => enforcedServiceIds);
};

export const checkEntitySearch = (entity: Entity, searchedEntity: string) =>
  !searchedEntity ||
  (entity.name && normalizedString(entity.name).includes(normalizedString(searchedEntity))) ||
  entity.modelCode.toString().includes(searchedEntity) ||
  entity.articles?.some(article => article.articleId.toString().includes(searchedEntity));

export interface checkForArticleParams {
  userInputSearch: string;
  handleArticleCode: (code: number) => Promise<void>;
  errorMessageServiceInSparePart: string;
  errorMessageUnavailable: string;
}

export const checkForArticles = async ({
  userInputSearch,
  handleArticleCode,
  errorMessageServiceInSparePart,
  errorMessageUnavailable,
}: checkForArticleParams): Promise<Entity | null> => {
  if (!userInputSearch) {
    return null;
  }

  const productCode = await getArticleIdFromCode(userInputSearch);
  if (!productCode) {
    return null;
  }

  const fullArticles = await getArticles([productCode]);
  if (fullArticles.length === 0) {
    await handleArticleCode(productCode);
    return null;
  }

  const isServiceCode = await isServiceNature(productCode);
  if (isServiceCode) {
    throw new Error(errorMessageServiceInSparePart);
  }

  const availableArticles = await checkAvailabilityForArticles(
    fullArticles.map(article => article.articleId),
    availableArticleLifeStages
  );
  const articles = fullArticles.filter(article =>
    availableArticles.some(availableArticle => availableArticle.articleId === article.articleId && availableArticle.available)
  );

  if (articles.length > 1) {
    try {
      const [modelInformations] = await getModelsInformation([productCode]);
      return {
        modelCode: modelInformations.modelCode,
        name: modelInformations.label,
        imageUrl: modelInformations.imageUrl,
        quantity: 0,
        articles: articles
          .map(article => ({ ...article, priceType: '', value: 0 }))
          .sort(({ labelGrid: labelGridA = '' }, { labelGrid: labelGridB = '' }) =>
            labelGridA.localeCompare(labelGridB, undefined, { numeric: true, sensitivity: 'base' })
          ),
      };
    } catch (error) {
      throw new Error(error);
    }
  }
  if (articles.length === 1) {
    await handleArticleCode(articles[0].articleId);
    return null;
  }

  throw new Error(errorMessageUnavailable);
};

export const getNumberOfSparePartsToDisplay = (isMobile: boolean) => {
  const sparePartsOuterWidth = 450;
  const sparePartWidth = 280;
  return isMobile ? 4 : Math.trunc((window.innerWidth - sparePartsOuterWidth) / sparePartWidth);
};

export const getServicesFromIntervention = ({ services, stateId, interventionType }: WorkshopIntervention): Promise<Entity[]> => {
  const servicesModelCodes = services.map(service => service.modelCode);
  return getModelsInformation(servicesModelCodes).then(modelsInformation => {
    if (isInterventionDisabled(stateId)) {
      return services.map(service => {
        const serviceModel = modelsInformation.find(model => model.modelCode === service.modelCode);
        return {
          id: v4(),
          name: serviceModel?.label ?? service.label,
          modelCode: service.modelCode,
          quantity: service.quantity,
          imageUrl: serviceModel?.imageUrl ?? '',
          articles: [
            {
              articleId: service.itemCode,
              free: service.pogoId !== PAYABLE_POGO_ID,
              value: getPrice(service),
              priceType: translateFromPogo(service.pogoId),
              labelGrid: translateFromPogo(service.pogoId),
              estimatedTime: service.theoreticalTime,
              gridValueId: translateFromPogo(service.pogoId),
              selected: true,
            },
          ],
        };
      });
    }
    return formatServices(services, modelsInformation, interventionType);
  });
};

export const getSparePartsFromIntervention = (spareParts: SparePartWorkshop[], stateId: number): Promise<Entity[]> => {
  const articleIds = spareParts.map(sparePart => sparePart.itemCode);
  const getStocks = isInterventionDisabled(stateId) ? Promise.resolve([] as StockPictureResponse[]) : getStockForArticles(articleIds);

  return getArticlesDetails(articleIds).then(articles => {
    const formattedArticles = articles.map(article => ({ itemId: Number(article.itemId), modelId: Number(article.modelId) }));
    const modelsInformationPromise = getModelsInformation(formattedArticles.map(model => model.modelId)).then(models =>
      models.map(model => ({
        ...model,
        itemCode: formattedArticles.find(formattedArticle => formattedArticle.modelId === model.modelCode)?.itemId,
      }))
    );
    const getAvailability = checkAvailabilityForArticles(
      articles.map(article => Number(article.itemId)),
      availableArticleLifeStages
    );

    return Promise.all([getStocks, modelsInformationPromise, getAvailability]).then(([stocks, modelsInformation, availabilities]) =>
      spareParts.map(sparePart => {
        const modelCode = formattedArticles.find(model => model.itemId === sparePart.itemCode)?.modelId;
        const articleStock = stocks.find(stock => stock.item === sparePart.itemCode);
        const sparePartModel = modelsInformation.find(model => model.modelCode === modelCode);
        const available = availabilities.find(availability => availability.articleId === sparePart.itemCode)?.available;

        return {
          id: v4(),
          modelCode,
          name: sparePartModel?.label,
          quantity: sparePart.quantity,
          imageUrl: sparePartModel?.imageUrl,
          orderInProgress: sparePart.orderInProgress,
          orders: sparePart.orders,
          articles: [
            {
              available,
              stock: articleStock ? articleStock.stock : 0,
              stockMovementQuantity: sparePart.stockMovementQuantity,
              articleId: sparePart.itemCode,
              gridId: undefined,
              gridValueId: translateFromPogo(sparePart.pogoId),
              selected: true,
              eanNum: undefined,
              value: getPrice(sparePart),
              priceType: sparePart.pogo.toUpperCase(),
              free: sparePart.pogoId !== PAYABLE_POGO_ID,
            },
          ],
        };
      })
    );
  });
};

export const getSparePartsNatures = async (
  spareParts: Entity[],
  naturesResult: ILinkSparePartsAndServicesNature,
  docNumber: string,
  isDisabled: boolean
) => {
  const natures: InterventionNature[] = [];
  await Promise.all(
    spareParts.map(async (sparePart: Entity) => {
      const sparePartWithNature = naturesResult.spareParts.find(sparePartNature => sparePartNature.id === sparePart.modelCode);

      if (!sparePartWithNature) {
        if (isDisabled) {
          const existingNature = natures.find(nature => nature.natureId === NATURE_ID_WITHOUT_SPARE_PART);
          if (existingNature) {
            existingNature.spareParts.push(sparePart);
          } else {
            natures.push({
              natureId: NATURE_ID_WITHOUT_SPARE_PART,
              natureTitle: '',
              spareParts: [sparePart],
              services: [],
            });
          }
        } else {
          const { gridValueId, articleId } = sparePart.articles.find(findSelectedArticle) ?? {};
          const sparePartArgs = { sparePartCode: sparePart.modelCode.toString(), sparePartLabel: sparePart.name };
          await removeEntity(docNumber, articleId, translatePogoToPogoId(gridValueId), 'spare_parts');
          externalToastStore.addToast(translate('toaster.unactive.spare.part', sparePartArgs), colors.red);
        }
      } else {
        const existingNature = natures.find(nature => sparePartWithNature.natureIds.includes(nature.natureId));
        if (existingNature) {
          existingNature.spareParts.push(sparePart);
        } else {
          natures.push({
            natureId: sparePartWithNature.natureIds[0],
            natureTitle: '',
            spareParts: [sparePart],
            services: [],
          });
        }
      }
    })
  );
  return natures;
};

export const getServicesNatures = async (
  services: Entity[],
  naturesResult: ILinkSparePartsAndServicesNature,
  docNumber: string,
  naturesWithSpareParts: InterventionNature[],
  isDisabled: boolean
) => {
  const natures: InterventionNature[] = [...naturesWithSpareParts];
  await Promise.all(
    services.map(async (service: Entity) => {
      const serviceWithNatures = naturesResult.services.find(serviceNature => serviceNature.id === service.modelCode);
      if (!serviceWithNatures) {
        if (isDisabled) {
          const existingNature = natures.find(nature => nature.natureId === NATURE_ID_WITHOUT_SPARE_PART);
          if (existingNature) {
            existingNature.services.push(service);
          } else {
            natures.push({
              natureId: NATURE_ID_WITHOUT_SPARE_PART,
              natureTitle: '',
              spareParts: [],
              services: [service],
            });
          }
        } else {
          const gridValueId = service.articles.find(findSelectedArticle)?.gridValueId;
          const serviceArgs = { serviceCode: service.modelCode.toString(), serviceLabel: service.name };
          await removeEntity(docNumber, service.modelCode, translatePogoToPogoId(gridValueId), 'services');
          externalToastStore.addToast(translate('toaster.unactive.service', serviceArgs), colors.red);
        }
      } else {
        const existingNature = natures.find(nature => serviceWithNatures.natureIds.includes(nature.natureId));
        if (existingNature) {
          existingNature.services.push(service);
        } else {
          natures.push({
            natureId: serviceWithNatures.natureIds[0],
            natureTitle: '',
            spareParts: [],
            services: [service],
          });
        }
      }
    })
  );
  return natures;
};

interface EntityToUpdate {
  id: number;
  oldPogo: number;
  quantity: number;
}

const getServicesAddOrUpdate = (pecEntities: Entity[], newEntities: Entity[]) =>
  newEntities.reduce(
    (acc: [EntityToUpdate[], Entity[]], newService) => {
      const oldService = pecEntities.find(
        old =>
          old.modelCode === newService.modelCode &&
          old.articles.find(findSelectedArticle)?.gridValueId === newService.articles.find(findSelectedArticle)?.gridValueId
      );
      if (oldService) {
        const oldArticle = oldService.articles.find(findSelectedArticle);
        if (oldArticle?.gridValueId) {
          acc[0].push({
            id: oldService.modelCode,
            oldPogo: translatePogoToPogoId(oldArticle.gridValueId),
            quantity: newService.quantity + oldService.quantity,
          });
        }
      } else {
        acc[1].push({ ...newService });
      }
      return acc;
    },
    [[], []]
  );

const getSparePartsAddOrUpdate = (pecSpareParts: Entity[], newSpareParts: Entity[]) =>
  newSpareParts.reduce(
    (acc: [EntityToUpdate[], Entity[]], newSparePart: Entity) => {
      const oldSparePart = pecSpareParts.find(
        old =>
          old.articles.find(findSelectedArticle)?.articleId === newSparePart.articles.find(findSelectedArticle)?.articleId &&
          old.articles.find(findSelectedArticle)?.gridValueId === newSparePart.articles.find(findSelectedArticle)?.gridValueId
      );
      if (oldSparePart) {
        const oldArticle = oldSparePart.articles.find(findSelectedArticle);
        if (oldArticle && oldArticle.articleId && oldArticle.gridValueId) {
          acc[0].push({
            id: oldArticle.articleId,
            oldPogo: translatePogoToPogoId(oldArticle.gridValueId),
            quantity: newSparePart.quantity + oldSparePart.quantity,
          });
        }
      } else {
        acc[1].push({ ...newSparePart });
      }
      return acc;
    },
    [[], []]
  );

export interface SendServicesAndSparePartsToIntervention {
  docNumber: string;
  pecServices: Entity[];
  newServices: Entity[];
  pecSpareParts: Entity[];
  newSpareParts: Entity[];
  authorizedPogos: PogoDetail[];
}

export const sendServicesAndSparePartsToIntervention = async ({
  docNumber,
  pecServices,
  newServices,
  pecSpareParts,
  newSpareParts,
  authorizedPogos,
}: SendServicesAndSparePartsToIntervention) => {
  if (!newServices.length && !newSpareParts.length) {
    return Promise.resolve([]);
  }
  const [servicesToUpdate, servicesToAdd] = getServicesAddOrUpdate(pecServices, newServices);
  const [sparePartsToUpdate, sparePartsToAdd] = getSparePartsAddOrUpdate(pecSpareParts, newSpareParts);

  for (const service of servicesToUpdate) {
    await updateEntity({
      docNumber,
      articleId: service.id,
      oldPogo: service.oldPogo,
      pogoId: undefined,
      quantity: service.quantity,
      type: 'services',
    });
  }

  for (const sparePart of sparePartsToUpdate) {
    await updateEntity({
      docNumber,
      articleId: sparePart.id,
      oldPogo: sparePart.oldPogo,
      pogoId: undefined,
      quantity: sparePart.quantity,
      type: 'spare_parts',
    });
  }

  return addServicesAndSparePartsToIntervention({ docNumber, services: servicesToAdd, spareParts: sparePartsToAdd, authorizedPogos });
};
