import snakecaseKeys from 'snakecase-keys';
import { type CountryCode, parsePhoneNumber } from 'libphonenumber-js';

import { createLog } from '../logger';
import translate from '../../utils/i18n/SwappingIntlProvider';
import type HttpError from '../../utils/apis/HttpError';
import store from '../../store/store';
import { buildHeaders, buildRequest, buildUrl, fetchRequest } from '../../utils/apis/RestApi';
import { handleError, handleHttpErrors } from '../../business/helpers';
import { generateRandomDigits } from '../../utils/utils';
import { getCountryCode } from '../../business/customerHelper';
import { createLegalEntity, updateLegalEntity } from './memberLegalEntity';
import { type Identifier, type MemberCountry, type OTPBody, type OTPValidationResponse } from './member.types';
import {
  type Customer,
  type CustomerAddress,
  type CustomerIds,
  type CustomerInput,
  type CustomersLightDetails,
  Gender,
  PersonTypes,
  QuickSearchType,
} from '../customer/customer.types';
import { getEmailAlert, getSMSAlert } from '../interventions/interventions';
import { type CustomerCommunicationType } from '../startup/startup.type';
import { translateGenderFromEnum, translateGenderFromString } from './member.utils';

const logger = createLog('api/member');

// MEMBER SEARCH //

export const fetchFullCustomerById = (memberId: string | undefined): Promise<Customer> => {
  const selectedSite = store.getState().userInfo.selectedSite;
  const location = selectedSite.address.countryCode.toUpperCase();
  const body = {
    location,
    memberId,
  };
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const url = buildUrl('ICARE_BACK', 'member/v2/profile');
  return fetchRequest(buildRequest(url, 'POST', headers, JSON.stringify(body))).then(response => response.json());
};

export const searchFullCustomerByCriteria = (customerInput: CustomerInput): Promise<Customer[]> => {
  const location = store.getState().userInfo.selectedSite.address.countryCode.toUpperCase();
  let subjectValue;
  switch (customerInput.quickSearchType) {
    case QuickSearchType.phone:
      subjectValue = customerInput.phone;
      break;
    case QuickSearchType.cardNumber:
      subjectValue = customerInput.cardNumber;
      break;
    default:
      subjectValue = customerInput.email;
  }

  const body = {
    subjectValue,
    location,
  };
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const url = buildUrl('ICARE_BACK', `member/v2/search/${customerInput.quickSearchType}`);
  return fetchRequest(buildRequest(url, 'POST', headers, JSON.stringify(body)))
    .then(response => response.json())
    .then(customer => (customer ? [customer] : []))
    .catch((httpError: HttpError) => {
      if (httpError.response?.status === 403) {
        throw translate('error.message.member.access.forbidden');
      }
      if (httpError.response?.status === 404) {
        return [];
      }
      return handleHttpErrors(httpError, 'member', logger, { url });
    });
};

export const searchFullCustomerByName = (customerInput: CustomerInput): Promise<Customer[]> => {
  const location = store.getState().userInfo.selectedSite.address.countryCode.toUpperCase();
  const formattedInput = {
    givenName: customerInput.firstname,
    familyName: customerInput.lastname,
    address: {
      zipCode: customerInput.postalCode,
    },
  };
  const body = {
    location,
    inputs: snakecaseKeys(formattedInput),
  };
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const url = buildUrl('ICARE_BACK', 'member/v2/search');
  const request = buildRequest(url, 'POST', headers, JSON.stringify(body));
  return fetchRequest(request)
    .then(response => response.json())
    .catch((httpError: HttpError) => {
      if (httpError.response?.status === 404) {
        return [];
      }
      return handleHttpErrors(httpError, 'member', logger, { url });
    });
};

export const getCustomersLightDetails = (customers: CustomerIds[]): Promise<CustomersLightDetails[]> => {
  const location = store.getState().userInfo.selectedSite.address.countryCode.toUpperCase();
  const body = {
    location,
    customers,
  };
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const url = buildUrl('ICARE_BACK', 'member/v2/customers/light');
  const request = buildRequest(url, 'POST', headers, JSON.stringify(body));
  return fetchRequest(request)
    .then(response => response.json())
    .catch((httpError: HttpError) => {
      if (httpError?.response?.status === 404) {
        return [];
      }
      if (httpError.response?.status === 403) {
        throw translate('error.message.member.access.forbidden');
      }
      return handleHttpErrors(httpError, 'member', logger, { url });
    });
};

// MEMBER UPDATE //

const identityResultMapper = (customer: Customer | CustomerInput) => ({
  firstname: customer.firstname,
  lastname: customer.lastname,
  gender: customer.gender,
  email: customer.email,
  phone: customer.phone,
});

const updateIdentity = (customerInput: CustomerInput, actualCustomer: Customer, customerCountry: MemberCountry) => {
  if (
    customerInput.lastname !== actualCustomer.lastname ||
    customerInput.firstname !== actualCustomer.firstname ||
    customerInput.gender !== actualCustomer.gender ||
    customerInput.email !== actualCustomer.email
  ) {
    const identityBody = JSON.stringify({
      given_name: customerInput.firstname,
      family_name: customerInput.lastname,
      email: customerInput.email,
      gender: translateGenderFromEnum(customerInput.gender ?? Gender.man),
    });

    const headers = buildHeaders('ICARE_BACK', 'Bearer');
    const siteCountryCode = store.getState().userInfo.selectedSite.address.countryCode?.toUpperCase();

    const location = customerCountry.codeISO2?.toUpperCase() ?? customerInput.countryCode?.toUpperCase() ?? siteCountryCode;
    const url = buildUrl('ICARE_BACK', `member/identity/${actualCustomer.memberId}/${location}`);
    const request = buildRequest(url, 'PATCH', headers, identityBody);
    return fetchRequest(request)
      .then(response => response.json())
      .then(() => identityResultMapper(customerInput))
      .catch(httpError => handleHttpErrors(httpError, 'member', logger, { url }));
  }

  return Promise.resolve(identityResultMapper(actualCustomer));
};

const addressBodyMapper = (customerInput: CustomerInput, customerCountry: MemberCountry) =>
  JSON.stringify({
    street: customerInput.addressLine1,
    complement: customerInput.addressLine2,
    postal_code: customerInput.postalCode,
    locality: customerInput.city,
    country_code: customerCountry.codeISO3,
  });

// TODO: Customer and CustomerInput has to handle finally a full MemberCountryType, not only string countryCode !!
const updateBillingAddress = (
  customerInput: CustomerInput,
  actualCustomer: Customer,
  customerCountry: MemberCountry,
  countries: MemberCountry[]
): Promise<CustomerAddress> => {
  if (
    customerInput.addressLine1 !== actualCustomer.addressLine1 ||
    customerInput.addressLine2 !== actualCustomer.addressLine2 ||
    customerInput.city !== actualCustomer.city ||
    customerInput.postalCode !== actualCustomer.postalCode ||
    customerCountry.codeISO2 !== actualCustomer.countryCode
  ) {
    const addressBody = addressBodyMapper(customerInput, customerCountry);
    const headers = buildHeaders('ICARE_BACK', 'Bearer');
    const url = buildUrl('ICARE_BACK', `member/address/${actualCustomer.memberId}`);
    const request = buildRequest(url, 'PUT', headers, addressBody);
    return fetchRequest(request)
      .then(response => response.json())
      .then(() => ({
        addressLine1: customerInput.addressLine1,
        addressLine2: customerInput.addressLine2,
        postalCode: customerInput.postalCode,
        city: customerInput.city,
        countryCode: customerCountry.codeISO2,
      }))
      .catch(httpError => handleHttpErrors(httpError, 'member', logger, { url }));
  }

  const memberCountry = countries.find(country => country.codeISO2 === actualCustomer.countryCode);
  return Promise.resolve({
    addressLine1: actualCustomer.addressLine1,
    addressLine2: actualCustomer.addressLine2,
    postalCode: actualCustomer.postalCode,
    city: actualCustomer.city,
    country: memberCountry,
  });
};

export const updateMemberCustomer = (
  customerInput: CustomerInput,
  actualCustomer: Customer,
  customerCountry: MemberCountry,
  countries: MemberCountry[]
): Promise<Customer> => {
  const franceCondition = customerCountry.codeISO2 === 'FR';
  const addressRequest = franceCondition ? updateBillingAddress(customerInput, actualCustomer, customerCountry, countries) : Promise.resolve();
  const promises = [updateIdentity(customerInput, actualCustomer, customerCountry), addressRequest];
  if (customerInput.companyName) {
    promises.push(updateLegalEntity(customerInput, actualCustomer.memberId));
  }

  return Promise.all(promises).then(([customerIdentity, customerAddress]) => ({ ...actualCustomer, ...customerIdentity, ...customerAddress }));
};

// MEMBER CREATION //

const createIdentity = (
  customerInput: CustomerInput,
  customerCountry: MemberCountry,
  customerCommunication: CustomerCommunicationType
): Promise<Customer> => {
  const phoneNumber = customerInput.phone ? parsePhoneNumber(customerInput.phone, getCountryCode(customerCountry)).number : undefined;
  const state = store.getState();
  const language =
    state.i18n.availableLanguages.find(availableLanguage => availableLanguage.country === customerCountry.codeISO2) || state.i18n.appLanguage;
  const { id: storeId, address } = state.userInfo.selectedSite;
  const identityBody = JSON.stringify({
    location: customerCountry.codeISO2?.toUpperCase() ?? address.countryCode?.toUpperCase(),
    claims: {
      given_name: customerInput.firstname,
      family_name: customerInput.lastname || `${translate('customer.form.default.lastname')}${generateRandomDigits()}`,
      email: customerInput.email,
      gender: translateGenderFromEnum(customerInput.gender ?? Gender.man),
      locale: `${language?.language.toLowerCase()}-${language?.country.toUpperCase()}`,
      phone_number: phoneNumber,
    },
    consent: !!customerInput.optIn,
  });

  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const url = buildUrl('ICARE_BACK', `member/v2/identity/${storeId}`);
  const request = buildRequest(url, 'POST', headers, identityBody);
  return fetchRequest(request)
    .then(response => response.json())
    .then(({ sub: memberId, claims: createdCustomer }) => ({
      memberId,
      firstname: createdCustomer.given_name,
      lastname: createdCustomer.family_name,
      phone: createdCustomer.phone_number,
      email: createdCustomer.email,
      gender: translateGenderFromString(createdCustomer.gender),
      language: {
        language: createdCustomer.locale.split('-')[0],
        country: createdCustomer.locale.split('-')[1],
      },
      smsAlert: getSMSAlert(createdCustomer.phone_number, customerCommunication),
      emailAlert: getEmailAlert(createdCustomer.email, customerCommunication),
    }))
    .catch(httpError => {
      if (httpError.response.status === 400) {
        throw translate('error.message.wrong.format');
      }
      if (httpError.response.status === 409) {
        throw translate('error.account.already.exists');
      }
      return handleHttpErrors(httpError, 'member', logger, { url, store: storeId });
    });
};

const createAddress = (customerInput: CustomerInput, memberId: string, customerCountry: MemberCountry): Promise<CustomerAddress> => {
  const addressBody = addressBodyMapper(customerInput, customerCountry);
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const url = buildUrl('ICARE_BACK', `member/address/${memberId}`);
  const request = buildRequest(url, 'POST', headers, addressBody);
  return fetchRequest(request)
    .then(response => response.json())
    .then(() => ({
      addressLine1: customerInput.addressLine1,
      addressLine2: customerInput.addressLine2,
      postalCode: customerInput.postalCode,
      city: customerInput.city,
      countryCode: customerCountry.codeISO2,
    }))
    .catch(httpError => handleHttpErrors(httpError, 'member', logger, { url }));
};

export const createMemberCustomer = async (
  customerInput: CustomerInput,
  customerCountry: MemberCountry,
  customerCommunication: CustomerCommunicationType
): Promise<Customer> => {
  const createdCustomer = await createIdentity(customerInput, customerCountry, customerCommunication);
  let createdAddress;

  const isFrenchCustomer = customerCountry.codeISO2 === 'FR';
  if (isFrenchCustomer) {
    createdAddress = await createAddress(customerInput, createdCustomer.memberId, customerCountry);
  } else {
    createdAddress = { countryCode: customerCountry.codeISO2 };
  }

  if (customerInput.companyName) {
    await createLegalEntity(customerInput, createdCustomer.memberId);
  }

  return {
    ...createdCustomer,
    ...createdAddress,
    personType: PersonTypes.customer,
  };
};

// MEMBER IDENTIFIERS //

export const sendIdentifierValidationCode = (
  identifierType: Identifier,
  identifierValue: string,
  memberId: string,
  otpCode: string,
  countryCode: CountryCode
): Promise<OTPValidationResponse> => {
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const url = buildUrl('ICARE_BACK', `member/v2/identifiers/${identifierType}`);

  const body: OTPBody = {
    memberId,
    identifierValue,
    otpCode,
    location: countryCode,
  };

  const request = buildRequest(url, 'PUT', headers, JSON.stringify(body));
  return fetchRequest(request)
    .then(response => response.json())
    .catch((error: HttpError) => {
      if ([429, 400, 500].some(errorCode => error.response?.status === errorCode)) {
        return {
          id: identifierType,
          value: '',
          errorStatus: error.response?.status,
        };
      }
      return handleError('Member Identifiers - PUT validation OTP validation code');
    });
};
