import { type ServiceApiName } from '../config/configuration.type';
import store, { type RootState, useAppSelector } from '../../store/store';
import { type HttpMethod, type TokenType, throwHttpErrors } from './restApiUtils';
import { colors, externalToastStore } from '../toaster/ToastProvider';
import translate from '../i18n/SwappingIntlProvider';

export const buildUrl = (serviceName: ServiceApiName, resourcePath: string) => {
  const config = store.getState().configuration.config;
  return `${config.services[serviceName].url}/${resourcePath}`;
};

export const buildWorkshopEuUrl = (serviceName: ServiceApiName, resourcePath: string) =>
  `${store.getState().configuration.config.services[serviceName].url.replace('/v3', '/eu/v3')}/${resourcePath}`;

export const fetchRequest = (request: Request): Promise<Response> =>
  fetch(request)
    .then(throwHttpErrors)
    .catch(error => {
      if (error?.response?.status === 401) {
        externalToastStore.addToast(translate('error.message.session.expired'), colors.red);
      }

      throw error;
    });

const generateIcareHeader = ({
  serviceName,
  tokenType,
  fullLocale,
  state,
}: {
  serviceName: ServiceApiName;
  tokenType: TokenType;
  fullLocale?: boolean;
  state: RootState;
}) => {
  const headers = new Headers();
  headers.append('Authorization', `${tokenType} ${state.configuration.token}`);
  headers.append('x-api-key', state.configuration.config.services[serviceName].api_key);
  const appLanguage = state.i18n.appLanguage;
  appLanguage &&
    headers.append(
      'accept-language',
      fullLocale ? `${appLanguage.language.toLowerCase()}-${appLanguage.country.toUpperCase()}` : appLanguage.language
    );
  return headers;
};

export const buildHeaders = (serviceName: ServiceApiName, tokenType: TokenType, fullLocale?: boolean): Headers => {
  const state = store.getState();
  return generateIcareHeader({ serviceName, tokenType, fullLocale, state });
};

export const useBuildHeader = ({
  serviceName,
  tokenType,
  fullLocale,
}: {
  serviceName: ServiceApiName;
  tokenType: TokenType;
  fullLocale?: boolean;
}): Record<string, string> => {
  const state = useAppSelector(fullState => fullState);
  const headers = Object.fromEntries(generateIcareHeader({ serviceName, tokenType, fullLocale, state }));
  // token generation is managed in redux middleware
  delete headers.authorization;
  return headers;
};

export const buildRequest = (url: string, method: HttpMethod, headers: Headers, body?: string | FormData, fileUpload?: boolean): Request => {
  if (['PATCH', 'POST', 'PUT'].some(m => m === method)) {
    !fileUpload && headers.append('Content-Type', 'application/json');
  }

  const requestInit: RequestInit = {
    method,
    headers,
    body,
  };
  return new Request(url, requestInit);
};

export const fetchForRedux = async (requestInfo: RequestInfo | URL, requestInit: RequestInit | undefined) => {
  const response = await fetchRequest(new Request(requestInfo, requestInit));
  const contentType = response.headers.get('content-type');
  if (contentType?.includes('application/json')) {
    return response.json();
  }
  return response.text();
};
