import { selectIsRegionalWorkshopSelector } from '../../store/selectors/userInfo';
import { useAppSelector } from '../../store/store';
import { type InterventionState } from '../../apis/interventions/intervention.type';
import { InterventionStatus } from '../../business/interventionButtonMenu';

const buildFilter = (id: InterventionStatus, workshopInterventionStates: InterventionState[]) => ({
  id,
  label: workshopInterventionStates.find(state => state.stateId === id)?.stateLabel || id.toString(),
  selected: true,
});

export const usePlanningStateFilter = () => {
  const isRW = useAppSelector(selectIsRegionalWorkshopSelector);
  const workshopInterventionStates = useAppSelector(state => state.workshopIntervention.workshopInterventionStates);
  const statesLabels = isRW
    ? [
        [InterventionStatus.toProcess, InterventionStatus.receivedForProcessingInRW],
        [InterventionStatus.storeShortage, InterventionStatus.RWShortage],
        [InterventionStatus.forReceptionInRW],
      ]
    : [[InterventionStatus.toProcess], [InterventionStatus.storeShortage]];

  return statesLabels.map(id => ({
    ...buildFilter(id[0], workshopInterventionStates),
    subFilters: id.map(newId => ({
      ...buildFilter(newId, workshopInterventionStates),
    })),
  }));
};
