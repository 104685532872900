import { type RootState } from '../store';

const formatStoreIdForWBO = (storeIdToFormat: number | undefined) => {
  const paddedStoreId = storeIdToFormat?.toString().padStart(5, '0');
  return `007${paddedStoreId}${paddedStoreId}`;
};

export const selectUserInfo = (state: RootState) => state.userInfo;
export const selectIsRegionalWorkshopSelector = (state: RootState) => state.userInfo.selectedSite.isRegionalWorkshop;
export const selectStoreIdFull = (state: RootState) => formatStoreIdForWBO(state.userInfo.selectedSite.id);
export const selectCountryId = (state: RootState) => state.userInfo.countryId;
export const selectStoreId = (state: RootState) => state.userInfo.selectedSite.id;
export const selectSelectedSite = (state: RootState) => state.userInfo.selectedSite;
export const selectRegionalWorkshopId = (state: RootState) => state.userInfo.regionalWorkshopId;
export const selectCustomerCommunication = (state: RootState) => state.userInfo.customerCommunication;
export const selectTraceabilityActivation = (state: RootState) => Boolean(state.userInfo.traceabilityActivation);
export const selectSparePartsOrder = (state: RootState) => state.userInfo.sparePartsOrder;
export const selectUserDisplayName = (state: RootState) => state.userInfo.displayName;
export const selectSiteCountryCode = (state: RootState) => state.userInfo.selectedSite.address.countryCode;
export const selectHasSADNewProduct = (state: RootState) => state.userInfo.hasSADNewProduct;
export const selectIsPurchaseActive = (state: RootState) => state.userInfo.isPurchaseActive;
export const selectNewCustomerPurchasesPage = (state: RootState) => Boolean(state.userInfo.newCustomerPurchasesPage);
export const selectIsBookingsEnabled = (state: RootState) => state.userInfo.isBookingsEnabled;
