import React from 'react';

import TWFlag from '../imgs/icons/flag-TW.png';

import '../imgs/flags/flags.scss';

interface FlagProps {
  countryCode?: string;
}

export default ({ countryCode }: FlagProps) => {
  if (countryCode === 'TW') {
    return <img src={TWFlag} alt="" className="tw-flag" />;
  }

  return (
    <div className="sprite-container">
      <span className={`sprite sprite-${countryCode}`} data-testid="country-code" />
    </div>
  );
};
