import { type DependencyList, useCallback } from 'react';
import { type ReduxAjaxParams, RequestMethod, useReduxAjax } from '@chipp972/redux-ajax';

import { type ServiceApiName } from '../config/configuration.type';
import { type TokenType } from './restApiUtils';
import { buildUrl, useBuildHeader } from './RestApi';

interface UseAppFetchProps {
  requestId: string;
  url: string;
  method?: RequestMethod;
  serviceName?: ServiceApiName;
  tokenType?: TokenType;
  fullLocale?: boolean;
}

export const useAppFetch = <T>(
  { requestId, url, method = RequestMethod.get, serviceName = 'ICARE_BACK', tokenType = 'Bearer', fullLocale = false }: UseAppFetchProps,
  deps: DependencyList
) => {
  const headers = useBuildHeader({ serviceName, tokenType, fullLocale });
  const request = useReduxAjax<T | null>(requestId);
  const result = request.response;

  const fetchResult = useCallback(
    (body?: object) => {
      const endpoint = buildUrl(serviceName, url);
      const requestContent: ReduxAjaxParams = {
        url: endpoint,
        headers: {
          ...headers,
          'content-type': 'application/json',
        },
        method,
        body,
      };

      return request.submitRequest({ requestContent, transformData: JSON.stringify });
    },
    [...deps, url, headers]
  );

  return {
    result,
    fetchResult,
    isRequestPending: request.isRequestPending,
    isRequestSuccessful: request.isRequestSuccessful,
    isRequestAborted: request.isRequestAborted,
    isRequestFailed: request.isRequestFailed,
    hasError: request.hasError,
    error: request.error,
    resetResponse: request.resetResponse,
    resetRequest: request.resetRequest,
    Loading: request.Loading,
    Failure: request.Failure,
    Success: request.Success,
  };
};
