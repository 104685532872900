import React from 'react';
import { NavLink, type Path } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DelayIcon } from '@vtmn/icons/dist/vitamix/svg/settings-line.svg';
import clsx from 'clsx';
import { Case } from '@chipp972/react-case-when';

import useDataStudio from '../../utils/hooks/useDataStudio';
import { useProductContext } from '../ProductPage/ProductProvider';
import { useReset } from '../../store/hooks';
import { useAppSelector } from '../../store/store';
import { useCountryContext } from '../../utils/countryProvider/CountryProvider';
import { selectIsProd } from '../../store/selectors/configuration';
import { selectIsBookingsEnabled, selectSiteCountryCode, selectSparePartsOrder } from '../../store/selectors/userInfo';
import { selectHasRWRelatedStores } from '../../store/store-list';
import { getCountryCode } from '../../business/customerHelper';
import { getAppRoute, RoutesPath } from '../../core/routes';
import LogoIcare from '../../imgs/LOGO_ICARE.png';
import { DEFAULT_ACTIVE_TAB } from '../../pages/SparePartOrders/constants';
import { pushMenuEventGTM } from '../../utils/GTM/googleTagManager';

import './toolbar-menu.scss';

export interface NavigationLink {
  label: 'home' | 'interventions' | 'parameters' | 'parcels.flow' | 'planning' | 'useful.links' | 'order' | 'bookings';
  route: string | Partial<Path>;
  icon: string;
  exactPath?: boolean;
  enabled?: boolean;
  dataTestId: string;
}

export const ToolbarMenu = () => {
  const { t } = useTranslation();
  const { emptyIntervention } = useReset();
  const { emptyNatureCategories } = useProductContext();
  const dataStudioLink = useDataStudio();
  const { countries, setCustomerCountry } = useCountryContext();
  const { isProd, siteCountryCode, hasRWRelatedStores, sparePartsOrder, isBookingsEnabled } = useAppSelector(state => ({
    isProd: selectIsProd(state),
    siteCountryCode: selectSiteCountryCode(state),
    hasRWRelatedStores: selectHasRWRelatedStores(state),
    sparePartsOrder: selectSparePartsOrder(state),
    isBookingsEnabled: selectIsBookingsEnabled(state),
  }));

  const navLinks: NavigationLink[] = [
    {
      label: 'home',
      route: getAppRoute(RoutesPath.homePage),
      icon: 'vtmn-icon_home',
      exactPath: true,
      enabled: true,
      dataTestId: 'button-home',
    },
    {
      label: 'bookings',
      route: getAppRoute(RoutesPath.bookingsPage),
      icon: 'vtmn-icon_decision',
      enabled: isBookingsEnabled,
      dataTestId: 'button-bookings',
    },
    {
      label: 'planning',
      route: getAppRoute(RoutesPath.planningPage),
      icon: 'vtmn-icon_agenda',
      exactPath: false,
      enabled: true,
      dataTestId: 'button-planning',
    },
    {
      label: 'interventions',
      route: getAppRoute(RoutesPath.searchPage),
      icon: 'vtmn-icon_repair',
      exactPath: false,
      enabled: true,
      dataTestId: 'button-interventions',
    },
    {
      label: 'parcels.flow',
      route: getAppRoute(RoutesPath.parcelFlowPage),
      icon: 'vtmn-icon_pickup_relay',
      exactPath: false,
      enabled: true,
      dataTestId: 'button-parcels',
    },
    {
      label: 'order',
      route: { pathname: getAppRoute(RoutesPath.sparePartOrders), search: `?activeTab=${DEFAULT_ACTIVE_TAB}` },
      icon: 'vtmn-icon_cart',
      exactPath: true,
      enabled: sparePartsOrder,
      dataTestId: 'button-order',
    },
  ];

  const handleNavigationClick = (eventLabel: string) => {
    pushMenuEventGTM(eventLabel);
    const currentCountry = countries.find(country => getCountryCode(country)?.toUpperCase() === siteCountryCode.toUpperCase());
    emptyIntervention();
    emptyNatureCategories();
    currentCountry && setCustomerCountry(currentCountry);
  };

  return (
    <div className="toolbar-menu-container">
      <div>
        {isProd ? null : <div className="development">Preprod</div>}
        <div className="logo-icare">
          <img src={LogoIcare} alt="icare" />
        </div>
        {navLinks
          .filter(link => link.enabled)
          .map((link, index) => (
            <NavLink
              className={navData => clsx('link-menu', { selected: navData.isActive })}
              to={link.route}
              key={index}
              onClick={() => handleNavigationClick(link.label)}
              data-testid={link.dataTestId}
            >
              <div className="nav-link-logo">
                <i className={link.icon} />
              </div>
              <span>{t(`toolbar.menu.${link.label}`)}</span>
            </NavLink>
          ))}
        <a
          className="link-menu"
          href={dataStudioLink}
          target="_blank"
          rel="noreferrer"
          data-testid="button-report"
          onClick={() => pushMenuEventGTM('report')}
        >
          <div className="nav-link-logo">
            <i className="vtmn-icon_eye_on" />
          </div>
          <span>{t('toolbar.menu.dashboard.report')}</span>
        </a>
        <a
          className="link-menu"
          href="https://sites.google.com/decathlon.com/workshop-it-informations/liens-utiles-zone-douani%C3%A8re"
          target="_blank"
          rel="noreferrer"
          data-testid="button-useful-links"
          onClick={() => pushMenuEventGTM('external_links')}
        >
          <div className="nav-link-logo">
            <i className="vtmn-icon_external_link" />
          </div>
          <span>{t('toolbar.menu.useful.links')}</span>
        </a>
        <Case when={hasRWRelatedStores}>
          <NavLink
            className={navData => clsx('link-menu', 'fixed-nav-link', { selected: navData.isActive })}
            to="/delays-parameters"
            onClick={() => pushMenuEventGTM('delays_parameters')}
          >
            <div className="nav-link-logo">
              <DelayIcon />
            </div>
            <span>{t('toolbar.menu.parameters')}</span>
          </NavLink>
        </Case>
      </div>
    </div>
  );
};

export default ToolbarMenu;
