import { useContext } from 'react';

import { colors, ToastProviderContext } from '../toaster/ToastProvider';

export interface ToastProps {
  id: string;
  color?: string;
  content: string | Error;
  duration: number;
  reload?: boolean;
}

const { green, orange, red, lightBlueDKT } = colors;

const useToaster = () => {
  const { addToast, toasts, removeToast } = useContext(ToastProviderContext);

  return { toasts, addToast, green, orange, red, lightBlueDKT, removeToast };
};

export default useToaster;
