import React, { useState, createContext, type ReactNode } from 'react';
import { type Subtract } from 'utility-types';

export interface AutofocusProviderProps {
  children: ReactNode;
}

const { Provider, Consumer } = createContext({});

export const AutofocusProvider = ({ children }: AutofocusProviderProps) => {
  const [autofocusEnabled, setAutofocusEnabled] = useState(true);
  const contextValue: AutofocusProps = { autofocusEnabled, setAutofocusEnabled };

  return <Provider value={contextValue}>{children}</Provider>;
};

export interface AutofocusProps {
  autofocusEnabled: boolean;
  setAutofocusEnabled: (autofocusEnabled: boolean) => void;
}

const withAutoFocus =
  <P extends AutofocusProps>(Component: React.ComponentType<P>) =>
  (props: Subtract<P, AutofocusProps>) =>
    <Consumer>{autofocusContext => <Component {...autofocusContext} {...(props as P)} />}</Consumer>;

export default withAutoFocus;
