import { type Entity } from '../apis/ilink.type';
import { INTERNAL_POGO_ID, type POGO, Pogos } from '../apis/interventions/intervention.const';
import { type InterventionPogoItem, type PogoDetail } from '../apis/interventions/intervention.type';
import { type RootState } from '../store/store';

export const getPogoToUse = (authorizedPogos: PogoDetail[], useLinked = true): number | null =>
  authorizedPogos.reduce((acc: number | null, { pogoId, linkedPogoId }) => {
    // eslint-disable-next-line no-nested-ternary
    const linked = linkedPogoId ? (useLinked ? linkedPogoId : pogoId) : null;
    // eslint-disable-next-line no-param-reassign
    acc = linked;
    return acc;
  }, null);

export const selectAuthorizedPogos = (storeState: RootState) => storeState.intervention.contextType?.authorizedPogos ?? [];

export const selectIsPogoInternalPaying = (storeState: RootState) =>
  selectAuthorizedPogos(storeState).some(pogoDetail => pogoDetail.pogoId === INTERNAL_POGO_ID);

export const findCorrespondingPogo = (entity: Entity, pogo: InterventionPogoItem) =>
  entity.articles.find(article => article.modelCode === pogo.modelCode);

export const isInternalPogo = (pogo: POGO | string | undefined) => pogo === Pogos.INTERNAL;
