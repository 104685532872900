import store from '../store/store';
import { selectCustomer } from '../store/customerSlice';
import { type Customer, type Language } from '../apis/customer/customer.types';
import { CustomerAlertType, type WorkshopIntervention } from '../apis/interventions/intervention.type';
import { fetchFullCustomerById } from '../apis/member/member';

export const getCustomerByIds = (interventionData: WorkshopIntervention, languages: Language[]) =>
  fetchFullCustomerById(interventionData.memberId).then(customer => {
    const constructedCustomer: Customer = {
      ...customer,
      smsAlert: interventionData.customerAlertType === CustomerAlertType.BOTH || interventionData.customerAlertType === CustomerAlertType.SMS,
      emailAlert: interventionData.customerAlertType === CustomerAlertType.BOTH || interventionData.customerAlertType === CustomerAlertType.EMAIL,
      language: languages.find(language => language.language.toUpperCase() === interventionData.customerLanguage?.toUpperCase()) ?? null,
    };
    store.dispatch(selectCustomer(constructedCustomer));
  });
