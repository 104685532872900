import { buildHeaders, buildRequest, buildUrl, fetchRequest } from '../utils/apis/RestApi';
import { handleError } from '../business/helpers';
import { type Locale } from '../store/i18nSlice';

export const getTranslations = (appLanguage: Locale) => {
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const url = buildUrl('ICARE_BACK', `phrase/translations/${appLanguage.language.toLowerCase()}-${appLanguage.country.toUpperCase()}`);
  const request = buildRequest(url, 'GET', headers);

  return fetchRequest(request)
    .then(response => response.json())
    .catch(() => handleError('Phrase Request - GET translations'));
};
