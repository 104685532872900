import { type CountryCode, type E164Number } from 'libphonenumber-js';

import { type CompanyType } from '../customer/customer.types';

export interface MemberCountryRaw {
  countryIso3: string;
  countryIso2: string;
  countryName: string;
}

export interface MemberCountry {
  codeISO2: CountryCode | undefined;
  codeISO3: string;
  name: string;
  phoneAreaCode: string;
}

export enum LegalEntityType {
  club_sport = 'club_sport',
  association = 'association',
  other = 'other',
  school = 'school',
  administration = 'administration',
  business = 'business',
}

export interface RawCompany {
  legal_entity_id: string;
  display_name: string;
  location: string;
  type: LegalEntityType;
}

export interface Company {
  companyName: string;
  companyEmail?: string;
  companyMobile?: string;
  companyCountry?: MemberCountry;
  companyType: CompanyType;
}

export enum MemberGender {
  male = 'male',
  female = 'female',
}

export type Identifier = 'phone' | 'email';

export enum Identifiers {
  PHONE = 'phone',
  EMAIL = 'email',
}

interface ClaimsBody {
  given_name: string | undefined;
  family_name: string;
  email: string | undefined;
  gender: MemberGender;
  locale: string;
  phone_number: E164Number | string | undefined;
}

export interface CustomerCreationBody {
  location: string;
  claims: ClaimsBody;
  consent: boolean;
}

export interface IdentifierRequestBody {
  location: CountryCode;
  value: string;
}

export interface OTPBody {
  memberId: string;
  identifierValue: string;
  otpCode: string;
  location: string;
}

export interface OTPValidationResponse {
  id: Identifier;
  value: string;
  errorStatus?: number;
}

export interface MemberSearchRequestBody {
  storeId: number;
  subjectValue: string;
  location: CountryCode;
}
