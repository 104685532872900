import React, { memo, useContext } from 'react';
import clsx from 'clsx';

import { cleanBeforeReload } from '../../utils/redirectToFed';
import { LoaderContext } from '../../utils/loader/LoaderProvider';
import { useAppSelector } from '../../store/store';
import { useI18n } from '../../store/i18n.selectors';
import { selectAllowedStores } from '../../store/store-list';
import DropDown from '../DropDown/DropDown';

import './stores-dropdown.scss';

interface StoresDropdownProps {
  hasHeader: boolean;
}

export const StoresDropdown = ({ hasHeader }: StoresDropdownProps) => {
  const { selectedSite, allowedStores } = useAppSelector(state => ({
    selectedSite: state.userInfo.selectedSite,
    allowedStores: selectAllowedStores(state),
  }));
  const [placeholder] = useI18n(['toolbar.top.userinfos.placeholder']);
  const { showLoader } = useContext(LoaderContext);

  const setChosenStore = (storeName: string) => {
    const store = allowedStores.find(site => site.id === Number(storeName.split('-')[0].trim()) || site.name === storeName);
    if (store && store.id !== selectedSite.id) {
      showLoader();
      cleanBeforeReload(null, store.id);
    }
  };

  return (
    <DropDown
      className={clsx('toolbar-stores', { 'transparent-header': hasHeader })}
      selectedEntity={selectedSite.name}
      setNewEntity={setChosenStore}
      entities={allowedStores.map(site => `${site.id} - ${site.name}`)}
      enableEnter
      disabled={allowedStores.length === 1}
      placeholder={placeholder}
    />
  );
};

export default memo(StoresDropdown);
