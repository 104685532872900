import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
  differenceInWeeks,
  endOfDay,
  startOfDay,
  differenceInDays,
  addDays,
  subDays,
  isToday,
  getWeek,
  addMonths,
  subMonths,
  isSameMonth,
  isSameDay,
  formatISO,
  isWithinInterval,
  isAfter,
  isBefore,
  addYears,
  subYears,
  type Locale,
  differenceInMilliseconds,
  addHours,
  addMinutes,
  setMinutes,
  setHours,
  differenceInHours,
  differenceInMinutes,
  isValid,
  parse,
  eachDayOfInterval,
} from 'date-fns';
import { fr, enGB } from 'date-fns/locale';

type DateFormat = Date | string | number;

const weekStartOnSunday = ['ar-SA', 'ko-KR', 'zh-TW'];

const navigatorLocale = /^fr\b/.test(navigator.language) ? fr : enGB;

const getWeekStartOn = (locale?: string) => (locale && weekStartOnSunday.includes(locale) ? 0 : 1);

export const initDate = (dateString: string) => {
  const date = new Date(dateString);

  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

  return date;
};

export const getCurrentDate = () => new Date();

type DateTimeOptions = Pick<Intl.DateTimeFormatOptions, 'day' | 'month' | 'year'>;

export const dateToLocaleString = (date: Date, { day = 'numeric', month = 'long', year = 'numeric' }: DateTimeOptions = {}) =>
  date.toLocaleDateString('fr-FR', {
    day,
    month,
    year,
  });

export const getStartOfMonth = (date: DateFormat) => startOfMonth(date);

export const getEndOfMonth = (date: DateFormat) => endOfMonth(date);

export const getStartOfWeek = (date: DateFormat, locale?: string) => startOfWeek(date, { weekStartsOn: getWeekStartOn(locale) });

export const getEndOfWeek = (date: DateFormat, locale?: string) => endOfWeek(date, { weekStartsOn: getWeekStartOn(locale) });

export const getStartOfDay = (date: DateFormat) => startOfDay(date);

export const getEndOfDay = (date: DateFormat) => endOfDay(date);

interface DateCompare {
  firstDate: DateFormat;
  secondDate: DateFormat;
}

interface DateRangeCompare {
  actualDate: Date;
  start: Date;
  end: Date;
}

export const getWeeksDifference = ({ firstDate, secondDate }: DateCompare) => differenceInWeeks(secondDate, firstDate);

export const getDaysDifference = ({ firstDate, secondDate }: DateCompare) => differenceInDays(secondDate, firstDate);

export const getHoursDifference = ({ firstDate, secondDate }: DateCompare) => differenceInHours(secondDate, firstDate);

export const getIsSameMonth = ({ firstDate, secondDate }: DateCompare) => isSameMonth(firstDate, secondDate);

export const getIsSameDay = ({ firstDate, secondDate }: DateCompare) => isSameDay(firstDate, secondDate);

export const getIsBetween = ({ actualDate, start, end }: DateRangeCompare) => isWithinInterval(actualDate, { start, end });

export const getIsBefore = ({ firstDate, secondDate }: DateCompare) => isBefore(firstDate, secondDate);

export const getIsAfter = ({ firstDate, secondDate }: DateCompare) => isAfter(firstDate, secondDate);

export const getDifferenceInMilliseconds = ({ firstDate, secondDate }: DateCompare) => differenceInMilliseconds(firstDate, secondDate);

export const getDifferenceInMinutes = ({ firstDate, secondDate }: DateCompare) => differenceInMinutes(firstDate, secondDate);

interface DateQuantity {
  date: DateFormat;
  quantity: number;
}

export const addDaysTo = ({ date, quantity }: DateQuantity) => addDays(date, quantity);

export const addHoursTo = ({ date, quantity }: DateQuantity) => addHours(date, quantity);

export const addMinutesTo = ({ date, quantity }: DateQuantity) => addMinutes(date, quantity);

export const subDaysTo = ({ date, quantity }: DateQuantity) => subDays(date, quantity);

export const addMonthsTo = ({ date, quantity }: DateQuantity) => addMonths(date, quantity);

export const subMonthsTo = ({ date, quantity }: DateQuantity) => subMonths(date, quantity);

export const setHoursTo = ({ date, quantity }: DateQuantity) => setHours(date, quantity);

export const setMinutesTo = ({ date, quantity }: DateQuantity) => setMinutes(date, quantity);

export const addYearsTo = ({ date, quantity }: DateQuantity) => addYears(date, quantity);

export const subYearsTo = ({ date, quantity }: DateQuantity) => subYears(date, quantity);

export const getIsToday = (date: DateFormat) => isToday(date);

export const getWeekNumber = (date: DateFormat, locale?: string) => getWeek(date, { weekStartsOn: getWeekStartOn(locale) });

interface FormatDateParams {
  date: DateFormat;
  strFormat: string;
  locale?: Locale;
}

export const formatDate = ({ date, strFormat, locale }: FormatDateParams) => format(date, strFormat, { locale: locale ?? navigatorLocale });

/**
 * @description
 *
 * Format date into `dd/MM/yyyy` or `MM/dd/yyyy` depending on the locale.
 *
 * @param date - Date to format.
 *
 * @returns Formatted date.
 *
 * @example
 *
 * formatDateWithLocale(new Date()); // -> '06/08/2024' or '08/06/2024'
 */
export const formatDateWithLocale = (date: DateFormat) => formatDate({ date, strFormat: 'P' });

export const formatISOWithTimeZone = (date: DateFormat) => formatISO(date);

interface WeekDayName extends Pick<Intl.DateTimeFormatOptions, 'weekday'> {
  localeName: string;
}

export const daysForLocale = ({ localeName = 'en-GB', weekday = 'short' }: WeekDayName) => {
  const start = getStartOfWeek(new Date(), localeName);
  const end = getEndOfWeek(new Date(), localeName);

  const intlDate = new Intl.DateTimeFormat(localeName, { weekday });

  const daysOfWeek = eachDayOfInterval({ start, end }).map(day => intlDate.format(day));

  return daysOfWeek;
};

/**
 * @example getDateLocalStringMedium(new Date(2024, 8, 6), 'fr'); //6 août 2024
 */
export const getDateLocalStringMedium = (date: Date, locale?: string) => date.toLocaleString(locale, { dateStyle: 'medium' });

export const isValidDate = (date: DateFormat) => isValid(date);

/**
 * @description Parse a date string into a Date object.
 *
 * @param dateString - The date string to parse.
 * @param formatString - The format string to parse the date string.
 *
 * @returns The parsed date.
 *
 * @example
 *
 * parseDate('06/08/2024', 'dd/MM/yyyy'); // -> 2024-08-06T00:00:00.000Z
 */
export const parseDate = (dateString: string, formatString: string) => parse(dateString, formatString, new Date());
