import HttpError from './HttpError';

export type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';

export type TokenType = 'Bearer' | 'EMPLOYEE';

export const throwHttpErrors = (response: Response) => {
  const statusLimit = 310;
  if (response.status >= 200 && response.status < statusLimit) {
    return response;
  }
  const error = new HttpError(response.statusText);
  error.response = response;
  throw error;
};
