import printjs from 'print-js';

export const printBlob = (blob: Blob) => {
  const url = URL.createObjectURL(blob);
  printjs(url);
  setTimeout(() => URL.revokeObjectURL(url), 100);
};

export const base64ToArrayBuffer = (base64: string) => {
  const binaryString = window.atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const generateBlob = (fileContent: string) => new Blob([base64ToArrayBuffer(fileContent)], { type: 'application/pdf' });

export const printBase64 = (fileContent: string) => printBlob(generateBlob(fileContent));
