import { buildHeaders, buildRequest, buildUrl, fetchRequest } from '../utils/apis/RestApi';
import { handleError } from '../business/helpers';
import { getStoreId } from './interventions/interventions';
import { type StockPictureResponse } from './stockPicture.type';

export const getStockForArticles = (articleIds: number[], storeId?: number | null): Promise<StockPictureResponse[]> => {
  const chunkArticleIds: Array<number[]> = articleIds.reduce((acc: number[][], current, index) => {
    const chunkIndex = Math.floor(index / 20);
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }
    acc[chunkIndex].push(current);
    return acc;
  }, []);

  const requests = chunkArticleIds.map(chunkArticles => {
    const url = buildUrl('STOCK_PICTURE', `stores/${storeId || getStoreId()}/items/stocks/sale/pictures?item=${chunkArticles.join('&item=')}`);
    const headers = buildHeaders('STOCK_PICTURE', 'Bearer', true);

    return fetchRequest(buildRequest(url, 'GET', headers))
      .then(response => response.json())
      .catch(() => []);
  });

  return Promise.all(requests)
    .then((responses: StockPictureResponse[]) => responses.flatMap(stockPicture => stockPicture))
    .catch(() => {
      handleError('Stock Repo Request - GET stocks items');
      return [];
    });
};
