import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type ILinkProduct, type LevelType } from '../apis/ilink.type';

export interface BreadcrumbLevel {
  level: LevelType;
  label: string | undefined;
  id?: number;
  hide?: boolean;
}

export interface CatalogSearch {
  breadcrumb: BreadcrumbLevel[];
  product: ILinkProduct;
  defaultImageUrl?: string;
  fetchNeeded: boolean;
  translatedMessage?: string;
  initialBreadCrumbLevel?: BreadcrumbLevel;
}

export interface CatalogState {
  search: CatalogSearch;
}

export const initialProductCatalog: ILinkProduct = { articles: [], id: -1, label: '' };
export const initialCustomProductCatalog: ILinkProduct = { ...initialProductCatalog, isCustom: true };

export const initialState: CatalogState = {
  search: {
    breadcrumb: [],
    product: { ...initialProductCatalog },
    defaultImageUrl: undefined,
    fetchNeeded: true,
  },
};

const mapProduct = (product: ILinkProduct): ILinkProduct => ({
  ...product,
  id: product.id ?? -1,
});

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  extraReducers: builder => builder.addCase('resetStore', () => initialState),
  reducers: {
    updateCatalogSearch: (state, action: PayloadAction<CatalogSearch>) => {
      state.search = {
        ...action.payload,
        product: mapProduct(action.payload.product),
      };
    },
    resetCatalogSearch: state => {
      state.search = {
        ...state.search,
        breadcrumb: [{ ...state.search.breadcrumb[0], hide: false }],
        fetchNeeded: true,
      };
    },
    updateProduct: (state, action: PayloadAction<ILinkProduct>) => {
      state.search = {
        ...state.search,
        product: mapProduct(action.payload),
      };
    },
    updateBreadcrumb: (state, action: PayloadAction<BreadcrumbLevel[]>) => {
      state.search = {
        ...state.search,
        breadcrumb: action.payload,
      };
    },
  },
});

export const {
  reducer: catalogReducer,
  actions: { resetCatalogSearch, updateCatalogSearch, updateProduct, updateBreadcrumb },
} = catalogSlice;

export default catalogSlice.reducer;
