import translate, { type I18nKey } from '../utils/i18n/SwappingIntlProvider';
import store from '../store/store';
import type HttpError from '../utils/apis/HttpError';
import { type Logger } from '../apis/logger';
import { externalToastStore, colors } from '../utils/toaster/ToastProvider';
import { type Entity, LevelType } from '../apis/ilink.type';
import { type LocationState } from '../components/ProductPage/ProductPage';
import { getProductFromCatalog } from '../apis/masterdata/masterdata';
import { type BreadcrumbLevel, updateCatalogSearch } from '../store/catalogSlice';
import { initInterventionState, resetIntervention } from '../store/interventionSlice';
import { deleteCustomer } from '../store/customerSlice';
import { checkShippingGroup } from './shippingHelper';

export const normalizedString = (param: string) =>
  param
    .toUpperCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

export const capitalizeString = (word: string) => (word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : '');

export const capitalizeEachWord = (sentence: string | undefined) => {
  if (sentence) {
    const words = sentence.split(' ');
    return words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }
  return '';
};

export const handleError = (errorDetails?: string) => {
  const getErrorDetails = () => `${errorDetails ? ` ${errorDetails}` : ''}`;
  throw `${translate('error.generic.message')}${getErrorDetails()}`;
};

export type ErrorType = 'intervention' | 'customer' | 'member' | 'dictionary';

export const handleHttpErrors = (
  httpError: HttpError,
  errorType: ErrorType,
  logger: Logger,
  optionalParams: { [key: string]: any }
): Promise<any> => {
  if (httpError?.response && ![504, 403].includes(httpError.response.status) && httpError.response.json) {
    return httpError.response
      .json()
      .catch(error => {
        logger.log(error, optionalParams);
        throw translate('error.generic.message');
      })
      .then((error: any) => {
        const errorToLog = new Error();
        errorToLog.stack = httpError.stack;
        if (errorType === 'intervention') {
          errorToLog.message = error.error
            ? `${error.status} ${error.error} : ${error.message}`
            : error.message || translate('error.generic.message');
          logger.log(errorToLog, {
            ...optionalParams,
            uri: optionalParams.url,
            correlationId: httpError.response.headers.get('x-correlation-id'),
            document_number: optionalParams.docNumber,
          });
          throw errorToLog.message;
        } else if (error?.error && errorType) {
          const errorCode = `error.message.${errorType}.${error.error.code}` as I18nKey;
          const message = translate(errorCode);
          throw message !== errorCode ? message : error.error.message;
        }
        logger.log(httpError, optionalParams);
        return handleError();
      });
  }
  logger.log(httpError, optionalParams);
  return new Promise(() => {
    handleError();
  });
};

export const handle404Errors = (httpError: HttpError, logger: Logger, url: string, defaultValue: any) => {
  if (httpError?.response?.status === 404) {
    return Promise.resolve(defaultValue);
  }
  return handleHttpErrors(httpError, 'intervention', logger, { url });
};

export const willUpdateEntity = (prevEntity: Entity, prevEntities: Entity[], nextEntity: Entity, nextEntities: Entity[]) =>
  prevEntity.quantity === nextEntity.quantity &&
  prevEntity.articles.length === nextEntity.articles.length &&
  prevEntity.articles.every((article, index) => article.selected === nextEntity.articles[index].selected) &&
  prevEntity.articles.every((article, index) => article.stock === nextEntity.articles[index].stock) &&
  prevEntities?.length === nextEntities?.length &&
  (!prevEntities || prevEntities.every((entity, index) => entity.quantity === nextEntities[index].quantity)) &&
  (!prevEntities || prevEntities.every((entity, index) => entity.articles && entity.articles.length === nextEntities[index].articles.length)) &&
  (!prevEntities ||
    prevEntities.every((entity, index) =>
      entity.articles?.every((article, articlesIndex) => article.selected === nextEntities[index].articles[articlesIndex].selected)
    ));

export const getUserLocale = () => {
  const appLanguage = store.getState().i18n.appLanguage;

  return `${appLanguage?.language.toLowerCase()}_${appLanguage?.country.toUpperCase()}`;
};

export const homePageSearchAndGetRedirectPath = (
  searchedText: string,
  productSearchActive: boolean
): Promise<{ pathname: string; state?: LocationState } | null> => {
  if (searchedText) {
    const isShippingGroup = checkShippingGroup(searchedText);

    if (!isShippingGroup && isNaN(parseInt(searchedText, 10))) {
      externalToastStore.addToast(translate('error.message.invalid.searched.text'), colors.red);
      return Promise.resolve(null);
    }

    return (!isShippingGroup && productSearchActive ? getProductFromCatalog(searchedText).catch(() => null) : Promise.resolve(null)).then(
      catalogProduct => {
        const state = store.getState();
        if (catalogProduct?.productInformation) {
          const { hasSpareParts, product, productInformation } = catalogProduct;
          const catalogSearch = state.catalog.search;
          const updatedBreadcrumb: BreadcrumbLevel[] = [
            { ...catalogSearch.breadcrumb[0], hide: true },
            { level: LevelType.categories, label: productInformation.process.name, id: productInformation.process.id, hide: true },
            { level: LevelType.products, label: productInformation.category.name, id: productInformation.category.id, hide: true },
            { level: LevelType.product, label: translate('catalog.breadcrumb.search.product') },
          ];

          store.dispatch(
            updateCatalogSearch({
              ...catalogSearch,
              product,
              breadcrumb: updatedBreadcrumb,
              fetchNeeded: true,
            })
          );
          store.dispatch(initInterventionState());
          return { pathname: '/product/intervention', state: { hasSpareParts } };
        }

        if (isShippingGroup) {
          return { pathname: `/shipping-group/${searchedText}` };
        }
        store.dispatch(resetIntervention(state.intervention.footer.docNumber === searchedText));
        store.dispatch(deleteCustomer());
        return { pathname: `/intervention/${searchedText}` };
      }
    );
  }

  return Promise.resolve(null);
};
