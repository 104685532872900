import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Search from '@vtmn/icons/dist/vitamix/svg/search-line.svg';
import { ReactComponent as ArrowLeft } from '@vtmn/icons/dist/vitamix/svg/arrow-left-line.svg';

import { headerStyleCondition } from '../../utils/revamp/whiteHeader';
import useClickOutside from '../../utils/hooks/useClickOutside';
import useStringState from '../../utils/hooks/useStringState';
import { useReset } from '../../store/hooks';
import { useAppSelector } from '../../store/store';
import { selectSparePartsOrder, selectTraceabilityActivation } from '../../store/selectors/userInfo';
import { homePageSearchAndGetRedirectPath } from '../../business/helpers';
import { LoaderContext } from '../../utils/loader/LoaderProvider';
import { LazyReact } from '../LazyReact/LazyReact';
import { getAppRoute, RoutesPath } from '../../core/routes';
import { ReactComponent as BarcodeReader } from '../../imgs/icons/white_barcode.svg';
import { DEFAULT_ACTIVE_TAB } from '../../pages/SparePartOrders/constants';
import { pushMenuEventGTM } from '../../utils/GTM/googleTagManager';

import './toolbar-top-mobile.scss';

const SingleScanner = React.lazy(() => import('../Scanner/SingleScanner'));

export const ToolbarTopMobile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { traceabilityActivation, sparePartsOrder } = useAppSelector(state => ({
    traceabilityActivation: selectTraceabilityActivation(state),
    sparePartsOrder: selectSparePartsOrder(state),
  }));
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displaySearchBar, setDisplaySearchBar] = useState(false);
  const [scannerActive, setScannerActive] = useState(false);
  const [userInputSearch, setUserInputSearch] = useStringState('');
  const [isHomePage, setIsHomePage] = useState(location.pathname === '/');
  const [isRevampHeader, setIsRevampHeader] = useState(headerStyleCondition(location, !traceabilityActivation));

  const inputRef = useRef<HTMLInputElement>(null);
  const searchRef = useClickOutside<HTMLDivElement>(setDisplaySearchBar);
  const { emptyIntervention } = useReset();
  const { showLoader, hideLoader } = useContext(LoaderContext);

  useEffect(() => setIsHomePage(location.pathname === '/'), [location]);

  useEffect(() => {
    setIsRevampHeader(headerStyleCondition(location, !traceabilityActivation));
  }, [location]);

  const handleNavigationClick = (eventLabel: string) => {
    pushMenuEventGTM(eventLabel);
    emptyIntervention();
    setDisplayMenu(false);
  };

  const toggleSearchBar = (display: boolean) => {
    setDisplaySearchBar(display);
    display ? inputRef.current?.focus() : inputRef.current?.blur();
  };

  const handleSearch = (input = userInputSearch) => {
    setScannerActive(false);
    if (input) {
      showLoader();
      homePageSearchAndGetRedirectPath(input, isHomePage)
        .then(path => {
          if (path) {
            setUserInputSearch('');
            toggleSearchBar(false);
            navigate(path.pathname, { state: path.state });
          }
        })
        .finally(hideLoader);
    }
  };

  const placeholder = isHomePage ? t('home.page.searchbar.placeholder') : t('toolbar.top.searchbar.placeholder');

  return (
    <>
      {displayMenu && <div className="overlay" onClick={() => setDisplayMenu(false)} data-testid="overlay" />}
      <div className={clsx('deployed-menu', { hidden: !displayMenu })}>
        <div className="deployed-menu-close" onClick={() => setDisplayMenu(false)} data-testid="close-deployed-menu">
          <span>{t('toolbar.top.mobile.menu.close')}</span>
          <i className="vtmn-icon_tiny_bold_cross" />
        </div>
        <div className="deployed-menu-navigation">
          <NavLink
            className={navData => clsx('link-menu', { selected: navData.isActive })}
            to={getAppRoute(RoutesPath.homePage)}
            onClick={() => handleNavigationClick('home')}
          >
            <div className="nav-link-logo">
              <i className="vtmn-icon_home" />
            </div>
            <span className="menu-navigation-label">{t('toolbar.top.mobile.menu.navigation.home')}</span>
          </NavLink>
          <NavLink
            className={navData => clsx('link-menu', { selected: navData.isActive })}
            to={getAppRoute(RoutesPath.parcelFlowPage)}
            onClick={() => handleNavigationClick('parcels.flow')}
          >
            <div className="nav-link-logo">
              <i className="vtmn-icon_pickup_relay" />
            </div>
            <span className="menu-navigation-label">{t('toolbar.top.mobile.menu.navigation.parcel.flows')}</span>
          </NavLink>
          {sparePartsOrder && (
            <NavLink
              className={navData => clsx('link-menu', { selected: navData.isActive })}
              to={{ pathname: getAppRoute(RoutesPath.sparePartOrders), search: `?activeTab=${DEFAULT_ACTIVE_TAB}` }}
              onClick={() => handleNavigationClick('order')}
            >
              <div className="nav-link-logo">
                <i className="vtmn-icon_cart" />
              </div>
              <span className="menu-navigation-label">{t('toolbar.menu.order')}</span>
            </NavLink>
          )}
          <NavLink
            className={navData => clsx('link-menu', { selected: navData.isActive })}
            to={getAppRoute(RoutesPath.massiveInvoicePage)}
            onClick={() => handleNavigationClick('massive_invoice')}
          >
            <div className="nav-link-logo">
              <i className="vtmn-icon_document" />
            </div>
            <span className="menu-navigation-label">{t('toolbar.top.mobile.menu.navigation.massive.invoice')}</span>
          </NavLink>
        </div>
      </div>

      <div className={clsx('toolbar-top-mobile', { home: isHomePage, white: isRevampHeader })} ref={searchRef}>
        <i
          className={clsx('vtmn-icon_menu burger-menu', { black: traceabilityActivation })}
          onClick={() => setDisplayMenu(true)}
          data-testid="burger-menu"
        />
        <div className="toolbar-top-mobile-title">ICARE</div>
        <img onClick={() => toggleSearchBar(true)} src={Search} alt="search" />
        <div className={clsx('search-bar', { display: displaySearchBar, white: traceabilityActivation })}>
          <ArrowLeft id="arrow-left" onClick={() => toggleSearchBar(false)} data-testid="search-bar-arrow" />
          <label className="hidden-label" htmlFor="search-bar-mobile">
            {placeholder}
          </label>
          <input
            type="text"
            ref={inputRef}
            id="search-bar-mobile"
            onKeyPress={event => event.key === 'Enter' && handleSearch()}
            placeholder={placeholder}
            onChange={event => setUserInputSearch(event.target.value.replace(/\s/g, ''))}
            value={userInputSearch}
          />
          <BarcodeReader id="barcode" onClick={() => setScannerActive(true)} data-testid="barcode-reader" />
        </div>
        {scannerActive && (
          <LazyReact>
            <SingleScanner closeScan={() => setScannerActive(false)} action={handleSearch} />
          </LazyReact>
        )}
      </div>
    </>
  );
};

export default ToolbarTopMobile;
