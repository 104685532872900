import React, { useState, useRef, type KeyboardEvent, useEffect, type ChangeEvent, type MutableRefObject, useMemo, type SyntheticEvent } from 'react';
import clsx from 'clsx';
import InfiniteScroll from 'react-infinite-scroller';
import { Case, Switch } from '@chipp972/react-case-when';

import useClickOutside from '../../utils/hooks/useClickOutside';
import { FormattedMessage, type I18nKey } from '../../utils/i18n/SwappingIntlProvider';
import { handleEnterOrSpace } from '../../business/eventHelper';
import Loader from '../Loader/Loader';

import './dropdown.scss';

const numberOfPixelToScroll = 25;

export interface DropDownProps {
  entities?: string[];
  selectedEntity?: string;
  setNewEntity: (label: string) => void;
  disabled?: boolean;
  placeholder?: string;
  label?: I18nKey;
  disableSearch?: boolean;
  setSearchValue?: (searchedValue: string) => void;
  isLoading?: boolean;
  refDropDown?: MutableRefObject<HTMLDivElement | null>;
  translationKey?: string;
  className?: string;
  whiteLabel?: boolean;
  enableEnter?: boolean;
  dataTestid?: string;
  id?: string;
}

export default ({
  entities = [],
  setNewEntity,
  selectedEntity,
  disabled,
  placeholder,
  label,
  disableSearch,
  setSearchValue,
  isLoading,
  refDropDown,
  translationKey,
  className,
  whiteLabel,
  enableEnter,
  dataTestid,
  id,
}: DropDownProps) => {
  const [page, setPage] = useState(0);
  const [filteredEntities, setFilteredEntities] = useState<string[]>([]);
  const [deployed, setDeployed] = useState(false);
  const [searchLabel, setSearchLabel] = useState('');
  const refInput = useRef<HTMLInputElement | null>(null);
  const refContainer = useClickOutside<HTMLInputElement>(setDeployed);
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    !!setSearchValue && setSearchValue(value);
    setSearchLabel(value);
  };

  const handleEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      enableEnter && setNewEntity(searchLabel);
    }
  };

  const selectNewEntity = (entity: string) => (event: SyntheticEvent) => {
    setDeployed(false);
    setNewEntity(entity);
    setSearchLabel('');
    refInput.current?.blur();
    event.stopPropagation();
  };

  const toggleDeploy = () => {
    if (refContainer.current === document.activeElement || refContainer.current?.contains(document.activeElement)) {
      setDeployed(!deployed);
    }
  };

  useEffect(() => {
    !isLoading && setFilteredEntities(entities);
  }, [isLoading]);

  useEffect(() => {
    deployed ? refInput.current?.focus() : refInput.current?.blur();
  }, [deployed]);

  useEffect(() => {
    setPage(1);
    setFilteredEntities(entities.filter(el => el.toString().toUpperCase().includes(searchLabel.toUpperCase())));
  }, [searchLabel, entities]);

  const displayedEntities = useMemo(() => [...filteredEntities.slice(0, 15 * (page + 1))], [page, filteredEntities]);

  const containerClassName = clsx('dropdown-global-container', className, {
    disabled,
    deployed,
    'search-disabled': disableSearch,
    white: whiteLabel,
  });

  const arrowClass = `vtmn-icon_tiny_bold_arrow_${deployed ? 'up' : 'down'}`;
  const entityClassName = clsx('dropdown-entity', { opacity: deployed, hide: deployed && (!!placeholder || !!searchLabel) });

  return (
    <div className={containerClassName} ref={refDropDown} data-testid="deploy">
      {label && <FormattedMessage className="dropdown-label" id={label} />}

      <div ref={refContainer} id={id}>
        <div
          className="dropdown-header"
          onKeyDown={event => !disabled && handleEnterOrSpace(toggleDeploy)(event)}
          tabIndex={disabled ? -1 : 0}
          role="listbox"
          onClick={toggleDeploy}
          aria-label={`${translationKey ?? ''}${selectedEntity}`}
        >
          <div className="dropdown-input">
            <input
              onKeyUp={handleEnter}
              onKeyDown={event => event.stopPropagation()}
              ref={refInput}
              type="text"
              value={searchLabel}
              placeholder={placeholder}
              onChange={onChange}
              data-testid={dataTestid}
            />
            <div className={entityClassName}>
              {translationKey ? <FormattedMessage id={`${translationKey}${selectedEntity}` as I18nKey} /> : selectedEntity}
            </div>
          </div>
          <Case when={!disabled}>
            <Switch>
              <Case when={Boolean(isLoading)}>
                <div className="loader-dropdown">
                  <Loader color="white" width="small" />
                </div>
              </Case>
              <Case>
                <i className={arrowClass} />
              </Case>
            </Switch>
          </Case>
        </div>
        <div className={clsx('dropdown-list', { open: deployed })} role="list">
          <InfiniteScroll
            threshold={numberOfPixelToScroll}
            loadMore={() => setPage(page + 1)}
            hasMore={displayedEntities.length < filteredEntities.length}
            useWindow={false}
          >
            {displayedEntities.map(entity => (
              <div className="element-container" key={entity}>
                <div
                  className="element"
                  onClick={selectNewEntity(entity)}
                  tabIndex={0}
                  role="menuitem"
                  onKeyDown={handleEnterOrSpace(selectNewEntity(entity))}
                >
                  {translationKey ? <FormattedMessage id={`${translationKey}${entity}` as I18nKey} /> : entity}
                </div>
                <div className="border-bottom-animated" />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};
