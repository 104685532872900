import { type Location } from 'react-router-dom';

import { getAppRoute, RoutesPath } from '../../core/routes';

export const headerStyleCondition = (location: Location, isLegacyDesign: boolean = false) => {
  const routes = [];
  if (isLegacyDesign) {
    routes.push(getAppRoute(RoutesPath.homePage));
    routes.push(getAppRoute(RoutesPath.catalogPage));
    routes.push(getAppRoute(RoutesPath.catalogInterventionPage));
  }
  return routes.some(route => location.pathname === route);
};
