import { getStoreId } from '../interventions/interventions';
import { createLog } from '../logger';
import { buildHeaders, buildRequest, buildUrl, fetchRequest } from '../../utils/apis/RestApi';
import { handleHttpErrors } from '../../business/helpers';
import { type Company } from './member.types';
import { type CustomerInput } from '../customer/customer.types';
import { type HttpMethod } from '../../utils/apis/restApiUtils';

const logger = createLog('api/member');

type AddOrUpdateLegalEntityProps = {
  customerInput: CustomerInput;
  memberId: string;
  method: HttpMethod;
};

const legalEntityBodyMapper = (customerInput: CustomerInput) =>
  JSON.stringify(
    {
      legal_entity_type: customerInput.companyType,
      location: customerInput.countryCode,
      display_name: customerInput.companyName,
    },
    (_, value: string) => (value === '' ? undefined : value)
  );
const addOrUpdateLegalEntity = ({ customerInput, memberId, method }: AddOrUpdateLegalEntityProps): Promise<Company> => {
  const legalEntityBody = legalEntityBodyMapper(customerInput);
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const url = buildUrl('ICARE_BACK', `member/legal_entities/${memberId}`);
  const request = buildRequest(url, method, headers, legalEntityBody);
  return fetchRequest(request)
    .then(response => response.json())
    .catch(httpError => handleHttpErrors(httpError, 'customer', logger, { url, store: getStoreId() }));
};

export const createLegalEntity = (customerInput: CustomerInput, memberId: string): Promise<Company> =>
  addOrUpdateLegalEntity({ customerInput, memberId, method: 'POST' });

export const updateLegalEntity = (customerInput: CustomerInput, memberId: string): Promise<Company> =>
  addOrUpdateLegalEntity({ customerInput, memberId, method: 'PUT' });
