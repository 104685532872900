import { type SyntheticEvent, type KeyboardEvent } from 'react';

export const handleEnterOrSpace = (callback: (event: SyntheticEvent) => void) => (event: KeyboardEvent<HTMLElement>) => {
  if (['Enter', ' '].includes(event.key)) {
    callback(event);

    event.preventDefault();
    event.stopPropagation();
  }
};

export const handleEnter = (callback: (event: KeyboardEvent<HTMLInputElement>) => void) => (event: KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter') {
    callback(event);

    event.preventDefault();
    event.stopPropagation();
  }
};
