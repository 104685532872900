import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export const detectIsMobileWidth = () => window.innerWidth <= 900;

export interface SliceAppState {
  title: string;
  isMobile: boolean;
  isNewLayout: boolean;
}

const initialState: SliceAppState = {
  title: '',
  isMobile: detectIsMobileWidth(),
  isNewLayout: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  extraReducers: builder => builder.addCase('resetStore', () => initialState),
  reducers: {
    updateTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
    setIsNewLayout: (state, action: PayloadAction<boolean>) => {
      state.isNewLayout = action.payload;
    },
  },
});

export const {
  reducer: appReducer,
  actions: { updateTitle, setIsMobile, setIsNewLayout },
} = appSlice;
