import { replaceKeyInString } from '../utils/i18n/replaceKeyInString';
import { type I18nKey, type TranslationsAdditionalParams } from '../utils/i18n/SwappingIntlProvider';
import { type RootState, useAppSelector } from './store';

export interface I18nKeyWithArgs {
  id: I18nKey;
  args?: TranslationsAdditionalParams;
}

/**
 * @deprecated Use `useTranslation` from `react-i18next` library instead.
 */
export const useI18n = (i18nKeys: Array<I18nKeyWithArgs | I18nKey>) =>
  useAppSelector(state =>
    i18nKeys.map(i18nKey => {
      if (typeof i18nKey === 'object') {
        const translation = state.i18n.messages[i18nKey.id] || i18nKey.id;
        if (!i18nKey.args) {
          return translation;
        }
        return replaceKeyInString(i18nKey.args, translation);
      }
      return state.i18n.messages[i18nKey] || i18nKey;
    })
  );

export const useAlli18n = () => useAppSelector(state => state.i18n.messages);

export const selectLocale = (state: RootState) =>
  `${state.i18n.appLanguage?.language.toLowerCase()}-${state.i18n.appLanguage?.country.toUpperCase()}`;

export const selectAvailableLanguages = (state: RootState) => state.i18n.availableLanguages;
