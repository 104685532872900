import { type Dispatch, type ReactNode } from 'react';
import { type AnyAction } from 'redux';

import { type Parcel } from '../../apis/interventions/parcel.type';
import { type I18nKey } from '../../utils/i18n/SwappingIntlProvider';
import { type TableHistoryData } from './TableHistory/historyHelper';
import { type ShipmentIntervention } from './TableShipment/TableShipment';

export interface ParcelUpdateInfo {
  docOrInvoiceNumber: string;
  received: boolean;
  issued: boolean;
  isFromScan?: boolean;
}

export interface ParcelsFlowState {
  parcels: Parcel[];
  interventions: ShipmentIntervention[];
  parcelsInfo: TableHistoryData[];
  historyInterventions: TableHistoryData[];
  searchNumbersInProgress: string[];
  tabType: ParcelsFlowTabType;
  historySearchLabel: string;
  receptionNextParams: string | null;
}

export interface ParcelsFlowProviderProps {
  parcelsFlowState: ParcelsFlowState;
  dispatchParcelsFlow: Dispatch<AnyAction>;
}

export interface ParcelsFlowProviderComponentProps {
  children: ReactNode;
}

export interface ParcelsFlowSearchProps {
  action: (input: string) => void;
  searchNumbersInProgress: string[];
  placeholderId: I18nKey;
  displayScanner?: () => void;
}

export const enum ParcelsFlowTabType {
  shipment = 'shipment',
  reception = 'reception',
  history = 'history',
}

export interface ParcelsFlowTabProps extends ParcelsFlowProviderProps {
  tabName: ParcelsFlowTabType;
}
