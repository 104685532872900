import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import type { SetupIntlParams } from './types';

export function setupIntl({ locale, translation }: SetupIntlParams) {
  return i18next.use(initReactI18next).init({
    lng: locale,
    fallbackLng: false,
    returnNull: false,
    interpolation: { escapeValue: false },
    resources: { [locale]: { translation } },
  });
}
