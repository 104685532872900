// @ts-strict-ignore
import { buildUrl, buildHeaders, buildRequest, fetchRequest } from '../utils/apis/RestApi';
import { handleError, getUserLocale } from '../business/helpers';
import { chunkArray } from './ilink';
import store from '../store/store';
import { type ModelInformation, type PixlResult } from './spid.type';

export const getModelsInformation = (modelCodes: number[], withCharacteristics?: boolean): Promise<ModelInformation[]> => {
  if (!modelCodes.length) {
    return Promise.resolve([]);
  }
  const splittedModelCodes = chunkArray(modelCodes, 200);

  return Promise.all(
    splittedModelCodes.map(modelCodesToGet => {
      const locale = getUserLocale();
      const url = buildUrl('ICARE_BACK', `spid/models_information/${locale}/${modelCodesToGet}?with_characteristics=${!!withCharacteristics}`);
      const headers = buildHeaders('ICARE_BACK', 'Bearer');
      const request = buildRequest(url, 'GET', headers);

      return fetchRequest(request)
        .then(response => response.json())
        .catch(() => handleError('Spid request: GET models information'));
    })
  ).then(modelsInformation => modelsInformation.flat());
};

export const getPixlInfos = (modelCodes: number[]): Promise<PixlResult[] | null> => {
  if (!modelCodes?.length) {
    return Promise.resolve([]);
  }
  const language = store.getState().i18n.appLanguage;
  const locale = `${language.language.toLowerCase()}_${language.country.toUpperCase()}`;

  const url = buildUrl('ICARE_BACK', `spid/pixl_details/${locale}/${modelCodes}`);
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => response.json())
    .then(pixlResults =>
      pixlResults.map((pixlResult: any) => ({
        imageUrl: pixlResult.imageUrl,
        modelCode: Number(pixlResult.modelCode),
      }))
    )
    .catch(() => null);
};
