import translate, { type I18nKey } from '../utils/i18n/SwappingIntlProvider';
import { CompanyType, type Customer, type CustomerIdentity, type CustomerInput, Gender, PersonTypes } from '../apis/customer/customer.types';
import { type MemberCountry } from '../apis/member/member.types';

export const getCountryCode = (country: MemberCountry) => country.codeISO2;

export const getCustomerName = (customer: CustomerIdentity) => {
  switch (customer.personType) {
    case PersonTypes.customer:
      return `${customer.lastname} ${customer.firstname || ''}`;
    case PersonTypes.company:
      return customer.companyName ?? `${customer.lastname} ${customer.firstname || ''}`;
    default:
      return `DECATHLON ${customer}`;
  }
};

export const isCardNumber = (value: string) => /209\d{10}/.test(value);
export const isEmail = (value: string | undefined) => /([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,})/.test(value ?? '');

export const getDecathlonCustomerName = (contextType: number | undefined) =>
  contextType ? `${translate('decathlon')} - ${translate(`intervention.business.type.${contextType}` as I18nKey)}` : '';

export const getCustomerInput = (customer: Partial<Customer>, customerInput: CustomerInput, customerCountry: MemberCountry): CustomerInput => ({
  city: '',
  gender: Gender.man,
  companyType: CompanyType.administration,
  countryCode: getCountryCode(customerCountry),
  ...customerInput,
  ...customer,
});
