import React, { useEffect, useState } from 'react';

import { Message } from '../shared/Message/Message';
import { useFetchGetAlertMessages } from '../../apis/useFetch/admin-message';

const VIEWED_MESSAGE_CACHE_KEY = 'alert_message_viewed';

const getCacheKey = (id: string) => `${VIEWED_MESSAGE_CACHE_KEY}-${id}`;

export const AlertMessage = () => {
  const [refresh, setRefresh] = useState(0);
  const getMessageRequest = useFetchGetAlertMessages();

  useEffect(() => {
    getMessageRequest.fetchResult();
  }, []);

  const displayedMessage = getMessageRequest.result?.messages.at(0);

  const canDisplayMessage = displayedMessage && !(localStorage.getItem(getCacheKey(displayedMessage.id)) ?? '');

  if (canDisplayMessage) {
    return (
      <Message
        title={displayedMessage.title}
        message={displayedMessage.message}
        onClose={() => {
          localStorage.setItem(getCacheKey(displayedMessage.id), 'true');
          setRefresh(refresh + 1);
        }}
      />
    );
  }
  return null;
};
