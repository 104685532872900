import React, { useReducer } from 'react';
import { type Subtract } from 'utility-types';

import { type ParcelsFlowProviderComponentProps, type ParcelsFlowProviderProps } from './ParcelsFlow.type';
import ParcelsFlowReducer, { initialState } from './ParcelsFlowSlice';

const { Consumer, Provider } = React.createContext({});

export const ParcelsFlowProvider = ({ children }: ParcelsFlowProviderComponentProps) => {
  const [parcelsFlowState, dispatchParcelsFlow] = useReducer(ParcelsFlowReducer, initialState);
  const value: ParcelsFlowProviderProps = {
    parcelsFlowState,
    dispatchParcelsFlow,
  };
  return <Provider value={value}>{children}</Provider>;
};

const withParcelsFlow =
  <P extends ParcelsFlowProviderProps>(Component: React.ComponentType<P>) =>
  (props: Subtract<P, ParcelsFlowProviderProps>) =>
    <Consumer>{interventionsContext => <Component {...interventionsContext} {...(props as P)} />}</Consumer>;

export default withParcelsFlow;
