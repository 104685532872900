import React, { type MouseEvent } from 'react';

import { handleEnterOrSpace } from '../../business/eventHelper';
import { useI18n } from '../../store/i18n.selectors';
import { updateTranslations } from '../../store/i18nSlice';
import store from '../../store/store';
import type backupEN from './backupTransl/en.json';
import { replaceKeyInString } from './replaceKeyInString';

export type I18nKey = keyof typeof backupEN;
export type Translations = Record<I18nKey, string>;
export type TranslationsAdditionalParams = Record<string, string | undefined>;

const getTranslation = (messages: Translations, id: I18nKey, args?: TranslationsAdditionalParams) => {
  const translation = messages[id];
  if (!translation) {
    return id;
  }
  if (!args) {
    return translation;
  }

  return replaceKeyInString(args, translation);
};

const translate = (id: I18nKey, args?: TranslationsAdditionalParams) => getTranslation(store.getState().i18n.messages, id, args);

/**
 * @deprecated do not use store outside on component
 */
export const initTranslation = (messages: Translations) => {
  store.dispatch(updateTranslations(messages));
};

export interface FormattedMessageProps {
  id: I18nKey;
  className?: string;
  additionalParams?: TranslationsAdditionalParams;
  onClick?: () => void;
}

export const FormattedMessage = ({ id, className, onClick, additionalParams }: FormattedMessageProps) => {
  const translation = useI18n([{ id, args: additionalParams }]);
  const handleClick = (event: MouseEvent) => {
    if (onClick) {
      onClick();
      event.stopPropagation();
    }
  };

  return (
    <span
      className={className}
      onClick={handleClick}
      tabIndex={onClick ? 0 : -1}
      role="button"
      onKeyDown={event => onClick && handleEnterOrSpace(onClick)(event)}
    >
      {translation}
    </span>
  );
};

export default translate;
