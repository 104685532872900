import cache from 'lscache';
import cookie from 'js-cookie';

import { authenticationStorageKey, languageStorageKey, sessionIdentifierKey, storeStorageKey } from '../components/auth/constants';
import { minutesToMidnight } from './utils';

const authenticationStorageKeyExpirationKey = `lscache-${authenticationStorageKey}-token-cacheexpiration`;

export const cleanBeforeReload = (newLanguage: any, newStore: number | null) => {
  const storedLanguages = JSON.parse(cache.get(languageStorageKey));
  const storedStore = cache.get(storeStorageKey);
  const token = cache.get(`${authenticationStorageKey}-token`);
  const sessionIdentifier = cache.get(sessionIdentifierKey);
  const tokenExpiry = localStorage.getItem(authenticationStorageKeyExpirationKey);
  while (Object.keys(localStorage).some(storageKey => storageKey.includes('lscache'))) {
    cache.flush();
  }
  cookie.remove('sap-usercontext');
  cache.set(`${authenticationStorageKey}-token`, token);
  tokenExpiry && localStorage.setItem(authenticationStorageKeyExpirationKey, tokenExpiry);
  cache.set(sessionIdentifierKey, sessionIdentifier, minutesToMidnight());
  cache.set(languageStorageKey, newLanguage ? JSON.stringify(newLanguage) : JSON.stringify(storedLanguages));
  cache.set(storeStorageKey, newStore || storedStore);

  sessionStorage.setItem(authenticationStorageKey, '');
  window.location.replace('/');
};
