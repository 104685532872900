import React, { type ReactNode, Suspense } from 'react';
import { connect } from 'react-redux';
import { v4 } from 'uuid';

import { createLog } from '../apis/logger';
import { type RootState } from '../store/store';
import history from '../utils/history/customHistory';

const ErrorPage = React.lazy(() => import('./ErrorPage/ErrorPage'));

export interface ErrorBoundaryProps {
  children: ReactNode;
  docNumber?: string;
  language: string;
  productCode?: number;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  correlationId?: string;
}
const logger = createLog('AppError');

export class AppErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    const correlationId = v4();
    const { docNumber, language, productCode } = this.props;

    logger.log(error, {
      correlation_id: correlationId,
      uri: history.location.pathname,
      invoice_number: docNumber,
      language,
      product_code: productCode,
    });
    this.setState(state => ({ ...state, correlationId }));
  }

  render() {
    const { hasError, correlationId } = this.state;
    const { children } = this.props;
    return hasError ? (
      <Suspense fallback="...">
        <ErrorPage correlationId={correlationId} />
      </Suspense>
    ) : (
      children
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  docNumber: state.intervention.footer.docNumber,
  language: `${state.i18n.appLanguage?.language.toLowerCase()}-${state.i18n.appLanguage?.country.toUpperCase()}`,
  productCode: state.catalog.search.product.id,
});

export default connect(mapStateToProps)(AppErrorBoundary);
