import React, { createContext, type ReactNode, useCallback, useContext, useMemo, useReducer, useState } from 'react';
import { type Subtract } from 'utility-types';

import { type InterventionNature, type Entity, type NatureCategory, type NatureWithCategory } from '../../apis/ilink.type';
import { type EntityQuantityUpdate } from '../../apis/interventions/intervention.type';
import { type Article } from '../../apis/masterdata/masterdata.type';
import { type EntitySearch } from '../../business/entityHelper';
import { capitalizeString } from '../../business/helpers';
import { addEntities } from '../../store/interventionSlice';
import { type ProductTabType } from '../InterventionPage/CatalogAddLinks/CatalogAddLinks';
import ProductReducer, {
  initialState,
  selectArticleAction,
  setArticleStockAction,
  setServiceArticlesAction,
  setServiceQuantityAction,
  setSparePartsArticlesAction,
  setSparePartQuantityAction,
  initNatureCategoriesAction,
  selectNatureCategoryAction,
  selectSparePartNatureAction,
  replaceRelatedSparePartsAction,
  emptyNatureCategoriesAction,
  addSparePartToNatureAction,
  fillSparePartsAction,
  filterEntitiesAction,
  resetSearchAction,
} from './ProductReducer';

interface SearchedEntities {
  label: string;
  modelCode: number;
  nature: { id: number; label: string };
  natureCategory: { id: number; label: string };
}

const mapToEntities =
  (nature: InterventionNature, natureCategory: NatureCategory): ((value: Entity, index: number, array: Entity[]) => SearchedEntities) =>
  entity => ({
    label: capitalizeString(entity.name),
    modelCode: entity.modelCode,
    nature: {
      id: nature.natureId,
      label: capitalizeString(nature.natureTitle),
    },
    natureCategory: {
      id: natureCategory.id,
      label: capitalizeString(natureCategory.label),
    },
  });

const sortEntities = (a: SearchedEntities, b: SearchedEntities): number => a.modelCode - b.modelCode;

export interface ProductProviderProps {
  spareParts: Entity[];
  services: Entity[];
  natureCategories: NatureCategory[];
  selectedNature: NatureWithCategory | null;
  allSparePartsSelected: boolean;
  setAllSparePartsSelected: (display: boolean) => void;
  getEntitiesToSearch: (entityType: keyof typeof ProductTabType) => EntitySearch[];
  emptyNatureCategories: () => void;
  initNatureCategories: (natureCategories: NatureCategory[]) => void;
  replaceRelatedSpareParts: (natureId: number, spareParts: Entity[], categoryId: number) => void;
  selectArticle: (modelCode: number, articleId: number, categoryId: number) => void;
  setSparePartQuantity: (model: EntityQuantityUpdate) => void;
  setServiceQuantity: (model: EntityQuantityUpdate) => void;
  setArticleStock: (modelCode: number, articleId: number, stock: number, categoryId: number) => void;
  formatInterventionNatures: () => InterventionNature[];
  setSparePartsArticles: (articles: Article[]) => void;
  setServiceArticles: (articles: Article[]) => void;
  selectNatureCategory: (natureCategoryId: number) => void;
  selectSparePartNature: (natureCategoryId: number, natureId: number) => void;
  addSparePartToNature: (natureId: number, sparePart: Entity, categoryId: number) => void;
  fillSpareParts: (spareParts: Entity[]) => void;
  filterEntities: (entityIds: number[], tabType: keyof typeof ProductTabType, categoryId: number, natureId?: number) => void;
  resetSearch: () => void;
}

export const ProductContext = createContext<ProductProviderProps | null>(null);

interface ProductProviderComponentProps {
  children: ReactNode;
}

export const ProductProvider = ({ children }: ProductProviderComponentProps) => {
  const [productState, dispatch] = useReducer(ProductReducer, initialState);
  const [allSparePartsSelected, setAllSparePartsSelected] = useState(false);
  const emptyNatureCategories = useCallback(() => dispatch(emptyNatureCategoriesAction()), []);
  const initNatureCategories = useCallback((newNatureCategories: NatureCategory[]) => dispatch(initNatureCategoriesAction(newNatureCategories)), []);
  const replaceRelatedSpareParts = useCallback(
    (natureId: number, spareParts: Entity[], categoryId: number) => dispatch(replaceRelatedSparePartsAction({ natureId, spareParts, categoryId })),
    []
  );
  const selectArticle = useCallback(
    (modelCode: number, articleId: number, categoryId: number) => dispatch(selectArticleAction({ modelCode, articleId, categoryId })),
    []
  );
  const setSparePartQuantity = useCallback((model: EntityQuantityUpdate) => dispatch(setSparePartQuantityAction(model)), []);
  const setServiceQuantity = useCallback((model: EntityQuantityUpdate) => dispatch(setServiceQuantityAction(model)), []);
  const setArticleStock = useCallback(
    (modelCode: number, articleId: number, stock: number, categoryId: number) =>
      dispatch(setArticleStockAction({ modelCode, articleId, stock, categoryId })),
    []
  );
  const setSparePartsArticles = useCallback((articles: Article[]) => dispatch(setSparePartsArticlesAction(articles)), []);
  const setServiceArticles = useCallback((articles: Article[]) => dispatch(setServiceArticlesAction(articles)), []);
  const selectNatureCategory = useCallback((natureCategoryId: number) => dispatch(selectNatureCategoryAction(natureCategoryId)), []);
  const selectSparePartNature = useCallback(
    (natureCategoryId: number, natureId: number) => dispatch(selectSparePartNatureAction({ natureCategoryId, natureId })),
    []
  );
  const addSparePartToNature = useCallback(
    (natureId: number, sparePart: Entity, categoryId: number) => dispatch(addSparePartToNatureAction({ natureId, sparePart, categoryId })),
    []
  );
  const fillSpareParts = useCallback((spareParts: Entity[]) => dispatch(fillSparePartsAction(spareParts)), []);
  const filterEntities = useCallback(
    (modelCodes: number[], tabType: keyof typeof ProductTabType, categoryId: number, natureId?: number) =>
      dispatch(filterEntitiesAction({ modelCodes, tabType, natureId, categoryId })),
    []
  );
  const resetSearch = useCallback(() => dispatch(resetSearchAction()), []);

  const hasQuantity = (entity: Entity) => entity?.quantity > 0;

  const filterDuplicatedArticles = (articles: Article[]) => {
    const articlesMap = new Map(articles.map(article => [article.articleId, article]));

    return [...articlesMap.values()];
  };

  const formatInterventionNatures = (): InterventionNature[] => {
    const formattedInterventionNatures = productState
      .flatMap(category => category.natures)
      .reduce((acc: InterventionNature[], nature) => {
        if (nature.spareParts.some(hasQuantity) || nature.services.some(hasQuantity)) {
          acc.push({
            ...nature,
            services: addEntities(nature.services.filter(hasQuantity), []),
            spareParts: addEntities(nature.spareParts.filter(hasQuantity), []),
          });
        }
        return acc;
      }, []);

    return formattedInterventionNatures.map(nature => ({
      ...nature,
      spareParts: nature.spareParts.map(sparePart => ({
        ...sparePart,
        articles: filterDuplicatedArticles(sparePart.articles),
      })),
      services: nature.services.map(service => ({
        ...service,
        articles: filterDuplicatedArticles(service.articles),
      })),
    }));
  };

  const fullSpareParts = formatInterventionNatures().flatMap(nature => nature.spareParts);
  const fullServices = formatInterventionNatures().flatMap(servicesNature => servicesNature.services);

  const selectedNatureCategory = productState.find(natureCategory => natureCategory.selected);
  const selectedInterventionNature = selectedNatureCategory?.natures.find(nature => nature.selected) ?? null;
  const selectedNature: NatureWithCategory | null =
    selectedNatureCategory && selectedInterventionNature
      ? {
          ...selectedInterventionNature,
          categoryId: selectedNatureCategory.id,
        }
      : null;

  const getEntitiesToSearch = (entityType: keyof typeof ProductTabType) =>
    productState.flatMap(natureCategory =>
      natureCategory.natures.flatMap(nature => nature[entityType].map(mapToEntities(nature, natureCategory)).sort(sortEntities))
    );

  const value: ProductProviderProps = useMemo(
    () => ({
      spareParts: fullSpareParts,
      services: fullServices,
      natureCategories: productState,
      selectedNature,
      allSparePartsSelected,
      setAllSparePartsSelected,
      getEntitiesToSearch,
      emptyNatureCategories,
      initNatureCategories,
      replaceRelatedSpareParts,
      selectArticle,
      setSparePartQuantity,
      setServiceQuantity,
      setArticleStock,
      formatInterventionNatures,
      setSparePartsArticles,
      setServiceArticles,
      selectNatureCategory,
      selectSparePartNature,
      addSparePartToNature,
      fillSpareParts,
      filterEntities,
      resetSearch,
    }),
    [fullSpareParts, fullServices, productState, selectedNature, allSparePartsSelected]
  );

  return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
};

export const useProductContext = () => {
  const productContext = useContext(ProductContext);

  if (!productContext) {
    throw new Error('useProductContext must be used inside the ProductProvider');
  }

  return productContext;
};

const withProduct =
  <P extends ProductProviderProps>(Component: React.ComponentType<P>) =>
  (props: Subtract<P, ProductProviderProps>) =>
    <ProductContext.Consumer>{context => <Component {...context} {...(props as P)} />}</ProductContext.Consumer>;

export default withProduct;
