import { getArticleIdFromEAN } from '../apis/masterdata/masterdata';

export const getAMIEanCode = (code: string) => {
  if (code.startsWith('200')) {
    return code.trim().substring(3, 9);
  }
  if (code.startsWith('203')) {
    return code.trim().substring(5, 12);
  }
  if (code.startsWith('21')) {
    return code.trim().substring(2, 9);
  }
  return null;
};

export const getFormattedCode = (code: string) => {
  if (code.length === 30) {
    return code.substring(3, 16);
  }
  if (code.length === 13) {
    return code;
  }
  return null;
};

export const getArticleIdFromCode = (code: string): Promise<number> => {
  const formattedCode = getFormattedCode(code);
  if (formattedCode) {
    return getArticleIdFromEAN(parseInt(formattedCode, 10)).catch(() => Number(getAMIEanCode(formattedCode)));
  }
  return Promise.resolve(parseInt(code, 10));
};
