import { type StackFrame } from 'stacktrace-js';

import store from '../store/store';
import { buildUrl, buildHeaders, buildRequest, fetchRequest } from '../utils/apis/RestApi';
import { selectStoreId } from '../store/selectors/userInfo';

interface LoggerOptions {
  componentName: string;
}

export interface Logger {
  log: (error: Error, optionalParams?: object) => void;
}

const createLogger = (logger: LoggerOptions): Logger => {
  const log = async (error: Error, optionalParams?: object) => {
    const callback = (stackframes: StackFrame[]) => stackframes.map(sf => sf.toString()).join('\n');

    const errback = (err: Error) => {
      // eslint-disable-next-line no-console
      console.error(err.message);
      return error.stack ?? '';
    };

    // todo store should not be used outside components
    const state = store.getState();
    const hasLogFromConfig = state.configuration.hasLog;
    const username = state.userInfo.uid;
    const storeId = selectStoreId(state);
    const appUrl = location.pathname;

    const stackTrace = await (await import('stacktrace-js')).default.fromError(error).then(callback).catch(errback);

    const body = {
      username,
      component: logger.componentName,
      message: error.message,
      stackTrace,
      appUrl,
      storeId,
      ...optionalParams,
    };

    if (hasLogFromConfig) {
      const url = buildUrl('ICARE_BACK', 'log');
      const headers = buildHeaders('ICARE_BACK', 'Bearer');
      const request = buildRequest(url, 'POST', headers, JSON.stringify(body));
      fetchRequest(request).catch(() => {
        /* Nothing to do here, what can we do if even the logger is down ? 💀 */
      });
    } else {
      // eslint-disable-next-line no-console
      console.warn(body);
    }
  };
  return { log };
};

export const createLog = (componentName: string) => createLogger({ componentName });

const cacheLogger = createLog('cache');
export const cacheErrorLog = (error: Error | unknown) => error instanceof Error && cacheLogger.log(error, { level: 'warn' });
