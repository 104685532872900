import { type ServiceApiKey } from '../../utils/config/configuration.type';
import { type PogoDetail } from '../interventions/intervention.type';

export interface SelectedSite {
  address: Address;
  id: number;
  isRegionalWorkshop: boolean;
  name: string;
  shortName: string;
  currency: string;
  maxExpressAmount: number;
  allowSad: boolean;
}

export interface Address {
  countryCode: string;
}

export interface StoreInfo {
  allowSad: boolean;
  contexts: Context[];
  currency: string;
  defaultRegionalWorkshop: number | null;
  displayPriceWithoutTaxes: boolean;
  isMultiTaxes: boolean;
  maxExpressAmount: number;
  siteNumber: number;
  siteType: string;
}

export interface Context {
  id: number;
  internal: boolean;
  pogoList: PogoDetail[];
}

export interface StoreItem {
  id: number;
  name: string;
  countryCode: string;
  isAllowed: boolean;
  isRW: boolean;
  isRWRelated: boolean;
}

export interface StoreItemRaw {
  id: number;
  name: string;
  countryId: string;
  isAllowed?: boolean;
  isRW?: boolean;
  isRWRelated?: boolean;
}

export enum CustomerCommunicationType {
  EMAIL = 'email',
  PHONE_NUMBER = 'phone_number',
  BOTH = 'both',
  NONE = 'none',
}

export interface StartupResponse {
  apiKeys: ServiceApiKey;
  availableLang: string[];
  countryId: number;
  selectedSite: SelectedSite;
  storeId: number;
  storeInfo: StoreInfo;
  storeList: StoreItemRaw[];
  parameters: {
    isHandlingNewProduct: boolean;
    isPurchaseActive: boolean;
    isAllocationAuthorized: boolean;
    customerCreationRequiredFields: string[];
    crmZone: string;
    customerCommunication: CustomerCommunicationType;
    traceabilityActivation: boolean;
    newCustomerPurchasesPage: boolean;
    sparePartsOrder: boolean;
    bookings: boolean;
  };
}
