import React from 'react';
import clsx from 'clsx';
import Search from '@vtmn/icons/dist/vitamix/svg/search-line.svg';

import { useAppSelector } from '../../store/store';
import { ReactComponent as BarcodeReader } from '../../imgs/icons/barcode-scan.svg';
import { selectIsMobile } from '../../store/selectors/app';
import { type I18nKey } from '../../utils/i18n/SwappingIntlProvider';
import { useI18n } from '../../store/i18n.selectors';

import './search-bar.scss';

export interface SearchBarProps {
  setUserInputSearch: (search: string) => void;
  placeholderId?: I18nKey;
  action?: () => void;
  value?: string;
  onClick?: () => void;
  autofocus?: boolean;
  className?: string;
  displayScanner?: () => void;
  onFocus?: () => void;
  id: string;
  testid?: string;
  disabled?: boolean;
  isWithSpace?: boolean;
}

export const SearchBar = ({
  id,
  testid,
  setUserInputSearch,
  placeholderId,
  action,
  value,
  onClick,
  autofocus,
  className,
  displayScanner,
  onFocus,
  disabled = false,
  isWithSpace = true,
}: SearchBarProps) => {
  const [placeholder] = useI18n([placeholderId ?? 'searchbar.placeholder']);
  const isMobile = useAppSelector(selectIsMobile);

  return (
    <div className={clsx('search-bar-container', className)}>
      <label className="hidden-label" htmlFor={id}>
        {placeholder}
      </label>
      <input
        onKeyPress={event => action && event.key === 'Enter' && action()}
        type="text"
        placeholder={placeholder}
        onChange={event => setUserInputSearch(isWithSpace ? event.target.value : event.target.value.replace(/\s/g, ''))}
        title={placeholder}
        value={value}
        onClick={onClick}
        onFocus={onFocus}
        id={id}
        disabled={disabled}
        autoFocus={autofocus}
        data-testid={testid}
      />
      <div className="icons">
        {displayScanner && isMobile && <BarcodeReader id="barcode" fill="red" onClick={displayScanner} />}
        <img src={Search} alt="search" onClick={() => action?.()} />
      </div>
    </div>
  );
};

export default SearchBar;
