/* eslint-disable import/order */
import React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import store from './store/store';
import history from './utils/history/customHistory';
import { App } from './components/App';

import './global.scss';
import './vitamin-play.css';

const isMock = process.env.ICARE_ENV === 'mock';

const addRootDivToTemplate = () => {
  document.documentElement.translate = false;
  const rootDiv: HTMLElement = document.createElement('DIV');
  rootDiv.setAttribute('id', 'root');
  document.body.appendChild(rootDiv);
};

const MainApp = () => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Provider store={store}>
      <Router location={state.location} navigationType={state.action} navigator={history}>
        <App />
      </Router>
    </Provider>
  );
};

const renderAppInRootDiv = () => {
  addRootDivToTemplate();
  createRoot(document.getElementById('root')!).render(<MainApp />);
};

if (isMock) {
  import('./msw/msw-browser').then(async ({ mswBrowser }) => {
    await mswBrowser.start({
      onUnhandledRequest(request, print) {
        if (/icare|decathlon|workshop/.test(request.url.hostname)) {
          print.warning();
        }
      },
    });
    renderAppInRootDiv();
  });
} else {
  renderAppInRootDiv();
}
