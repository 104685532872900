import { type StartupResponse } from './startup.type';
import { buildUrl, buildHeaders, buildRequest, fetchRequest } from '../../utils/apis/RestApi';

export const fetchStartup = async (storeId = '', sitePartyNumber?: string): Promise<StartupResponse> => {
  const path = `startup/v2/${storeId}?sitePartyNumber=${sitePartyNumber}`;
  const url = buildUrl('ICARE_BACK', path);
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const request = buildRequest(url, 'GET', headers);

  return fetchRequest(request).then(response => response.json());
};
