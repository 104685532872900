import React, { createContext, type ReactNode, useContext, useMemo, useState } from 'react';

import Preloader from '../../components/Preloader/Preloader';

interface LoaderProviderProps {
  children: ReactNode;
}

export interface LoaderComponentProps {
  showLoader: () => void;
  hideLoader: () => void;
}

export const LoaderContext = createContext<LoaderComponentProps>({ hideLoader: () => {}, showLoader: () => {} });

export const LoaderProvider = ({ children }: LoaderProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const contextValue = useMemo(
    () => ({
      showLoader: () => setIsLoading(true),
      hideLoader: () => setIsLoading(false),
    }),
    []
  );

  return (
    <LoaderContext.Provider value={contextValue}>
      {isLoading && <Preloader fixed />}
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoaderContext = () => {
  const loaderContext = useContext(LoaderContext);

  if (!loaderContext) {
    throw new Error('useLoaderContext must be used inside the LoaderProvider');
  }

  return loaderContext;
};
