// @ts-strict-ignore
import React, { lazy, type ReactElement, useEffect, useState } from 'react';
import { Case, Switch } from '@chipp972/react-case-when';
import { v4 } from 'uuid';
import cache from 'lscache';

import { useI18nStore, useUserInfoStore, useConfigStore } from '../../store/hooks';
import { getUserInfo } from '../../apis/userinfo';
import { type I18nKey, initTranslation } from '../../utils/i18n/SwappingIntlProvider';
import { languageStorageKey } from '../auth/constants';
import { selectToken } from '../../store/selectors/configuration';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { createLog } from '../../apis/logger';
import { LazyReact } from '../LazyReact/LazyReact';
import Preloader from '../Preloader/Preloader';
import ToolbarTop from '../ToolbarTop/ToolbarTop';
import { setupIntl } from '../../utils/i18n/translations';
import { updateBreadcrumb } from '../../store/catalogSlice';
import { initialBreadcrumb } from '../Breadcrumb/Breadcrumb';
import useToaster from '../../utils/hooks/useToaster';
import { getTranslations } from '../../apis/translations';
import { AlertMessage } from './AlertMessage';

const ErrorPage = lazy(() => import('../ErrorPage/ErrorPage'));

const logger = createLog('StartUp');

const handledKeys: I18nKey[] = ['error.no.available.stores', 'error.no.store.found'];

export default ({ children }: { children: ReactElement }) => {
  const {
    addUserInfo,
    userInfo: { preferredLanguage, sitePartyNumber },
  } = useUserInfoStore();
  const { updateConfigApiKeys } = useConfigStore();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorKey, setErrorKey] = useState<I18nKey>();
  const { updateAppLanguage, updateLocale, appLanguage, updateAvailableLanguages } = useI18nStore();
  const token = useAppSelector(selectToken);
  const { addToast, orange } = useToaster();
  const dispatch = useAppDispatch();
  const navigatorLanguage = /^fr\b/.test(navigator.language) ? 'fr' : 'en';

  useEffect(() => {
    if (token) {
      (async () => {
        try {
          const { userInfo, locales, apiKeys } = await getUserInfo(sitePartyNumber);

          updateAvailableLanguages(locales);
          updateConfigApiKeys(apiKeys);

          const preferredLocale =
            preferredLanguage ??
            locales.find(locale => locale.country.toUpperCase() === userInfo.selectedSite.address.countryCode.toUpperCase())?.language ??
            'en';

          addUserInfo(userInfo);

          const storedAuthenticationLanguage = JSON.parse(cache.get(languageStorageKey));
          const userLocale = storedAuthenticationLanguage ??
            appLanguage ??
            locales.find(
              locale =>
                locale.language.toUpperCase() === preferredLocale.toUpperCase() &&
                locale.country.toUpperCase() === userInfo.selectedSite.address.countryCode.toUpperCase()
            ) ??
            locales.find(locale => locale.language.toUpperCase() === preferredLocale.toUpperCase()) ?? {
              language: 'en',
              country: 'GB',
            };

          const { language, country } = userLocale;

          const appLocale = `${language.toLowerCase()}-${country.toUpperCase()}`;

          try {
            const translation = await getTranslations(userLocale);

            // Old way to init translation
            initTranslation(translation);

            // New way to init translation
            setupIntl({ locale: appLocale, translation });

            updateLocale(userLocale);

            if (!appLanguage) {
              updateAppLanguage(userLocale);
            }
          } catch (e) {
            const toastMsg =
              navigatorLanguage === 'fr'
                ? "Impossible de charger les traductions, seuls l'anglais et le français sont disponibles."
                : "Failed to load countries' languages. Only english and french translations are available.";
            const backupI18n = await import(`../../utils/i18n/backupTransl/${navigatorLanguage}.json`);

            initTranslation({ ...backupI18n });
            setupIntl({ locale: appLocale, translation: backupI18n });
            addToast(toastMsg, orange);
          } finally {
            dispatch(updateBreadcrumb([initialBreadcrumb()]));
          }
        } catch (err) {
          const usedBackupLocale = navigatorLanguage === 'fr' ? 'fr-FR' : 'en-GB';
          const backupI18n = await import(`../../utils/i18n/backupTransl/${navigatorLanguage}.json`);

          // Old way to init translation
          initTranslation(backupI18n);

          // New way to init translation
          setupIntl({ locale: usedBackupLocale, translation: backupI18n });

          try {
            const data = await err.response.json();
            const replacedCode = data.code?.replace(/-/g, '.');
            const key = handledKeys.find(handledKey => handledKey === `error.${replacedCode}`);

            if (key) {
              setErrorKey(key);
            }
          } catch (e) {
            logger.log(e);
          }

          setError(true);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [token]);

  return (
    <>
      <AlertMessage />
      <Switch>
        <Case when={loading}>
          <Preloader text="Loading…" fixed />
        </Case>
        <Case when={error}>
          {() => (
            <LazyReact>
              <ToolbarTop />
              <ErrorPage correlationId={v4()} errorKey={errorKey} />
            </LazyReact>
          )}
        </Case>
        <Case>{children}</Case>
      </Switch>
    </>
  );
};
