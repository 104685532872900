import { useRef, useEffect } from 'react';

function assertIsNode(eventTarget: EventTarget | null): asserts eventTarget is Node {
  if (!eventTarget || !('nodeType' in eventTarget)) {
    throw new Error('eventTarget is not a Node');
  }
}

export default <T extends HTMLElement>(toggleFunction: (isDisplayed: boolean) => void, stopPropagation?: boolean) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      try {
        assertIsNode(event.target);

        if (ref.current && ref.current.contains(event.target)) return;

        toggleFunction(false);

        if (stopPropagation) {
          event.stopPropagation();
          event.preventDefault();
        }
      } catch (err) {
        /* assertIsNode throw */
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleFunction]);

  return ref;
};
