import camelcaseKeys from 'camelcase-keys';
import { type CountryCode, getCountryCallingCode, isSupportedCountry } from 'libphonenumber-js';

import { createLog } from './logger';
import { buildUrl, buildHeaders, buildRequest, fetchRequest } from '../utils/apis/RestApi';
import { handleError, handleHttpErrors } from '../business/helpers';
import { type Brand, type ProductNature } from './dictionaries.type';
import { type MemberCountry, type MemberCountryRaw } from './member/member.types';

const logger = createLog('api/dictionary');

const base_path = 'dictionary';

export const getAvailableBrands = (): Promise<Brand[]> => {
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const url = buildUrl('ICARE_BACK', `${base_path}/brands`);
  const request = buildRequest(url, 'GET', headers);

  return fetchRequest(request)
    .then(response => response.json())
    .catch(() => handleError('Dictionaries Request - GET brands'));
};

export const getProductNatureLabelsByIds = (productNatureIds: number[]): Promise<ProductNature[]> => {
  if (!productNatureIds?.length) {
    return Promise.resolve([]);
  }

  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const url = buildUrl('ICARE_BACK', `${base_path}/product_nature_label/${productNatureIds}`);
  const request = buildRequest(url, 'GET', headers);

  return fetchRequest(request).then(response => response.json());
};

export const getCountries = (): Promise<MemberCountry[]> => {
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const url = buildUrl('ICARE_BACK', `${base_path}/countries`);
  const request = buildRequest(url, 'GET', headers);

  return fetchRequest(request)
    .then(response => response.json())
    .then(data => camelcaseKeys<MemberCountryRaw[]>(data, { deep: true }))
    .then(rawCountries =>
      rawCountries
        .filter(country => isSupportedCountry(country.countryIso2))
        .map(country => {
          const codeISO2 = country.countryIso2 as CountryCode;
          return {
            codeISO2,
            codeISO3: country.countryIso3,
            name: country.countryName,
            phoneAreaCode: `+${getCountryCallingCode(codeISO2)}`,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    )
    .catch(httpError => handleHttpErrors(httpError, 'dictionary', logger, { url }));
};
