import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

interface AuthServiceParams {
  idpUrl: string;
  clientId: string;
  clientSecret: string;
}

export interface AuthState {
  service: UserManager;
}

const initialState: AuthState = {
  service: {} as UserManager,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: builder => builder.addCase('resetStore', () => initialState),
  reducers: {
    initAuthService: (state, { payload: { idpUrl, clientId, clientSecret } }: PayloadAction<AuthServiceParams>) => {
      state.service = new UserManager({
        authority: idpUrl,
        client_id: clientId,
        redirect_uri: location.origin,
        silent_redirect_uri: location.origin,
        response_type: 'code',
        userStore: new WebStorageStateStore({ store: localStorage }),
        scope: 'openid profile',
        filterProtocolClaims: true,
        loadUserInfo: true,
        automaticSilentRenew: false,
        client_secret: clientSecret,
      });
    },
  },
});

export const {
  reducer: authReducer,
  actions: { initAuthService },
} = authSlice;
