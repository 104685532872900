// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9NHyhaeHis5cexkgc70q0A\\=\\={align-items:center;background:var(--vp-core-color-red-200);border-left:solid var(--vp-semantic-border-width-s) var(--vp-semantic-color-status-negative);left:0;padding:var(--vp-semantic-spacing-m);position:sticky;right:0;top:0;z-index:1000}._9NHyhaeHis5cexkgc70q0A\\=\\=,._9NHyhaeHis5cexkgc70q0A\\=\\= p{display:flex;gap:var(--vp-semantic-spacing-xs)}._9NHyhaeHis5cexkgc70q0A\\=\\= p{flex:1 1 auto;margin:0}._3JryvkwNM0XAPw2wBkL-YQ\\=\\={font-weight:700}", "",{"version":3,"sources":["webpack://./src/components/shared/Message/message.module.css"],"names":[],"mappings":"AAAA,6BACE,kBAAmB,CACnB,uCAAwC,CACxC,4FAA6F,CAK7F,MAAO,CAFP,oCAAqC,CACrC,eAAgB,CAEhB,OAAQ,CACR,KAAM,CACN,YAQF,CANE,4DATA,YAAa,CACb,iCAaA,CALA,+BAIE,aAAc,CAHd,QAIF,CAGF,6BACE,eACF","sourcesContent":[".message {\n  align-items: center;\n  background: var(--vp-core-color-red-200);\n  border-left: solid var(--vp-semantic-border-width-s) var(--vp-semantic-color-status-negative);\n  display: flex;\n  gap: var(--vp-semantic-spacing-xs);\n  padding: var(--vp-semantic-spacing-m);\n  position: sticky;\n  left: 0;\n  right: 0;\n  top: 0;\n  z-index: 1000;\n\n  p {\n    margin: 0;\n    display: flex;\n    gap: var(--vp-semantic-spacing-xs);\n    flex: 1 1 auto;\n  }\n}\n\n.title {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "_9NHyhaeHis5cexkgc70q0A==",
	"title": "_3JryvkwNM0XAPw2wBkL-YQ=="
};
export default ___CSS_LOADER_EXPORT___;
