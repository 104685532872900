import React, { createContext, type ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { type Subtract } from 'utility-types';

import { type RootState } from '../../store/store';
import { getCountries } from '../../apis/dictionaries';
import { type MemberCountry } from '../../apis/member/member.types';
import { type Language } from '../../apis/customer/customer.types';
import { getILinkAvailableLocales } from '../../apis/ilink';
import { useCustomerStore, useI18nStore } from '../../store/hooks';
import { getCountryCode } from '../../business/customerHelper';
import Preloader from '../../components/Preloader/Preloader';

export interface CountriesProps {
  languages: Language[];
  countries: MemberCountry[];
  customerCountry: MemberCountry;
  setCustomerCountry: (customerCountry: MemberCountry) => void;
}

export const CountryProviderContext = createContext<CountriesProps>({
  countries: [],
  languages: [],
  customerCountry: { codeISO2: undefined, codeISO3: '', name: '', phoneAreaCode: '' },
  setCustomerCountry: () => {},
});

const defaultCountry: MemberCountry = { codeISO2: undefined, codeISO3: '', name: '', phoneAreaCode: '' };

interface CountryProviderProps {
  children: ReactNode;
}

export const CountryProvider = ({ children }: CountryProviderProps) => {
  const navigate = useNavigate();
  const [countries, setCountries] = useState<MemberCountry[]>([]);
  const { appLanguage } = useI18nStore();
  const { customer } = useCustomerStore();
  const [customerCountry, setCustomerCountry] = useState<MemberCountry>(defaultCountry);
  const [iLinkLocales, setILinkLocales] = useState<Language[]>([]);
  const siteCountryCode = useSelector((state: RootState) => state.userInfo.selectedSite.address.countryCode);

  const countryCode = customer?.countryCode ?? siteCountryCode;

  useEffect(() => {
    if (appLanguage) {
      getILinkAvailableLocales(appLanguage).then(setILinkLocales);
      getCountries()
        .then(setCountries)
        .catch(() => {
          navigate('/errorPage');
        });
    }
  }, [appLanguage]);

  useEffect(() => {
    if (countries?.length) {
      const currentCustomerCountry = countries.find(country => getCountryCode(country)?.toUpperCase() === countryCode.toUpperCase());
      setCustomerCountry(currentCustomerCountry || countries[0]);
    }
  }, [countries, countryCode]);

  const contextValue: CountriesProps = useMemo(
    () => ({
      countries,
      customerCountry,
      setCustomerCountry,
      languages: iLinkLocales,
    }),
    [customerCountry, countries, iLinkLocales]
  );

  return (
    <CountryProviderContext.Provider value={contextValue}>
      {customerCountry.codeISO2 ? children : <Preloader text="Loading……" fixed />}
    </CountryProviderContext.Provider>
  );
};

export const useCountryContext = () => {
  const countryContext = useContext(CountryProviderContext);

  if (!countryContext) {
    throw new Error('useCountryContext must be used inside the CountryProviderContext');
  }

  return countryContext;
};

/**
 *
 * @deprecated
 */
const withCountries =
  <P extends CountriesProps>(Component: React.ComponentType<P>) =>
  (props: Subtract<P, CountriesProps>) =>
    (
      <CountryProviderContext.Consumer>
        {(countriesContext: CountriesProps) => <Component {...countriesContext} {...(props as P)} />}
      </CountryProviderContext.Consumer>
    );

export default withCountries;
