export const enum RoutesPath {
  homePage = '/',
  sparePartOrders = '/spare-part-orders',
  interventionPage = '/intervention',
  catalogRootPage = '/catalog',
  catalogPage = '/catalog/product',
  catalogInterventionPage = '/catalog/intervention',
  planningPage = '/planning',
  planningCustomerInterventionPage = '/planning/customer-interventions',
  planningInterventionPage = '/planning/interventions',
  parcelFlowPage = '/parcels-flow',
  massiveInvoicePage = '/search/massive-invoice',
  customerPage = '/customer',
  customerCreatePage = '/customer/create',
  customerUpdatePage = '/customer/update',
  errorPage = '/errorPage',
  productPage = '/product',
  productInterventionPage = '/product/intervention',
  shippingGroupPage = '/shipping-group',
  adminPage = '/admin',
  searchPage = '/search',
  searchResultPage = '/search/results',
  forecastPage = '/forecast',
  delayParamPage = '/delays-parameters',
  bookingsPage = '/bookings',
}

export const getAppRoute = (route: RoutesPath, routeParam?: string) => `${route}${routeParam ? `/${routeParam}` : ''}`;
