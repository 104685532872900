import React, { type FunctionComponent, type MouseEvent, type PointerEvent } from 'react';
import clsx from 'clsx';
import { Switch, Case } from '@chipp972/react-case-when';

import { type I18nKey, type TranslationsAdditionalParams } from '../../utils/i18n/SwappingIntlProvider';
import { useI18n } from '../../store/i18n.selectors';
import Loader from '../Loader/Loader';

import './button.scss';

export enum ButtonType {
  BLACK = 'black',
  REVERSED = 'reversed',
  WHITE = 'white',
  YELLOW = 'yellow',
  GRADIENT = 'gradient',
}

export interface ButtonProps {
  content: I18nKey;
  className?: string;
  action?: (event: MouseEvent) => void;
  onMouseEnter?: (event: MouseEvent) => void;
  onPointerDown?: (event: PointerEvent<HTMLButtonElement>) => void;
  color?: ButtonType;
  submit?: boolean;
  icon?: string;
  Svg?: FunctionComponent;
  disabled?: boolean;
  loading?: boolean;
  additionalParams?: TranslationsAdditionalParams;
  testid?: string;
}

export default ({
  content,
  action,
  onMouseEnter,
  onPointerDown,
  color = ButtonType.BLACK,
  submit = false,
  icon,
  Svg,
  disabled,
  loading = false,
  className,
  additionalParams,
  testid,
}: ButtonProps) => {
  const [contentText] = useI18n([{ id: content, args: additionalParams }]);

  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={clsx('button', color, className, { disabled, loading })}
      onMouseEnter={onMouseEnter}
      onPointerDown={onPointerDown}
      disabled={disabled}
      onClick={action}
      data-testid={testid}
    >
      <Switch>
        <Case when={loading}>
          <Loader width="small" />
        </Case>
        <Case>
          <span>{contentText}</span>
          {icon && !disabled && <i className={icon} />}
          {Svg && <Svg />}
        </Case>
      </Switch>
    </button>
  );
};
