import { type ReduxAjaxParams, RequestMethod, useReduxAjax } from '@chipp972/redux-ajax';
import clsx from 'clsx';

import { type ILinkCategoryRaw } from '../../apis/ilink.type';
import { type PlanningInterventionResponse, type InProgressResponse, type InterventionIdsByDate, type PlanningParams } from './planning.type';
import type { Filter } from '../Filters/Filter';
import { getComparisonOperator, getLogicalOperator, setFilterParam } from '../../business/requestHelper';
import { buildUrl } from '../../utils/apis/RestApi';
import { getIsSameDay, getIsSameMonth, getCurrentDate, getEndOfDay } from '../../utils/date/date-utils';

const getSelectedFilter = (filterItem: Filter) => filterItem.selected;

const formatDateForParams = (date: Date) => {
  const formatedDate = new Date(date);
  formatedDate.setMinutes(formatedDate.getMinutes() - formatedDate.getTimezoneOffset());

  return formatedDate.toISOString().replace(',', '').slice(0, -1);
};

export const formatTodoEvent = (interventions: PlanningInterventionResponse[], withTheoreticalTime: boolean) =>
  interventions.reduce((prevInterventions: InterventionIdsByDate, currIntervention) => {
    const date = new Date(currIntervention.pledge_date).toLocaleDateString('en-US');
    prevInterventions[date] = {
      ids: [...(prevInterventions[date]?.ids ?? []), currIntervention.document_number],
      totalTime: withTheoreticalTime ? (prevInterventions[date]?.totalTime ?? 0) + (currIntervention.total?.theoretical_time ?? 0) : 0,
    };
    return prevInterventions;
  }, {});

export const getCellClass = ({ currentDay, monthStart, isWeekCell }: { currentDay: Date; monthStart: Date; isWeekCell: boolean }) =>
  clsx('planning-cell', {
    'out-of-month': !getIsSameMonth({ firstDate: currentDay, secondDate: monthStart }),
    today: getIsSameDay({ firstDate: currentDay, secondDate: getCurrentDate() }) && !isWeekCell,
    'total-week': isWeekCell,
  });

export const getPlanningParams = ({
  startDate,
  endDate,
  storeId,
  processes,
  interventionStatesFilter,
  headers,
  withTheoreticalTime,
}: PlanningParams): ReduxAjaxParams => {
  const param = new URLSearchParams({
    includes: `pledge_date,document_number,services.states${withTheoreticalTime ? ',total.theoretical_time' : ''}`,
    realisation_site: getComparisonOperator(storeId, 'eq'),
    size: '2000',
    family_id: setFilterParam(processes.filter(getSelectedFilter)),
    'states.state.id': setFilterParam(
      interventionStatesFilter
        .reduce((acc: Filter[], state) => {
          acc.push(...(state?.subFilters?.map(newState => ({ ...newState, selected: state.selected })) ?? []));
          return acc;
        }, [])
        .filter(getSelectedFilter)
    ),
  });
  param.append('pledge_date', getComparisonOperator(formatDateForParams(startDate), 'gte'));
  param.append('pledge_date', getLogicalOperator(getComparisonOperator(formatDateForParams(getEndOfDay(endDate)), 'lte'), 'and'));

  const url = `${buildUrl('WORKSHOP_V3', 'interventions')}?${param.toString()}`;

  return {
    url,
    method: RequestMethod.get,
    headers,
  };
};

export const useProcessesRequest = () => useReduxAjax<ILinkCategoryRaw[]>('processes');
export const usePlanningRequest = () => useReduxAjax<InProgressResponse>('intervention-planning');

const optionsRelativeFormat: Intl.RelativeTimeFormatOptions = {
  numeric: 'always',
  style: 'narrow',
};

interface TimeFormatForPlanningCell {
  value: string;
  unit: string;
}
const predicateFormatRelativeTime = (prev: TimeFormatForPlanningCell, curr: Intl.RelativeTimeFormatPart): TimeFormatForPlanningCell => ({
  value: curr.type === 'integer' ? prev.value + curr.value : prev.value,
  unit: curr.type === 'literal' ? curr.value.trim() : prev.value,
});

export const formatTheoreticalTime = (time: number, lang: string) => {
  const rawHours = Math.trunc(time / 60);
  const rawMinutes = time % 60;

  const relativeTimeFormat = new Intl.RelativeTimeFormat(lang, optionsRelativeFormat);

  const hours = relativeTimeFormat.formatToParts(rawHours, 'hour').reduce(predicateFormatRelativeTime, { unit: '', value: '' });
  const minutes = relativeTimeFormat.formatToParts(rawMinutes, 'minute').reduce(predicateFormatRelativeTime, { unit: '', value: '' });
  const textHours = rawHours > 0 ? `${Number(hours.value)}${hours.unit}` : '';
  const textMinutes = rawMinutes > 0 || rawHours + rawMinutes === 0 ? `${Number(minutes.value)}${minutes.unit}` : '';

  return `${textHours} ${textMinutes}`.trim();
};
