import React from 'react';

import './preloader.scss';

interface PreloaderProps {
  fixed?: boolean;
  text?: string;
}

export default ({ fixed, text = 'Loading…' }: PreloaderProps) => (
  <div className={`container-loader ${fixed ? 'force-fixed' : ''}`} role="progressbar" data-testid="interventionLoader">
    <div className="container-dash">
      <div className="dash one" />
      <div className="dash two" />
      <div className="dash three" />
      <div className="dash four" />
    </div>
    <div className="loading-text">{text}</div>
  </div>
);
