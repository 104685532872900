import store from '../store/store';
import translate from '../utils/i18n/SwappingIntlProvider';
import { isInternalPogo } from './pogoHelper';
import { type ServiceWorkshop, type SparePartWorkshop } from '../apis/interventions/intervention.type';
import { type Entity } from '../apis/ilink.type';
import type { Article } from '../apis/masterdata/masterdata.type';
import { findSelectedArticle } from './articleHelper';
import { Pogos } from '../apis/interventions/intervention.const';

export const payableLabel = () => translate('spare.part.card.payable.label').toUpperCase();
export const warrantyLabel = () => translate('spare.part.card.warranty.label').toUpperCase();
export const ogeaLabel = () => translate('spare.part.card.ogea.label').toUpperCase();

export const isPriceOk = (selectedArticle: Article) => selectedArticle.value !== null;
export const isEveryPriceKO = (articles: Article[]) => articles.every(article => article.value === null);

export const getPrice = (service: ServiceWorkshop | SparePartWorkshop) => {
  const { displayPriceWithoutTaxes } = store.getState().userInfo.selectedSite;

  return displayPriceWithoutTaxes
    ? service.theoreticalUnitPriceWithoutTaxes ?? service.unitPriceWithoutTaxes
    : service.theoreticalUnitPriceWithTaxes ?? service.unitPriceWithTaxes;
};

export const getTotalAmount = (services: Entity[], spareParts: Entity[]) =>
  [...services, ...spareParts].reduce((acc, entity) => {
    const selectedArticle = entity.articles.find(article => article.selected);
    const selectedValue = selectedArticle?.value ?? 0;
    const value = selectedArticle?.free || isInternalPogo(selectedArticle?.gridValueId) ? 0 : selectedValue * entity.quantity;
    return acc + value;
  }, 0);

export const getOgeaAmount = (services: Entity[], spareParts: Entity[]) =>
  services.concat(spareParts).reduce((acc, intervention: Entity) => {
    const selectedArticle = intervention.articles.find(article => article.selected);
    if (selectedArticle?.gridValueId === Pogos['ASSURANCE OGEA']) {
      const selectedValue = selectedArticle?.value ?? 0;
      return acc + selectedValue * intervention.quantity;
    }

    return acc;
  }, 0);

export const findWarranty = (entities: Entity[]) =>
  entities.some(entity => entity.articles.some(article => article.selected && article.gridValueId === Pogos.GARANTI));

export const findFullWarranty = (entities: Entity[]) =>
  entities.every(entity => entity.articles.some(article => article.selected && article.gridValueId === Pogos.GARANTI));

export const findPriceless = (entities: Entity[], onlySelected?: boolean) =>
  entities.some(entity => {
    const articlesToCheck = onlySelected ? entity.articles.filter(findSelectedArticle) : entity.articles;
    return articlesToCheck.some(article => !article.value);
  });
