import React from 'react';
import { VpIcon, VpIconButton } from '@vtmn-play/react';
import { Case } from '@chipp972/react-case-when';
import { VpCloseIcon } from '@vtmn-play/icons/react';

import styles from './message.module.css';

interface MessageProps {
  title: React.ReactNode;
  message: React.ReactNode;
  onClose?: () => void;
}
export const Message = ({ message, title, onClose }: MessageProps) => (
  <Case>
    <article className={styles.message}>
      <VpIcon size={20} name="warning-error" />
      <p>
        <Case when={title !== undefined}>
          <span className={styles.title}>{title}</span> :
        </Case>
        <span>{message}</span>
      </p>
      <Case when={Boolean(onClose)}>
        <VpIconButton data-testid="close-message" size="small" variant="tertiary" onClick={() => onClose?.()}>
          <VpCloseIcon aria-label="Close message" />
        </VpIconButton>
      </Case>
    </article>
  </Case>
);
