import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type Defect, type DeflocCategory, type Location } from '../apis/defloc/defloc.type';

export interface DeflocInfos {
  category: DeflocCategory;
  location: Location;
  defect: Defect;
}

export interface DeflocState {
  defloc: DeflocInfos | null;
}

export const initialState: DeflocState = {
  defloc: null,
};

export const deflocSlice = createSlice({
  name: 'defloc',
  initialState,
  extraReducers: builder => builder.addCase('resetStore', () => initialState),
  reducers: {
    updateDefloc: (state, action: PayloadAction<DeflocInfos>) => {
      state.defloc = action.payload;
    },
  },
});

export const {
  reducer: deflocReducer,
  actions: { updateDefloc },
} = deflocSlice;
