import { RequestMethod } from '@chipp972/redux-ajax';

import { useAppFetch } from '../../utils/apis/useAppFetch';

interface MessagesResponse {
  messages: Array<{
    message: string;
    title: string;
    id: string;
  }>;
}
const urlGet = 'incident-status/messages';
const url = 'incident-status/message';

export const useFetchGetAlertMessages = () => useAppFetch<MessagesResponse>({ requestId: 'getMessages', url: urlGet }, []);

export const useFetchPostAlertMessage = () => useAppFetch({ requestId: 'createMessage', url, method: RequestMethod.post }, []);

export const useFetchEditAlertMessage = () => useAppFetch({ requestId: 'editMessage', url, method: RequestMethod.put }, []);

export const useFetchDeleteAlertMessage = () => useAppFetch({ requestId: 'deleteMessage', url, method: RequestMethod.delete }, []);
