// @ts-strict-ignore
import { type TableHistoryData } from '../components/ParcelsFlowPage/TableHistory/historyHelper';
import { getArticlesDetails } from '../apis/masterdata/masterdata';
import { getHistoric, getLogisticalInformation, getStoreId, receiveParcel, getInterventionsByIds } from '../apis/interventions/interventions';
import { type StoreItem } from '../apis/startup/startup.type';
import { type Parcel } from '../apis/interventions/parcel.type';

const shippingGroupPattern = /^sg[a-z]{2}\d{1,}$/;
export const checkShippingGroup = (searchedText: string) => shippingGroupPattern.test(searchedText);

export interface ParcelsHistoricData {
  nextParams: string;
  parcels: TableHistoryData[];
}

export const getParcelsHistoricData = (
  isShipment: boolean,
  beginDate: Date,
  endDate: Date | undefined,
  paginationParams: string,
  targetStoreId: number | null
): Promise<ParcelsHistoricData> =>
  getHistoric(beginDate, endDate, isShipment, paginationParams, targetStoreId).then(({ parcels, nextParams }) => ({
    parcels: parcels
      .flatMap(parcel =>
        parcel.interventions.map(intervention => ({
          parcelNumber: parcel.parcelNumber,
          logisticalNumber: parcel.logisticalNumber,
          shipmentDate: parcel.shipmentDate,
          receptionDate: intervention.receptionDate,
          interventionNumber: intervention.documentNumber,
          locationId: isShipment ? parcel.receiverSite : parcel.shipmentSite,
          isShipment,
        }))
      )
      .sort((a, b) => Number(b.parcelNumber) - Number(a.parcelNumber)),
    nextParams,
  }));

export const fillParcelInterventions = async (interventions: TableHistoryData[], stores: StoreItem[]): Promise<TableHistoryData[]> =>
  getInterventionsByIds(
    interventions.map(intervention => intervention.interventionNumber),
    []
  ).then(async workshopInterventions =>
    Promise.all(
      interventions.map(async intervention => {
        const matchingWorkshopIntervention = workshopInterventions.find(i => i.documentNumber === intervention.interventionNumber);
        return {
          ...intervention,
          contextId: matchingWorkshopIntervention?.contextId,
          customer: { memberId: matchingWorkshopIntervention?.memberId },
          creationDate: matchingWorkshopIntervention?.creationDate,
          location: stores.find(location => location.id === intervention.locationId)?.name,
          product: matchingWorkshopIntervention?.depositedProduct?.itemCode
            ? (await getArticlesDetails([matchingWorkshopIntervention?.depositedProduct.itemCode]))[0].modelLib
            : matchingWorkshopIntervention?.depositedProduct?.label,
        };
      })
    )
  );

export const receiveParcelFromDocumentNumber = (documentNumber: string) => {
  const storeId = getStoreId();

  return getLogisticalInformation(documentNumber).then(parcelsInfo => {
    const parcel = parcelsInfo.find(parcelInfo => parcelInfo.receiverSite === storeId);
    const parcelToReceive: Parcel = {
      ...parcel,
      interventions: [
        {
          documentNumber,
          received: true,
          isPending: true,
        },
      ],
    };
    return receiveParcel(parcelToReceive);
  });
};
