import { selectIsRegionalWorkshopSelector, selectStoreId } from '../../store/selectors/userInfo';
import { useAppSelector } from '../../store/store';

type RedirectParam = {
  link: string;
  param: string;
};

const useDataStudio = () => {
  const { id, name, isRegionalWorkshop, isFrenchLanguage } = useAppSelector(state => ({
    id: selectStoreId(state),
    name: state.userInfo.selectedSite.shortName,
    isRegionalWorkshop: selectIsRegionalWorkshopSelector(state),
    isFrenchLanguage: state.i18n.appLanguage?.language === 'FR',
  }));
  let redirectParam: RedirectParam = isFrenchLanguage ? { link: 'u', param: 'df485' } : { link: 'x', param: 'df537' };
  if (isRegionalWorkshop) {
    redirectParam = isFrenchLanguage ? { link: 'g', param: 'df607' } : { link: 'n', param: 'df644' };
  }

  const baseUrl = `https://datastudio.google.com/u/0/reporting/1ys13kGjbnVEGJjGfX4TurSWrB11Xte0V/page/${redirectParam.link}flx?params=`;
  return encodeURI(`${baseUrl}{"${redirectParam.param}":"include%EE%80%800%EE%80%80IN%EE%80%80${id} - ${name}"}`);
};

export default useDataStudio;
