import { type RootState, useAppDispatch, useAppSelector } from './store';
import {
  updatePecSparePart,
  resetIntervention,
  updateStoreComment,
  updateWorkshopComment,
  updateFooter,
  updateFileNames,
  updateFiles,
  setDuplicate,
  updateRealizationSite,
  updateCreationSite,
  updateInterventionRef,
  updateIsWarranty,
  setArticleStock,
  deleteContextType,
} from './interventionSlice';
import { updateLocale, updateAppLanguage, updateTranslations, updateAvailableLanguages, type Locale } from './i18nSlice';
import { type Translations } from '../utils/i18n/SwappingIntlProvider';
import {
  updateToken,
  updateConfigApiKeys,
  updateDomains,
  updateSecrets,
  setIsProd,
  setHasLog,
  setGTMTrackingID,
  setHideEventNotifications,
} from './configSlice';
import { type ApisDomains, type Secrets, type ServiceApiKey } from '../utils/config/configuration.type';
import { type FooterInfos, type RealizationSite } from '../apis/interventions/intervention.type';
import { updateDefloc, type DeflocInfos } from './deflocSlice';
import { initWorkshopState, type WorkshopState } from './workshopInterventionSlice';
import { type IcareSiteUserInfo, addUserInfo } from './userInfoSlice';
import { selectStoreId } from './selectors/userInfo';
import {
  updateCatalogSearch,
  updateProduct,
  resetCatalogSearch,
  type CatalogSearch,
  updateBreadcrumb,
  type BreadcrumbLevel,
  initialProductCatalog,
} from './catalogSlice';
import { type ILinkProduct, LevelType, type Entity } from '../apis/ilink.type';
import { InterventionStatus } from '../business/interventionButtonMenu';
import { type Customer, type CustomerInput } from '../apis/customer/customer.types';
import { deleteCustomer, selectCustomer, updateCustomerInput } from './customerSlice';
import {
  isShortageStateForbidden,
  isInTransitState,
  isInterventionDisabled,
  isLocationDisabled,
  isStateDisabled,
} from '../business/interventionHelper';
import { getInterventionContextTypes } from './selectors/intervention';

/**
 * @deprecated
 * avoid this usage because it compute everything inside the function
 */
export const useInterventionStore = () => {
  const dispatch = useAppDispatch();
  return {
    type: useAppSelector(state => state.intervention.interventionType),
    contextType: useAppSelector(state => state.intervention.contextType),
    isWarranty: useAppSelector(state => state.intervention.isWarranty),
    interventionRef: useAppSelector(state => state.intervention.interventionRef),
    /**
     * @deprecated
     * use selectSpareParts
     */
    spareParts: useAppSelector(state => state.intervention.natures.flatMap(nature => nature.spareParts)),
    /**
     * @deprecated
     * use selectServices
     */
    services: useAppSelector(state => state.intervention.natures.flatMap(nature => nature.services)),
    creationSite: useAppSelector(state => state.intervention.creationSite),
    realizationSite: useAppSelector(state => state.intervention.realizationSite),
    storeComment: useAppSelector(state => state.intervention.storeComment),
    workshopComment: useAppSelector(state => state.intervention.workshopComment),
    maxOgeaAmount: useAppSelector(state => state.intervention.maxOgeaAmount),
    reload: useAppSelector(state => state.intervention.reload),
    duplicate: useAppSelector(state => state.intervention.duplicate),
    hasPendingIntervention: useAppSelector(state => state.intervention.footer.state.stateId !== InterventionStatus.notStarted),
    fileNames: useAppSelector(state => state.intervention.fileNames),
    deleteContextType: () => dispatch(deleteContextType()),
    updateInterventionRef: (payload: string) => dispatch(updateInterventionRef(payload)),
    updateIsWarranty: (payload: boolean) => dispatch(updateIsWarranty(payload)),
    updatePecSparePart: (natureId: number, sparePart: Entity) => dispatch(updatePecSparePart({ natureId, sparePart })),
    updateStoreComment: (payload: string) => dispatch(updateStoreComment(payload)),
    updateWorkshopComment: (payload: string) => dispatch(updateWorkshopComment(payload)),
    updateCreationSite: (payload: RealizationSite) => dispatch(updateCreationSite(payload)),
    updateRealizationSite: (payload: RealizationSite) => dispatch(updateRealizationSite(payload)),
    setArticleStock: (modelCode: number, articleId: number, stock: number) => dispatch(setArticleStock({ modelCode, articleId, stock })),
    setDuplicate: () => dispatch(setDuplicate()),
  };
};

export const getIsStateDisabled = (state: RootState) =>
  isStateDisabled(
    state.intervention.footer.state.stateId,
    state.intervention.creationSite?.id === selectStoreId(state),
    state.intervention.interventionType
  );

export const useDisabled = () => ({
  isLocationDisabled: useAppSelector(state => isLocationDisabled(state.intervention.footer.state.stateId)),
  isFullyDisabled: useAppSelector(state => isInterventionDisabled(state.intervention.footer.state.stateId)),
  isSpecificationsDisabled: useAppSelector(
    state => isInterventionDisabled(state.intervention.footer.state.stateId) || isInTransitState(state.intervention.footer.state.stateId)
  ),
  isDisabled: useAppSelector(getIsStateDisabled),
  isShortageStateForbidden: useAppSelector(state => isShortageStateForbidden(state.intervention.footer.state.stateId)),
});

export const useI18nStore = () => {
  const dispatch = useAppDispatch();
  return {
    appLanguage: useAppSelector(state => state.i18n.appLanguage),
    availableLanguages: useAppSelector(state => state.i18n.availableLanguages),
    locale: useAppSelector(state => state.i18n.locale),
    messages: useAppSelector(state => state.i18n.messages),
    updateLocale: (payload: Locale) => dispatch(updateLocale(payload)),
    updateAppLanguage: (payload: Locale) => dispatch(updateAppLanguage(payload)),
    updateTranslations: (payload: Translations) => dispatch(updateTranslations(payload)),
    updateAvailableLanguages: (payload: Locale[]) => dispatch(updateAvailableLanguages(payload)),
  };
};

export const useCustomerStore = () => {
  const dispatch = useAppDispatch();
  return {
    customer: useAppSelector(state => state.customer.customer),
    customerInput: useAppSelector(state => state.customer.customerInput),
    selectCustomer: (payload: Customer | null) => dispatch(selectCustomer(payload)),
    updateCustomerInput: (payload: CustomerInput) => dispatch(updateCustomerInput(payload)),
    deleteCustomer: () => dispatch(deleteCustomer()),
  };
};

export const useConfigStore = () => {
  const dispatch = useAppDispatch();
  return {
    setIsProd: (payload: boolean) => dispatch(setIsProd(payload)),
    setGTMTrackingID: (payload: string) => dispatch(setGTMTrackingID(payload)),
    setHideEventNotifications: (payload: boolean) => dispatch(setHideEventNotifications(payload)),
    setHasLog: (payload: boolean) => dispatch(setHasLog(payload)),
    token: useAppSelector(state => state.configuration.token),
    updateToken: (payload: string) => dispatch(updateToken(payload)),
    config: useAppSelector(state => state.configuration.config),
    updateSecrets: (payload: Secrets) => dispatch(updateSecrets(payload)),
    updateDomains: (payload: ApisDomains) => dispatch(updateDomains(payload)),
    updateConfigApiKeys: (payload: ServiceApiKey) => dispatch(updateConfigApiKeys(payload)),
  };
};

export const useFooterStore = () => {
  const dispatch = useAppDispatch();
  return {
    footer: useAppSelector(state => state.intervention.footer),
    updateFooter: (payload: FooterInfos) => dispatch(updateFooter(payload)),
  };
};

export const useDeflocStore = () => {
  const dispatch = useAppDispatch();
  return {
    defloc: useAppSelector(state => state.defloc.defloc),
    updateDefloc: (payload: DeflocInfos) => dispatch(updateDefloc(payload)),
  };
};

export const useFilesStore = () => {
  const dispatch = useAppDispatch();
  return {
    fileNames: useAppSelector(state => state.intervention.fileNames),
    files: useAppSelector(state => state.intervention.files),
    updateFileNames: (payload: string[]) => dispatch(updateFileNames(payload)),
    updateFiles: (payload: FileList) => dispatch(updateFiles(payload)),
  };
};

export const useWorkshopInterventionStore = () => {
  const dispatch = useAppDispatch();
  return {
    workshopInterventionStates: useAppSelector(state => state.workshopIntervention.workshopInterventionStates),
    workshopInterventionTypes: useAppSelector(state => state.workshopIntervention.workshopInterventionTypes),
    interventionContextTypes: useAppSelector(getInterventionContextTypes),
    initWorkshopState: (payload: WorkshopState) => dispatch(initWorkshopState(payload)),
  };
};

export const useUserInfoStore = () => {
  const dispatch = useAppDispatch();
  return {
    userInfo: useAppSelector(state => state.userInfo),
    storeId: useAppSelector(selectStoreId),
    addUserInfo: (payload: IcareSiteUserInfo) => dispatch(addUserInfo(payload)),
  };
};

export const useCatalogStore = () => {
  const dispatch = useAppDispatch();
  return {
    catalogSearch: useAppSelector(state => state.catalog.search),
    product: useAppSelector(state => state.catalog.search.product),
    process: useAppSelector(state => state.catalog.search.breadcrumb?.find(breadcrumb => breadcrumb.level === LevelType.categories)),
    category: useAppSelector(state => state.catalog.search.breadcrumb?.find(breadcrumb => breadcrumb.level === LevelType.products)),
    updateCatalogSearch: (payload: CatalogSearch) => dispatch(updateCatalogSearch(payload)),
    resetCatalogSearch: () => dispatch(resetCatalogSearch()),
    updateProduct: (payload: ILinkProduct) => dispatch(updateProduct(payload)),
    updateBreadcrumb: (payload: BreadcrumbLevel[]) => dispatch(updateBreadcrumb(payload)),
  };
};

export const useReset = () => {
  const dispatch = useAppDispatch();
  return {
    emptyIntervention: () => {
      dispatch(resetIntervention(false));
      dispatch(updateProduct(initialProductCatalog));
      dispatch(resetCatalogSearch());
      dispatch(deleteCustomer());
    },
  };
};

export const useGtmUserArgs = () =>
  useAppSelector(state => ({
    userJobname: state.userInfo.jobname,
    userUID: state.userInfo.uid,
    languageCode: state.i18n.appLanguage?.language,
    selectedSite: state.userInfo.selectedSite,
  }));
