import { LevelType } from '../../apis/ilink.type';
import { type Site } from '../../apis/userinfo.type';
import { type BreadcrumbLevel } from '../../store/catalogSlice';
import { type GetTagManagerArgs, type GTMScannedProduct } from './googleTagManager.type';

export interface GTMUserArgs {
  userJobname: string | undefined;
  languageCode: string | undefined;
  selectedSite: Site;
}

export const getGTMArgs = (pageName: string, pageType: string, pageCategory: string, gtmUserArgs: GTMUserArgs, additionalArgs?: any) => ({
  dataLayer: {
    pageName,
    pageType,
    pageCategory,
    userJobname: gtmUserArgs.userJobname,
    languageCode: gtmUserArgs.languageCode,
    storeID: gtmUserArgs.selectedSite.id,
    countryCode: gtmUserArgs.selectedSite.address.countryCode,
    event: 'page_view',
    ...additionalArgs,
  },
  dataLayerName: 'dataLayer',
});

interface PageTagArgs {
  pageName: string;
  pageType: string;
  process?: string;
  productCategory?: string;
}

const getCatalogArgs = (actualBreadcrumbLevel: BreadcrumbLevel, fullBreadcrumb: BreadcrumbLevel[]): PageTagArgs => {
  switch (actualBreadcrumbLevel.level) {
    case LevelType.categories:
      return {
        pageName: 'catalog_category',
        pageType: 'catalog_category',
        process: actualBreadcrumbLevel.label ?? '',
      };
    case LevelType.products:
      return {
        pageName: 'catalog_product',
        pageType: 'catalog_category_product',
        process: fullBreadcrumb[1]?.label ?? '',
        productCategory: actualBreadcrumbLevel.label ?? '',
      };
    default:
      return {
        pageName: 'catalog_process',
        pageType: 'catalog_process',
      };
  }
};

export const getCatalogPageGTMArgs = (actualBreadcrumbLevel: BreadcrumbLevel, fullBreadcrumb: BreadcrumbLevel[], gtmUserArgs: GTMUserArgs) => {
  const args = getCatalogArgs(actualBreadcrumbLevel, fullBreadcrumb);
  return getGTMArgs(args.pageName, args.pageType, 'catalog', gtmUserArgs, { ...args });
};

// INTERVENTION CREATION

type DataLayerInterventionEvent = 'pec_creation';

interface DataLayerTraceability {
  dataLayer: GTMScannedProduct & {
    PECType: 'A' | 'B' | 'C';
    PECNumber: string | undefined;
    event: DataLayerInterventionEvent;
  };
  dataLayerName: 'dataLayer';
}

/**
 * @deprecated Use `#getTagManagerArgs` instead
 */
export const getGTMTraceabilityArgs = (
  PECType: 'A' | 'B' | 'C',
  event: DataLayerInterventionEvent,
  additionalArgs: GTMScannedProduct,
  pecNumber: string | undefined
): DataLayerTraceability => ({
  dataLayer: {
    PECType,
    event,
    PECNumber: pecNumber,
    ...additionalArgs,
  },
  dataLayerName: 'dataLayer',
});

// SCAN PRODUCT

type DataLayerScanProductEvent = 'traceability_scan';

interface DataLayerScan {
  dataLayer: GTMScannedProduct & {
    event: DataLayerScanProductEvent;
  };
  dataLayerName: 'dataLayer';
}

/**
 * @deprecated Use `#getTagManagerArgs` instead
 */
export const getGTMScanArgs = (event: DataLayerScanProductEvent, additionalArgs: GTMScannedProduct): DataLayerScan => ({
  dataLayer: {
    event,
    ...additionalArgs,
  },
  dataLayerName: 'dataLayer',
});

export function getTagManagerArgs({ event, label, params }: GetTagManagerArgs) {
  return {
    dataLayerName: 'dataLayer',
    dataLayer: {
      event,
      event_label: label,
      ...params,
    },
  };
}
