import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type ParcelsFlowState, ParcelsFlowTabType, type ParcelUpdateInfo } from './ParcelsFlow.type';
import { type ParcelIntervention, type Parcel } from '../../apis/interventions/parcel.type';
import { type TableHistoryData } from './TableHistory/historyHelper';
import { type ShipmentIntervention } from './TableShipment/TableShipment';

export const initialState: ParcelsFlowState = {
  parcels: [],
  interventions: [],
  parcelsInfo: [],
  searchNumbersInProgress: [],
  historyInterventions: [],
  tabType: ParcelsFlowTabType.reception,
  historySearchLabel: '',
  receptionNextParams: null,
};

export const parcelsFlowSlice = createSlice({
  name: 'parcelsFLow',
  initialState,
  reducers: {
    addInterventions: (state, action: PayloadAction<ShipmentIntervention[]>) => {
      state.interventions = [...state.interventions, ...action.payload];
    },
    selectIntervention: (state, action: PayloadAction<string>) => {
      state.interventions = state.interventions.map(current =>
        current.interventionNumber === action.payload ? { ...current, selected: !current.selected } : current
      );
    },
    removeSelectedIntervention: state => {
      state.interventions = state.interventions.filter(current => !current.selected);
    },
    clearInterventions: state => {
      state.interventions = [];
    },
    addSearchNumber: (state, action: PayloadAction<string>) => {
      state.searchNumbersInProgress = state.searchNumbersInProgress.concat(action.payload);
    },
    subTractSearchNumber: (state, action: PayloadAction<string>) => {
      state.searchNumbersInProgress = state.searchNumbersInProgress.filter(inProgress => inProgress !== action.payload);
    },
    addParcel: (state, action: PayloadAction<Parcel>) => {
      if (state.parcels.some(parcel => parcel.parcelNumber === action.payload.parcelNumber)) {
        return;
      }
      state.parcels = [...state.parcels, action.payload];
    },
    removeParcel: (state, action: PayloadAction<string>) => {
      state.parcels = state.parcels.filter(parcelWhole => parcelWhole.parcelNumber !== action.payload);
    },
    updateParcels: (state, action: PayloadAction<ParcelUpdateInfo>) => {
      const searchedNumber = action.payload.docOrInvoiceNumber;
      const isFiltered = (intervention: ParcelIntervention) =>
        (intervention.documentNumber === searchedNumber || intervention.invoiceNumber === searchedNumber) &&
        (!action.payload.isFromScan || !intervention.received);
      const parcelToUpdate = state.parcels.find(parcel => parcel.interventions.some(isFiltered));
      if (!parcelToUpdate) {
        return;
      }

      const interventions = parcelToUpdate.interventions.map(intervention =>
        isFiltered(intervention)
          ? {
              ...intervention,
              issued: action.payload.issued,
              received: action.payload.received,
            }
          : intervention
      );

      const parcels = action.payload.isFromScan
        ? [{ ...parcelToUpdate, interventions }, ...state.parcels.filter(parcel => parcel.parcelNumber !== parcelToUpdate.parcelNumber)]
        : state.parcels.map(parcel => ({
            ...parcel,
            interventions: parcel.parcelNumber === parcelToUpdate.parcelNumber ? interventions : parcel.interventions,
          }));

      state.parcels = parcels;
    },
    clearParcels: (state, action: PayloadAction<string[]>) => {
      state.parcels = state.parcels.filter(parcel => !action.payload.includes(parcel.parcelNumber));
    },
    selectParcels: (state, action: PayloadAction<string>) => {
      const parcelToUpdate = state.parcels.find(
        parcel => parcel.parcelNumber === action.payload && parcel.interventions.some(intervention => !intervention.received)
      );
      if (!parcelToUpdate) {
        return;
      }

      const filteredParcels = state.parcels.filter(parcel => parcel.parcelNumber !== parcelToUpdate.parcelNumber);
      const interventions = parcelToUpdate.interventions.map(intervention => ({
        ...intervention,
        issued: false,
        received: true,
      }));
      state.parcels = [{ ...parcelToUpdate, interventions }, ...filteredParcels];
    },
    setHistoryParcelsInfo: (state, action: PayloadAction<TableHistoryData[]>) => {
      state.parcelsInfo = action.payload;
    },
    addHistoryInterventions: (state, action: PayloadAction<TableHistoryData[]>) => {
      state.historyInterventions = [...state.historyInterventions, ...action.payload];
    },
    clearHistoryInterventions: state => {
      state.historyInterventions = [];
      state.historySearchLabel = '';
    },
    setTabType: (state, action: PayloadAction<ParcelsFlowTabType>) => {
      state.tabType = action.payload;
    },
    setHistorySearchLabel: (state, action: PayloadAction<string>) => {
      state.historySearchLabel = action.payload;
    },
    setReceptionNextParams: (state, action: PayloadAction<string>) => {
      state.receptionNextParams = action.payload;
    },
  },
});

export const {
  addHistoryInterventions,
  addInterventions,
  addParcel,
  addSearchNumber,
  clearHistoryInterventions,
  clearInterventions,
  clearParcels,
  removeParcel,
  removeSelectedIntervention,
  selectIntervention,
  selectParcels,
  setHistoryParcelsInfo,
  setHistorySearchLabel,
  setReceptionNextParams,
  setTabType,
  subTractSearchNumber,
  updateParcels,
} = parcelsFlowSlice.actions;

export default parcelsFlowSlice.reducer;
