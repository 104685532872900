import React, { lazy } from 'react';

import { useAppSelector } from '../../store/store';

const ToolbarTop = lazy(() => import('./ToolbarTop'));
const Header = lazy(() => import('./Header/Header'));

export const ToolbarTopSwitcher = () => {
  const traceabilityActivation = useAppSelector(state => state.userInfo.traceabilityActivation);

  return traceabilityActivation ? <Header /> : <ToolbarTop />;
};
