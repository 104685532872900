import React, { type ComponentProps, lazy, Suspense, type ReactNode, type ComponentType } from 'react';

export const LazyReact = ({ children }: { children: ReactNode }) => <Suspense fallback="...">{children}</Suspense>;

/**
 * Lazyload a React component from its path
 * and wrap it with its own Suspense parent.
 *
 * @param load Callback that should return the promise of the imported module.
 * @returns
 */
export const lazyWithSuspense = <T extends ComponentType<any>>(load: () => Promise<{ default: T }>) => {
  const LazyComponent = lazy(load);

  return (props: ComponentProps<T>) => (
    <LazyReact>
      <LazyComponent {...props} />
    </LazyReact>
  );
};
