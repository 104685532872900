import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { InterventionType, type InterventionState, type StoreInformation, type WorkshopContextType } from '../apis/interventions/intervention.type';

export interface WorkshopState {
  workshopInterventionStates: InterventionState[];
  storeInformation: StoreInformation;
  workshopInterventionTypes: InterventionType[];
}

export interface WorkshopInterventionState {
  workshopInterventionStates: InterventionState[];
  workshopInterventionTypes: InterventionType[];
  workshopInterventionContextTypes: WorkshopContextType[];
}

export const initialState: WorkshopInterventionState = {
  workshopInterventionStates: [],
  workshopInterventionTypes: [],
  workshopInterventionContextTypes: [],
};

const workshopInterventionSlice = createSlice({
  name: 'workshopIntervention',
  initialState,
  extraReducers: builder => builder.addCase('resetStore', () => initialState),
  reducers: {
    initWorkshopState: (state, action: PayloadAction<WorkshopState>) => {
      const { workshopInterventionStates, storeInformation, workshopInterventionTypes } = action.payload;
      state.workshopInterventionStates = workshopInterventionStates;
      state.workshopInterventionContextTypes =
        storeInformation.contexts?.map(context => ({
          id: context.id,
          internal: context.internal,
          authorizedPogos: context.pogoList,
        })) ?? [];
      state.workshopInterventionTypes = workshopInterventionTypes.filter(currentType => {
        const excludedTypes = (storeInformation.allowSad ? [] : [InterventionType.homeService]).concat([
          InterventionType.ogea,
          InterventionType.web,
          InterventionType.express,
          InterventionType.new,
        ]);
        return !excludedTypes.includes(currentType);
      });
    },
  },
});

export const {
  reducer: workshopInterventionReducer,
  actions: { initWorkshopState },
} = workshopInterventionSlice;
