import { v4 } from 'uuid';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { translatePogoToPogoId } from '../apis/interventions/intervention.const';
import { findSelectedArticle, findArticleId, updateSparePartArticles } from '../business/articleHelper';
import { isPogoAuthorized } from './selectors/intervention';
import {
  type FooterInfos,
  InterventionType,
  type InterventionUpdateType,
  type RealizationSite,
  STANDARD_EXTERNAL_BUSINESS_ID,
  STANDARD_INTERNAL_BUSINESS_ID,
  type WorkshopContextType,
  type InterventionPogoItem,
  type InterventionState,
  type PogoDetail,
} from '../apis/interventions/intervention.type';
import { type Article, type MasterDataStore } from '../apis/masterdata/masterdata.type';
import { type Entity, type InterventionNature } from '../apis/ilink.type';
import { InterventionStatus } from '../business/interventionButtonMenu';
import { EntityType } from '../apis/entity.type';
import { getPogoToUse } from '../business/pogoHelper';

export const hasFreeArticle = (entities: Entity[]) => entities.some(entity => entity.articles.some(article => article.selected && article.free));

export const shippingContainers = {
  box: 1,
  magnum: 3,
  roll: 4,
  pallet: 20,
};

interface DisableState {
  actionButton: boolean;
}

interface ModalsDisplay {
  isDeletionModalOpen: boolean;
  isValidationModalOpen: boolean;
  isDeflocModalOpen: boolean;
  isRedModalOpen: boolean;
  isShippingModalOpen: boolean;
  isReceptionModalOpen: boolean;
}

export interface ChangeInternalContext {
  context: WorkshopContextType;
  selectedPogoId: number;
}

interface MissingFields {
  purchaseDate: boolean;
}

export interface InterventionStoreState {
  interventionType: InterventionType;
  contextType: WorkshopContextType | null;
  natures: InterventionNature[];
  storeComment: string;
  workshopComment: string;
  fileNames: string[] | null;
  files: FileList | null;
  footer: FooterInfos;
  creationSite?: MasterDataStore;
  realizationSite?: RealizationSite;
  isWarranty?: boolean;
  interventionRef?: string;
  reload: boolean;
  fromTraceability: boolean;
  duplicate?: boolean;
  maxOgeaAmount?: number;
  currency: string;
  disable: DisableState;
  pogo: {
    services: InterventionPogoItem[];
    spareParts: InterventionPogoItem[];
  };
  modalsDisplay: ModalsDisplay;
  missingFields: MissingFields;
}

interface AddOrUpdateInterventionNaturesPayload {
  interventionNatures: InterventionNature[];
  authorizedPogos?: PogoDetail[];
}

const mapArticleToPogo = (article: Article, authorizedPogos: PogoDetail[]): { modelCode: number; pogoId: number } => ({
  modelCode: article.modelCode ?? 0,
  pogoId: getPogoToUse(authorizedPogos, false) ?? translatePogoToPogoId(article.gridValueId ?? '') ?? 0,
});

interface MapNaturesToPogoListParams {
  natureType: 'services' | 'spareParts';
  updatedNatures: InterventionNature[];
  authorizedPogos: PogoDetail[];
}

const mapNaturesToPogoList = ({ natureType, updatedNatures, authorizedPogos }: MapNaturesToPogoListParams) =>
  updatedNatures
    .flatMap(interventionNature => interventionNature[natureType].flatMap(entity => entity.articles))
    .map(article => mapArticleToPogo(article, authorizedPogos));

const findSamePogoEntity = (entity: Entity, entityToCompare: Entity, isServiceEntity: boolean) =>
  ((isServiceEntity && entityToCompare.modelCode === entity.modelCode) ||
    (!isServiceEntity && entityToCompare.articles.find(findSelectedArticle)?.articleId === entity.articles.find(findSelectedArticle)?.articleId)) &&
  entityToCompare.articles.find(findSelectedArticle)?.gridValueId === entity.articles.find(findSelectedArticle)?.gridValueId;

export const updateEntity = (entity: Entity, stateEntities: Entity[], isServiceEntity = false) =>
  stateEntities
    .map(stateEntity => (stateEntity.id === entity.id ? { ...stateEntity, ...entity } : stateEntity))
    .filter(stateEntity => stateEntity.quantity > 0)
    .reduce((updatedEntities: Entity[], currentEntity) => {
      const index = updatedEntities.findIndex(stateEntity => findSamePogoEntity(stateEntity, currentEntity, isServiceEntity));
      if (index === -1) {
        updatedEntities.push(currentEntity);
      } else {
        updatedEntities[index] = { ...updatedEntities[index], quantity: updatedEntities[index].quantity + currentEntity.quantity };
      }
      return updatedEntities;
    }, []);

export const addEntities = (entities: Entity[], stateEntities: Entity[], isServiceEntity = false) => {
  const updatedEntities = [...stateEntities];
  entities.forEach(entity => {
    const index = updatedEntities.findIndex(stateEntity => findSamePogoEntity(stateEntity, entity, isServiceEntity));
    if (index === -1) {
      const newEntity = isServiceEntity ? entity : { ...entity, orderInProgress: false };
      updatedEntities.push({ ...newEntity, id: newEntity.id || v4() });
    } else {
      updatedEntities[index] = { ...updatedEntities[index], quantity: updatedEntities[index].quantity + entity.quantity };
    }
  });
  return updatedEntities;
};

const addNatureEntities = (newNature: InterventionNature, natures: InterventionNature[]) =>
  [...natures].map(nature =>
    nature.natureId === newNature.natureId
      ? {
          ...nature,
          services: addEntities(newNature.services, nature.services, true),
          spareParts: addEntities(newNature.spareParts, nature.spareParts),
        }
      : nature
  );

export const initialState: InterventionStoreState = {
  interventionType: InterventionType.standard,
  contextType: null,
  natures: [],
  storeComment: '',
  workshopComment: '',
  fileNames: [],
  files: null,
  interventionRef: '',
  footer: {
    pledgeDate: new Date(),
    state: {
      stateId: InterventionStatus.notStarted,
      stateLabel: '',
    },
  },
  reload: false,
  fromTraceability: false,
  currency: '',
  disable: {
    actionButton: false,
  },
  pogo: { services: [], spareParts: [] },
  modalsDisplay: {
    isDeletionModalOpen: false,
    isValidationModalOpen: false,
    isDeflocModalOpen: false,
    isRedModalOpen: false,
    isShippingModalOpen: false,
    isReceptionModalOpen: false,
  },
  missingFields: {
    purchaseDate: false,
  },
};

const updatePecEntity = (
  natures: InterventionNature[],
  natureId: number,
  entityTypes: EntityType,
  entity: Entity,
  isService = false
): InterventionNature[] =>
  natures
    .map(nature =>
      nature.natureId === natureId
        ? {
            ...nature,
            [entityTypes]: updateEntity(entity, nature[entityTypes], isService),
          }
        : nature
    )
    .filter(nature => !!nature.services.length || !!nature.spareParts.length);

export const interventionSlice = createSlice({
  name: 'intervention',
  initialState,
  extraReducers: builder => builder.addCase('resetStore', () => initialState),
  reducers: {
    setArticleStock: (state, action: PayloadAction<{ modelCode: number; articleId: number; stock: number }>) => {
      const { articleId, modelCode, stock } = action.payload;
      state.natures = state.natures.map(nature => ({
        ...nature,
        spareParts: nature.spareParts.map(sparePart => ({
          ...sparePart,
          articles:
            sparePart.modelCode === modelCode
              ? sparePart.articles.map(article => ({
                  ...article,
                  stock: findArticleId(articleId)(article) ? stock : article.stock,
                }))
              : sparePart.articles,
        })),
      }));
    },
    initInterventionState: state => {
      state.footer = { ...state.footer, state: { stateId: InterventionStatus.inCreation, stateLabel: '' } };
    },
    initIntervention: (_, action: PayloadAction<InterventionStoreState>) => action.payload,
    resetIntervention: (_, action: PayloadAction<boolean | undefined>) => ({ ...initialState, reload: Boolean(action.payload) }),
    addFromTraceability: () => ({
      ...initialState,
      fromTraceability: true,
    }),
    removeFromTraceability: () => ({
      ...initialState,
      fromTraceability: false,
    }),
    updateType: (state, action: PayloadAction<InterventionUpdateType>) => {
      const { interventionType, hasCustomer, workshopInterventionContextTypes } = action.payload;
      state.interventionType = interventionType;
      if (interventionType === InterventionType.homeService) {
        const initialContextType =
          workshopInterventionContextTypes.find(
            contextType => contextType.id === (hasCustomer ? STANDARD_EXTERNAL_BUSINESS_ID : STANDARD_INTERNAL_BUSINESS_ID)
          ) ?? null;
        state.contextType = initialContextType;
      }
    },
    updateInternalContextType: (state, action: PayloadAction<ChangeInternalContext>) => {
      const { context, selectedPogoId: selectedPogo } = action.payload;
      const updatedNatures = state.natures.map(nature => ({
        ...nature,
        spareParts: nature.spareParts.map(sparePart => ({
          ...sparePart,
          articles: updateSparePartArticles(sparePart.articles, context),
        })),
        services: nature.services.map(service => ({
          ...service,
          articles: service.articles.some(article => isPogoAuthorized(context.authorizedPogos, article.gridValueId))
            ? service.articles.map(article => ({
                ...article,
                selected: isPogoAuthorized(context.authorizedPogos, article.gridValueId),
              }))
            : service.articles,
        })),
      }));
      state.natures = updatedNatures;
      state.contextType = context;
      state.pogo.services = state.pogo.services.map(serviceItem => ({ modelCode: serviceItem.modelCode, pogoId: selectedPogo }));
      state.pogo.spareParts = state.pogo.spareParts.map(sparePartItem => ({ modelCode: sparePartItem.modelCode, pogoId: selectedPogo }));
    },
    updateContextType: (state, action: PayloadAction<WorkshopContextType>) => {
      state.contextType = action.payload;
    },
    updateSparePartOrderNumbers: (state, action: PayloadAction<{ articleId: number; orderNumber: number }>) => {
      const { articleId, orderNumber } = action.payload;

      const editedNatures = state.natures.map(nature => ({
        ...nature,
        spareParts: nature.spareParts.map(sparePart => {
          const { orderNumbers, articles } = sparePart;

          const isOrderedArticle = articles.some(article => article.articleId === articleId);

          if (isOrderedArticle) {
            return {
              ...sparePart,
              orderNumbers: [...(orderNumbers || []), orderNumber],
              orderInProgress: true,
            };
          }

          return sparePart;
        }),
      }));

      return {
        ...state,
        natures: editedNatures,
      };
    },
    deleteContextType: state => {
      state.contextType = null;
    },
    updateInterventionRef: (state, action: PayloadAction<string>) => {
      state.interventionRef = action.payload;
    },
    updateIsWarranty: (state, action: PayloadAction<boolean>) => {
      state.isWarranty = action.payload;
    },
    addOrUpdateInterventionNatures: (state, action: PayloadAction<AddOrUpdateInterventionNaturesPayload>) => {
      const { interventionNatures, authorizedPogos = [] } = action.payload;

      const updatedNatures = interventionNatures.reduce((acc: InterventionNature[], nature) => {
        const existingNature = acc.find(stateNature => stateNature.natureId === nature.natureId);
        if (!existingNature) {
          return [...acc, nature];
        }
        return addNatureEntities(nature, acc);
      }, state.natures);

      state.natures = updatedNatures;
      state.pogo.spareParts = mapNaturesToPogoList({ natureType: 'spareParts', updatedNatures, authorizedPogos });
      state.pogo.services = mapNaturesToPogoList({ natureType: 'services', updatedNatures, authorizedPogos });
    },
    updatePecService: (state, action: PayloadAction<{ natureId: number; service: Entity }>) => {
      state.natures = updatePecEntity(state.natures, action.payload.natureId, EntityType.services, action.payload.service, true);
      state.pogo.services = action.payload.service.articles.map(article => mapArticleToPogo(article, []));
    },
    updatePecSparePart: (state, action: PayloadAction<{ natureId: number; sparePart: Entity }>) => {
      state.natures = updatePecEntity(state.natures, action.payload.natureId, EntityType.spareParts, action.payload.sparePart);
      state.pogo.spareParts = action.payload.sparePart.articles.map(article => mapArticleToPogo(article, []));
    },
    updateStoreComment: (state, action: PayloadAction<string>) => {
      state.storeComment = action.payload;
    },
    updateWorkshopComment: (state, action: PayloadAction<string>) => {
      state.workshopComment = action.payload;
    },
    updateCreationSite: (state, action: PayloadAction<MasterDataStore>) => {
      state.creationSite = action.payload;
    },
    updateRealizationSite: (state, action: PayloadAction<RealizationSite>) => {
      state.realizationSite = action.payload;
    },
    updateFileNames: (state, action: PayloadAction<string[]>) => {
      state.fileNames = action.payload.filter((fileName, index) => action.payload.indexOf(fileName) === index);
    },
    updateFiles: (state, action: PayloadAction<FileList>) => {
      state.files = action.payload;
    },
    updateFooter: (state, action: PayloadAction<FooterInfos>) => {
      state.footer = action.payload;
    },
    updateFooterState: (state, action: PayloadAction<InterventionState>) => {
      state.footer.state = { ...state.footer.state, ...action.payload };
    },
    unsetReload: state => {
      state.reload = false;
    },
    unsetDuplicate: state => {
      state.duplicate = false;
    },
    setDuplicate: state => {
      state.duplicate = true;
    },
    setDisableActionButton: (state, action: PayloadAction<boolean>) => {
      state.disable.actionButton = action.payload;
    },
    setModalsDisplay: (state, action: PayloadAction<Partial<ModalsDisplay>>) => ({
      ...state,
      modalsDisplay: {
        ...state.modalsDisplay,
        ...action.payload,
      },
    }),
    setMissingFields: (state, action: PayloadAction<MissingFields>) => ({
      ...state,
      missingFields: {
        ...state.missingFields,
        ...action.payload,
      },
    }),
  },
});

export const {
  reducer: interventionReducer,
  actions: {
    setArticleStock,
    addOrUpdateInterventionNatures,
    initIntervention,
    initInterventionState,
    addFromTraceability,
    removeFromTraceability,
    resetIntervention,
    updateContextType,
    updateSparePartOrderNumbers,
    setDuplicate,
    unsetDuplicate,
    unsetReload,
    updateCreationSite,
    updateFileNames,
    updateFiles,
    updateIsWarranty,
    updateFooter,
    updateFooterState,
    updateInterventionRef,
    updatePecService,
    updatePecSparePart,
    updateWorkshopComment,
    updateType,
    updateStoreComment,
    updateRealizationSite,
    updateInternalContextType,
    deleteContextType,
    setDisableActionButton,
    setModalsDisplay,
    setMissingFields,
  },
} = interventionSlice;
